import { gql } from '@apollo/client';
import client from 'apollo/client';

/**
 * Login method
 * @param  {String} username
 * @param  {Password} password
 * @return {Promise}
 */
const login = ({ username, password }) => client.mutate({
  mutation: gql`
    mutation login($input: LoginInput!) {
      login(input: $input) {
        access_token
        refresh_token
        expires_in
        token_type
        user {
          id
          email
          first_name
          last_name
          full_name
          phone
          gender
          birthdate
          height
          joining_date
          plan_id {
            name
            price
          }
          services_id {
            id
            name
            price
            zuda_price
          }
          num_classes
          photo
          is_zuda
          role
          specialty
          eps
          note
          password_set
          trainer_id {
            full_name
          }
          clients(
            first: 20
            page: 1
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              full_name
            }
          }
          evaluations (
            first: 20
            page: 1
            orderBy: [{
              column: CREATED_AT
              order: DESC
            }]
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              muscle_percentage
              fat_percentage
              normal_fat_percentage
              ideal_fat_percentage
              created_at
            }
          }
          payments (
            first: 20
            page: 1
            orderBy: [{
              column: CREATED_AT
              order: DESC
            }]
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              price
              created_at
              start_date
              end_date
            }
          }
        }
      }
    }
  `,
  variables: { input: { username, password } },
});

/**
 * Logout method
 * @return {Promise}
 */
const logout = () => client.mutate({
  mutation: gql`
    mutation logout {
      logout {
        status
        message
      }
    }
  `,
});

/**
 * Refresh token method
 * @param  {String} token
 * @return {Promise}
 */
const refreshToken = (token) => client.mutate({
  mutation: gql`
    mutation refreshToken($input: RefreshTokenInput) {
      refreshToken(input: $input) {
        access_token
        refresh_token
        expires_in
        token_type
      }
    }
  `,
  variables: { input: { refresh_token: token } },
});

/**
 * Verify email method
 * @param  {String} token
 * @return {Promise}
 */
const verifyEmail = (token) => client.mutate({
  mutation: gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
      verifyEmail(input: $input) {
        access_token
        refresh_token
        expires_in
        token_type
        user {
          id
          email
          first_name
          last_name
          full_name
          phone
          gender
          birthdate
          height
          joining_date
          plan_id {
            name
            price
          }
          services_id {
            id
            name
            price
            zuda_price
          }
          num_classes
          photo
          is_zuda
          role
          specialty
          eps
          note
          password_set
          trainer_id {
            full_name
          }
          clients(
            first: 20
            page: 1
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              full_name
            }
          }
          evaluations (
            first: 20
            page: 1
            orderBy: [{
              column: CREATED_AT
              order: DESC
            }]
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              muscle_percentage
              fat_percentage
              normal_fat_percentage
              ideal_fat_percentage
              created_at
            }
          }
          payments (
            first: 20
            page: 1
            orderBy: [{
              column: CREATED_AT
              order: DESC
            }]
          ) {
            paginatorInfo {
              currentPage
              hasMorePages
              total
            }
            data {
              id
              price
              created_at
              start_date
              end_date
            }
          }
        }
      }
    }
  `,
  variables: { input: { token } },
});

/**
 * Resend verification email method
 * @param  {Object} payload
 * @return {Promise}
 */
const resendVerificationEmail = (id) => client.mutate({
  mutation: gql`
    mutation resendVerificationEmail($id: ID!) {
      resendVerificationEmail(id: $id) {
        sent
        error
      }
    }
  `,
  variables: {
    id,
  },
});

export default {
  login,
  logout,
  refreshToken,
  verifyEmail,
  resendVerificationEmail,
};
