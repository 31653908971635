import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import lightFormat from 'date-fns/lightFormat';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import usePaginationSettings from 'hooks/usePaginationSettings';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { defaultTableOptions, simpleTableLocalization, getViewButton } from 'common/table/utils';
import { clientProfileTableClasses } from 'common/muiSharedClasses';
import routes from 'common/routes';
import { trashed, dateFormat } from 'common/constants';
import evaluation from 'api/evaluation';

const useStyles = makeStyles((theme) => clientProfileTableClasses(theme, {
  borderRadius: '0px',
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
  boxShadow: 'none',
  minHeight: 250,
  mobileMinHeight: 'calc(100vh - 270.467px)',
}));

/**
 * ClientEvaluations component
 *
 * @param {object} { setTitle }
 * @return {jsx} react component
 */
function ClientEvaluations({ setTitle }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { authUser } = useContext(AuthUserContext);

  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();

  const [evaluations, setEvaluations] = useState({
    data: [],
    paginator: { currentPage: 1, hasMorePages: true, total: 0 },
  });

  const {
    isLoading,
    isFetching,
  } = useQuery(
    ['evaluations', {
      page: currentPage, first: itemsPerPage,
    }, {
      user_id: authUser.id, soft_delete: trashed.without,
    }],
    evaluation.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-evaluations-error',
          variant: 'error',
        });
      },
      onSuccess: (data) => {
        if (data && typeof data !== 'undefined') {
          setEvaluations({
            data: data.data.evaluations.data,
            paginator: data.data.evaluations.paginatorInfo,
          });
        }
      },
    },
  );

  useEffect(() => {
    setTitle(routes.clientEvaluations.title);
  }, [setTitle]);

  const tableData = evaluations.data.map((item, index) => ({
    ...item,
    index: index + 1,
    created_at: lightFormat(new Date(item.created_at), dateFormat),
  }));

  const onCellClick = (colData, cellMeta) => {
    if (typeof colData !== 'function') {
      const { rowIndex } = cellMeta;
      const { id } = tableData[rowIndex];
      const path = routes.clientEvaluation.path.replace(':id', id);

      history.push(path);
    }
  };

  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: 'Numero', name: 'index' },
    { label: 'Ingresada', name: 'created_at' },
    {
      name: 'Ver',
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getViewButton({
          rowIndex,
          buttonClasses: classes,
          onClick: onCellClick,
        }),
      },
    },
  ];

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    viewColumns: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: evaluations.paginator.total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    onCellClick,
    textLabels: simpleTableLocalization('Valoraciones'),
  };

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Typography className={classes.cardTitle}>{routes.clientEvaluations.title}</Typography>
      </Hidden>
      <div className={classes.card}>
        {(!isLoading && !isFetching) ? (
          <MUIDataTable
            columns={columns}
            data={tableData}
            options={options}
          />
        ) : (
          <DialogLoadingIndicator open borderRadius="5px 30px" />
        )}
      </div>
    </React.Fragment>
  );
}

ClientEvaluations.propTypes = {
  setTitle: PropTypes.func.isRequired,
};

export default ClientEvaluations;
