import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from 'react-query';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { defaultTableOptions, tableLocalization, getCustomSelectToolBar } from 'common/table/utils';
import { getClientData } from 'common/table/data';
import { getClientColumns } from 'common/table/columns';
import { tableClasses } from 'common/muiSharedClasses';
import user from 'api/user';
import auth from 'api/auth';

const useStyles = makeStyles(tableClasses);

/**
 * ClientTable component
 *
 * @param {object} {
 *   data,
 *   isLoading,
 *   currentPage,
 *   setCurrentPage,
 *   itemsPerPage,
 *   setItemsPerPage,
 *   setSelectedItem,
 *   setEditModal,
 * }
 * @return {jsx} react component
 */
function ClientTable({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  setSelectedItem,
  setEditModal,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation((payload) => user.remove(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'delete-client-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { deleteUsers } } = response;
      const message = `${deleteUsers.length > 1 ? 'Clientes eliminados' : 'Cliente eliminado'} con éxito`;

      enqueueSnackbar(message, {
        key: 'delete-client-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('clients');
    },
  });

  const restoreUserMutation = useMutation((payload) => user.restore(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'restore-client-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { restoreUsers } } = response;
      const message = `${restoreUsers.length > 1 ? 'Clientes restaurados' : 'Cliente restaurado'} con éxito`;

      enqueueSnackbar(message, {
        key: 'restore-client-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('clients');
    },
  });

  const sendNotificationMutation = useMutation((payload) => auth.resendVerificationEmail(payload), {
    onError: (error) => {
      enqueueSnackbar(error.error, {
        key: 'resend-verification-notification-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Notificación enviada con éxito', {
        key: 'resend-verification-notification-success',
        variant: 'success',
      });
    },
  });

  if (!data) {
    return (
      <Skeleton
        variant="rect"
        height={200}
        component="div"
        animation="wave"
      />
    );
  }

  const { data: { clients: { data: clientData, paginatorInfo: { total } } } } = data;
  const tableData = getClientData(clientData);

  const openEditModal = (row) => {
    setSelectedItem(row);
    setEditModal(true);
  };

  const resendVerificationEmail = (row) => {
    sendNotificationMutation.mutate(row.id);
  };

  const columns = getClientColumns(tableData, openEditModal, resendVerificationEmail);

  const onDelete = (ids) => {
    deleteUserMutation.mutate(ids);
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRestore = (ids) => {
    restoreUserMutation.mutate(ids);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    textLabels: tableLocalization('Clientes'),
    customToolbarSelect: (selectedRows, displayData) => getCustomSelectToolBar({
      id: 'zudaClientFilter',
      rows: selectedRows,
      data: displayData,
      onDelete,
      onRestore,
    }),
  };

  return (
    <div className={classes.tableContainer}>
      <DialogLoadingIndicator open={isLoading && data} borderRadius="30px 5px" />
      <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
      />
    </div>
  );
}

ClientTable.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setEditModal: PropTypes.func.isRequired,
};

ClientTable.defaultProps = {
  data: undefined,
};

export default ClientTable;
