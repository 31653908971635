import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.primary.dark,
  },
  content: {
    height: 200,
    width: 296,
    '& text': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  note: {
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: 0.5,
    marginTop: 5,
  },
}));

/**
 * DialogChart component
 *
 * @param {object} { chartInfo, onClose, open }
 * @return {jsx} react component
 */
function DialogChart({ chartInfo, onClose, open }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="chart-dialog-title"
      open={open}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="chart-dialog-title">
        { chartInfo.label }
        <Typography className={classes.note}>{`Últimos ${chartInfo?.data?.length} meses`}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <ResponsiveBar
          data={chartInfo.data}
          keys={[chartInfo.prop]}
          indexBy="date"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          layout="horizontal"
          padding={0.2}
          isInteractive={false}
          enableGridX={false}
          enableGridY={false}
          label={(d) => `${d.value} ${chartInfo.unit}`}
          labelTextColor="#FFFFFF"
          colors={['#6A4C93']}
          margin={{
            left: 45,
          }}
          axisBottom={null}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          theme={{
            textColor: '#ffffff',
            fontSize: 12,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.test}>
        <Button onClick={onClose} color="secondary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogChart.propTypes = {
  chartInfo: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DialogChart;
