import formatISO9075 from 'date-fns/formatISO9075';

const getIdsFromOptions = (options) => options.map((option) => option.value).join();

const userFields = {
  birthdate: (value) => formatISO9075(value),
  height: (value) => Number(value),
  joining_date: (value) => formatISO9075(value),
  plan_id: (value) => (value !== null ? Number(value) : value),
  trainer_id: (value) => (value !== null ? Number(value) : value),
  services_id: (value) => (value.length > 0 ? `[${value.join()}]` : null),
  default: (value) => value,
};

const evaluationFields = {
  user_id: (value) => (value !== null ? Number(value.value) : value),
  comments: (value) => value,
  default: (value) => Number(value),
};

const planFields = {
  price: (value) => Number(value),
  default: (value) => value,
};

const serviceFields = {
  price: (value) => Number(value),
  zuda_price: (value) => Number(value),
  default: (value) => value,
};

const paymentFields = {
  user_id: (value) => (value !== null ? Number(value.value) : value),
  price: (value) => Number(value),
  plan_id: (value) => (value !== null ? Number(value) : value),
  services_id: (value) => (value.length > 0 ? `[${value.join()}]` : null),
  beneficiaries_id: (value) => (value.length > 0 ? `[${getIdsFromOptions(value)}]` : null),
  start_date: (value) => formatISO9075(value),
  end_date: (value) => formatISO9075(value),
  default: (value) => value,
};

const entity = {
  user: userFields,
  evaluation: evaluationFields,
  plan: planFields,
  service: serviceFields,
  payment: paymentFields,
};

const getDataToSave = (data, type) => {
  const fields = entity[type];
  const toSave = {};

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      toSave[key] = (fields[key] || fields.default)(data[key]);
    }
  }

  if (type === 'evaluation') {
    delete toSave.gender;
    delete toSave.height;
  }

  return toSave;
};

export default getDataToSave;
