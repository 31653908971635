import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import AddButton from 'components/layout/AddButton';
import SectionTitle from 'components/layout/SectionTitle';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import routes from 'common/routes/index';
import { defaultEvaluationFilters } from 'common/filters';
import evaluation from 'api/evaluation';
import EvaluationTable from './EvaluationTable';
import EvaluationFilters from './EvaluationFilters';
import CreateEvaluationModal from './CreateEvaluationModal';
import EditEvaluationModal from './EditEvaluationModal';

/**
 * Evaluation component
 *
 * @return {jsx} react component
 */
function Evaluation() {
  const { enqueueSnackbar } = useSnackbar();
  const [evaluationFilters, setEvaluationFilters] = useLocalStorage('zudaEvaluationFilter', defaultEvaluationFilters);
  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['evaluations', { page: currentPage, first: itemsPerPage }, evaluationFilters],
    evaluation.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-evaluations-error',
          variant: 'error',
        });
      },
    },
  );

  const toggleCreateModal = () => {
    setCreateModal(true);
  };

  return (
    <React.Fragment>
      <SectionTitle text={routes.evaluations.title}>
        <AddButton handleClick={toggleCreateModal} />
      </SectionTitle>
      <EvaluationFilters
        filters={evaluationFilters}
        setFilters={setEvaluationFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <EvaluationTable
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
      />
      <CreateEvaluationModal
        open={createModal}
        onClose={setCreateModal}
      />
      <EditEvaluationModal
        open={editModal}
        onClose={setEditModal}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  );
}

export default Evaluation;
