import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import routes from 'common/routes/index';
import BasicInfo from './BasicInfo';
import Password from './Password';
import Services from './Services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down(655)]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      backgroundColor: theme.palette.primary.dark,
      fontSize: theme.typography.pxToRem(18),
      padding: theme.spacing(2),
    },
  },
  leftContainer: {
    flex: `1 0 calc(50% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      marginRight: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  rightContainer: {
    flex: `1 0 calc(50% - ${theme.spacing(2)}px)`,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      marginLeft: 0,
    },
  },
}));

/**
 * ClientInformation component
 *
 * @param {object} { setTitle }
 * @return {jsx} react component
 */
function ClientInformation({ setTitle }) {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  useEffect(() => {
    setTitle(routes.clientInformation.title);
  }, [setTitle]);

  const hasServices = () => (
    authUser.plan_id
    || authUser.trainer_id
    || authUser?.services_id.length > 0
    || authUser.num_classes
  );

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Typography className={classes.title}>{routes.clientInformation.title}</Typography>
      </Hidden>
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <BasicInfo authUser={authUser} setAuthUser={setAuthUser} />
        </div>
        <div className={classes.rightContainer}>
          <Password authUser={authUser} />
          {hasServices && (
            <Services authUser={authUser} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

ClientInformation.propTypes = {
  setTitle: PropTypes.func.isRequired,
};

export default ClientInformation;
