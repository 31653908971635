import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import service from 'api/service';

const useServiceOptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  let serviceOptions = [];

  const {
    isLoading: isServiceLoading,
    isSuccess: isServiceSuccess,
    isError: isServiceError,
    error: serviceError,
    data: services,
  } = useQuery('allServices', service.getAll);

  if (isServiceError) {
    enqueueSnackbar(serviceError.message, {
      key: 'get-all-services-error',
      variant: 'error',
    });
  }

  if (isServiceSuccess && services && typeof services !== 'undefined') {
    serviceOptions = services.data.allServices.map((option) => ({
      value: option.id, label: option.name,
    }));
  }

  return {
    serviceOptions,
    isServiceLoading,
  };
};

export default useServiceOptions;
