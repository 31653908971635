import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * MultipleSelectFieldAdapter component
 *
 * @param {object} {
 *   input, id, label, options, ...rest
 * }
 * @return {jsx} react component
 */
function MultipleSelectFieldAdapter({
  input, id, label, options, ...rest
}) {
  const classes = useStyles();

  return (
    <FormControl {...rest}>
      <InputLabel
        id={id}
        classes={{ focused: classes.focusedLabel }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        value={input.value}
        onChange={(event) => input.onChange(event.target.value)}
        input={<Input />}
        renderValue={(selected) => {
          const labels = options.reduce((acc, curr) => {
            if (selected.indexOf(Number(curr.value)) >= 0) {
              acc.push(curr.label);
            }

            return acc;
          }, []);

          return labels.join(', ');
        }}
        multiple
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={`checkbox-${option.value}`} value={Number(option.value)}>
            <Checkbox checked={input.value.indexOf(Number(option.value)) >= 0} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default MultipleSelectFieldAdapter;
