import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import { genderOptions } from 'common/constants';

/**
 * TrainerFields component
 *
 * @param {object} {
 *   form,
 *   classes,
 *   requiredFields,
 *   disableFuture,
 * }
 * @return {jsx} react component
 */
function TrainerFields({
  form,
  classes,
  requiredFields,
  disableFuture,
}) {
  const isRequired = (field) => requiredFields.includes(field);

  const isFutureDisabled = (field) => disableFuture.includes(field);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={4}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label="Nombres"
            required={isRequired('first_name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label="Apellidos"
            required={isRequired('last_name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label="Email"
            type="email"
            required={isRequired('email')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label="Contraseña"
            shuffle
            onShuffle={form.mutators.generatePassword}
            autoComplete="new-password"
            required={isRequired('password')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="phone"
            name="phone"
            component={TextFieldAdapter}
            label="Teléfono"
            type="tel"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={genderOptions}
            label="Sexo"
            required={isRequired('gender')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label="Fecha de nacimiento"
            disableFuture={isFutureDisabled('birthdate')}
            openTo="year"
            views={['year', 'month', 'date']}
            required={isRequired('birthdate')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="joining_date"
            name="joining_date"
            component={DatePickerFieldAdapter}
            label="Fecha de ingreso"
            disableFuture={isFutureDisabled('joining_date')}
            openTo="year"
            views={['year', 'month', 'date']}
            required={isRequired('joining_date')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12}>
          <Field
            id="specialty"
            name="specialty"
            component={TextFieldAdapter}
            label="Especialidad"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

TrainerFields.defaultProps = {
  requiredFields: [],
  disableFuture: [],
};

TrainerFields.propTypes = {
  form: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disableFuture: PropTypes.arrayOf(PropTypes.string),
};

export default TrainerFields;
