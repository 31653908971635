/* eslint-disable no-loop-func */
import { fromPromise } from '@apollo/client';
import { getLocalStorage, saveAuthInfo, removeAuthInfo } from 'common/utils';
import routes from 'common/routes/index';
import auth from 'api/auth';

const { REACT_APP_BASE_URL } = process.env;

let isRefreshing = false;
let pendingRequests = [];

const getErrors = (data) => {
  let errors = [];

  if (Object.keys(data).length > 0) {
    for (const prop in data) {
      if (Object.prototype.hasOwnProperty.call(data, prop)) {
        errors = [...errors, data[prop]];
      }
    }

    return errors;
  }

  return ['Internal Error'];
};

const getNewToken = async () => {
  const refreshToken = await getLocalStorage('zuda_refresh_token');

  return auth.refreshToken(refreshToken).then((response) => {
    const { data } = response;

    return data;
  });
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorHandler = ({
  response,
  operation,
  graphQLErrors,
  networkError,
  forward,
}) => {
  if (response && 'errors' in response && response.errors.length > 0) {
    for (const [index, err] of response.errors.entries()) {
      // Default error handle
      if ('extensions' in err && 'validation' in err.extensions) {
        const errors = getErrors(err.extensions.validation);
        response.errors[index].message = errors;
      }

      // Unauthenticated error handle
      if (err.message === 'Unauthenticated.' || err.message === 'Not Authenticated') {
        let forward$;

        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .then(({ refreshToken }) => {
                saveAuthInfo(refreshToken);
                resolvePendingRequests();
                return refreshToken;
              })
              .catch(() => {
                pendingRequests = [];
                window.localStorage.clear();
                removeAuthInfo();
                window.location.replace(`${REACT_APP_BASE_URL}${routes.login.path}`);
              })
              .finally(() => {
                isRefreshing = false;
              }),
          )
            .filter((value) => Boolean(value));
        } else {
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            }),
          );
        }

        return forward$.flatMap(() => forward(operation));
      }

      // Login error handle
      if (operation && operation.operationName === 'login') {
        response.errors[index].message = 'Las credenciales ingresadas no concuerdan. Por favor, inténtelo de nuevo';
      }
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      console.log(`[GraphQL error]: ${err.message}`); // eslint-disable-line
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`); // eslint-disable-line
  }

  return null;
};

export default errorHandler;
