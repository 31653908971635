import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import user from 'api/user';

const usePlanOptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  let trainerOptions = [];

  const {
    isLoading: isTrainerLoading,
    isSuccess: isTrainerSuccess,
    isError: isTrainerError,
    error: trainerError,
    data: trainers,
  } = useQuery('allTrainers', user.getTrainersSelectOptions);

  if (isTrainerError) {
    enqueueSnackbar(trainerError.message, {
      key: 'get-all-trainers-error',
      variant: 'error',
    });
  }

  if (isTrainerSuccess && trainers && typeof trainers !== 'undefined') {
    trainerOptions = trainers.data.allTrainers.map(
      (option) => ({ value: option.id, label: option.full_name }),
    );
    trainerOptions.unshift({ value: null, label: 'Ninguno' });
  }

  return {
    trainerOptions,
    isTrainerLoading,
  };
};

export default usePlanOptions;
