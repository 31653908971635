import React from 'react';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { dateFormat } from 'common/constants';

const TextFieldComponent = function TextFieldComponent(props) {
  return (
    <TextField fullWidth {...props} />
  );
};

/**
 * DatePickerFieldAdapter component
 *
 * @param {object} { input, ...rest }
 * @return {jsx} react component
 */
function DatePickerFieldAdapter({ input, ...rest }) {
  return (
    <DatePicker
      clearable
      animateYearScrolling
      value={input.value || null}
      format={dateFormat}
      initialFocusedDate={new Date()}
      onChange={(date) => input.onChange(date)}
      TextFieldComponent={TextFieldComponent}
      cancelLabel="Cancelar"
      clearLabel="Borrar"
      autoOk
      {...rest}
    />
  );
}

DatePickerFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
};

export default DatePickerFieldAdapter;
