/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { ResponsiveLine } from '@nivo/line';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import { makeStyles } from '@material-ui/core/styles';
import evaluation from 'api/evaluation';

const useStyles = makeStyles((theme) => ({
  card: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 30,
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 350,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      width: '100%',
      boxShadow: 'none',
      marginBottom: 0,
    },
  },
  tooltip: {
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px 10px',
  },
  icon: {
    color: '#FFCA3A',
    marginBottom: theme.spacing(2),
  },
  message: {
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  fieldSelector: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 30,
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(5),
    position: 'relative',
    zIndex: 1,
    textAlign: 'end',
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      paddingRight: theme.spacing(4),
    },
  },
}));

const getYAxisLegend = (field) => {
  const legends = {
    weight: () => 'Peso kg',
    muscle_percentage: () => 'Porcentaje de musculo',
    fat_percentage: () => 'Porcentaje de grasa',
    fat_weight: () => 'Peso graso kg',
    lean_weight: () => 'Peso magro kg',
    visceral_fat: () => 'Grasa visceral kg',
  };

  return legends[field]();
};

const getTooltipLegend = (field, value) => {
  const legends = {
    weight: () => (
      <span>
        Peso:
        {' '}
        {value}
        {' '}
        kg
      </span>
    ),
    muscle_percentage: () => (
      <span>
        % de musculo:
        {' '}
        {value}
        %
      </span>
    ),
    fat_percentage: () => (
      <span>
        % de grasa:
        {' '}
        {value}
        %
      </span>
    ),
    fat_weight: () => (
      <span>
        Peso graso:
        {' '}
        {value}
        {' '}
        kg
      </span>
    ),
    lean_weight: () => (
      <span>
        Peso magro:
        {' '}
        {value}
        {' '}
        kg
      </span>
    ),
    visceral_fat: () => (
      <span>
        Grasa visceral:
        {' '}
        {value}
        {' '}
        kg
      </span>
    ),
  };

  return legends[field]();
};

/**
 * LineChartCard component
 *
 * @param {object} { userId }
 * @return {jsx} react component
 */
function LineChartCard({ userId }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [field, setField] = useState('weight');
  const [chartData, setChartData] = useState([]);
  const totalEvaluations = _get(chartData, '[0].data.length', 0);

  const {
    isLoading,
    isFetching,
  } = useQuery(
    ['evaluationChartData', { user_id: userId, field }],
    evaluation.getChartData,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-clients-error',
          variant: 'error',
        });
      },
      onSuccess: (data) => {
        const { data: { getChartData: points } } = data;
        const chartPoints = points.map((point) => ({ x: point.date, y: point.value }));

        setChartData([
          {
            id: field,
            color: '#FFCA3A',
            data: chartPoints,
          },
        ]);
      },
    },
  );

  const onChange = (event) => {
    setField(event.target.value);
  };

  const customTooltip = ({ point }) => (
    <div className={classes.tooltip}>
      <div>
        {getTooltipLegend(field, point.data.y)}
      </div>
      <div>
        Fecha:&nbsp;
        {point.data.x}
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.fieldSelector}>
        <FormControl>
          <Select
            labelId="line-chart-selector"
            id="line-chart-selector"
            value={field}
            onChange={onChange}
            disabled={isLoading || totalEvaluations < 2}
          >
            <MenuItem value="weight">Peso</MenuItem>
            <MenuItem value="muscle_percentage">Porcentaje de musculo</MenuItem>
            <MenuItem value="fat_percentage">Porcentaje de grasa</MenuItem>
            <MenuItem value="fat_weight">Peso graso</MenuItem>
            <MenuItem value="lean_weight">Peso magro</MenuItem>
            <MenuItem value="visceral_fat">Grasa visceral</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.card}>
        {(isLoading || isFetching) ? (
          <CircularProgress size={34} thickness={5} color="secondary" />
        ) : (
          <React.Fragment>
            {totalEvaluations < 2 ? (
              <React.Fragment>
                <EmojiEmotionsIcon className={classes.icon} fontSize="large" />
                <Typography className={classes.message} color="textPrimary">
                  Te
                  { (2 - totalEvaluations) === 1 ? ' hace ' : ' hacen ' }
                  falta
                  &nbsp;
                  { 2 - totalEvaluations }
                  &nbsp;
                  { (2 - totalEvaluations) === 1 ? 'valoración' : 'valoraciones' }
                  &nbsp;
                  mas para ver tu progreso
                </Typography>
              </React.Fragment>
            ) : (
              <ResponsiveLine
                data={chartData}
                margin={{
                  top: 20,
                  right: 17,
                  bottom: 46,
                  left: 60,
                }}
                curve="cardinal"
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Fecha',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: getYAxisLegend(field),
                  legendOffset: -50,
                  legendPosition: 'middle',
                }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                tooltip={customTooltip}
                useMesh
                theme={{
                  background: '#4a3566',
                  textColor: '#ffffff',
                  fontSize: 12,
                  axis: {
                    ticks: {
                      line: {
                        stroke: '#876fa8',
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: '#876fa8',
                    },
                  },
                }}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

LineChartCard.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default LineChartCard;
