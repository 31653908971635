import {
  hasValue,
  alphaSpace,
  alphaNumSpace,
  minLength,
  password,
  email,
  lengthBetween,
  isValidDate,
  positiveFloat,
  positiveInt,
} from './fieldValidations';

const defaultUserValidations = (values) => {
  const errors = {};

  if (!hasValue(values.first_name)) {
    errors.first_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.first_name)) {
    errors.first_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.first_name, 2)) {
    errors.first_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.last_name)) {
    errors.last_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.last_name)) {
    errors.last_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.last_name, 2)) {
    errors.last_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.email)) {
    errors.email = 'Este campo no puede estar vacío';
  }

  if (!email(values.email)) {
    errors.email = 'Este campo debe ser un email valido';
  }

  if (hasValue(values.password) && !password(values.password)) {
    errors.password = 'La contraseña suministrada debe estar compuesta mínimo de ocho caracteres, entre los cuales debe haber al menos una letra minúscula, una mayúscula y un número';
  }

  if (hasValue(values.phone) && !lengthBetween(values.phone, 7, 10)) {
    errors.phone = 'Este campo debe tener entre siete y diez números';
  }

  if (!isValidDate(values.birthdate)) {
    errors.birthdate = 'Este campo solo admite fechas';
  }

  if (!isValidDate(values.joining_date)) {
    errors.joining_date = 'Este campo solo admite fechas';
  }

  return errors;
};

const defaultUpdateUserProfileValidations = (values) => {
  const errors = {};

  if (!hasValue(values.first_name)) {
    errors.first_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.first_name)) {
    errors.first_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.first_name, 2)) {
    errors.first_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.last_name)) {
    errors.last_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.last_name)) {
    errors.last_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.last_name, 2)) {
    errors.last_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.email)) {
    errors.email = 'Este campo no puede estar vacío';
  }

  if (!email(values.email)) {
    errors.email = 'Este campo debe ser un email valido';
  }

  if (hasValue(values.phone) && !lengthBetween(values.phone, 7, 10)) {
    errors.phone = 'Este campo debe tener entre siete y diez números';
  }

  if (!isValidDate(values.birthdate)) {
    errors.birthdate = 'Este campo solo admite fechas';
  }

  return errors;
};

const defaultServicesValidations = (values) => {
  const errors = {};

  if (!hasValue(values.name)) {
    errors.name = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.name, 2)) {
    errors.name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.price)) {
    errors.price = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.price)) {
    errors.price = 'Este campo solo admite enteros positivos';
  }

  return errors;
};

export const validateLogin = (values) => {
  const errors = {};

  if (!email(values.username)) {
    errors.username = 'Debes ingresar un email valido';
  }

  if (!hasValue(values.password)) {
    errors.password = 'La contraseña no puede estar vacía';
  }

  return errors;
};

export const validateClient = (values) => {
  const errors = defaultUserValidations(values);

  if (!hasValue(values.password)) {
    errors.password = 'Este campo no puede estar vacío';
  }

  if (!positiveFloat(values.height)) {
    errors.height = 'Este campo solo admite números decimales mayores a cero';
  }

  if (hasValue(values.eps) && !alphaNumSpace(values.eps)) {
    errors.eps = 'Este campo solo admite letras, números, puntos y espacios';
  }

  if (hasValue(values.note) && !alphaNumSpace(values.note)) {
    errors.note = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validateUpdateClient = (values) => {
  const errors = defaultUserValidations(values);

  if (!positiveFloat(values.height)) {
    errors.height = 'Este campo solo admite números decimales mayores a cero';
  }

  if (hasValue(values.eps) && !alphaNumSpace(values.eps)) {
    errors.eps = 'Este campo solo admite letras, números, puntos y espacios';
  }

  if (hasValue(values.note) && !alphaNumSpace(values.note)) {
    errors.note = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validateUpdateClientProfile = (values) => {
  const errors = defaultUpdateUserProfileValidations(values);

  if (!positiveFloat(values.height)) {
    errors.height = 'Este campo solo admite números decimales mayores a cero';
  }

  if (hasValue(values.eps) && !alphaNumSpace(values.eps)) {
    errors.eps = 'Este campo solo admite letras, números, puntos y espacios';
  }

  if (hasValue(values.note) && !alphaNumSpace(values.note)) {
    errors.note = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validateUpdatePassword = (values) => {
  const errors = {};

  if (!hasValue(values.password)) {
    errors.password = 'Este campo no puede estar vacío';
  }

  if (hasValue(values.password) && !password(values.password)) {
    errors.password = 'La contraseña suministrada debe estar compuesta mínimo de ocho caracteres, entre los cuales debe haber al menos una letra minúscula, una mayúscula y un número';
  }

  if (!hasValue(values.confirm)) {
    errors.confirm = 'Este campo no puede estar vacío';
  }

  if (hasValue(values.confirm) && values.password !== values.confirm) {
    errors.confirm = 'Las contraseñas no coinciden';
  }

  return errors;
};

export const validateTrainer = (values) => {
  const errors = defaultUserValidations(values);

  if (!hasValue(values.password)) {
    errors.password = 'Este campo no puede estar vacío';
  }

  if (hasValue(values.specialty) && !alphaNumSpace(values.specialty)) {
    errors.specialty = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validateUpdateTrainer = (values) => {
  const errors = defaultUserValidations(values);

  if (hasValue(values.specialty) && !alphaNumSpace(values.specialty)) {
    errors.specialty = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validateUpdateTrainerProfile = (values) => {
  const errors = defaultUpdateUserProfileValidations(values);

  if (hasValue(values.specialty) && !alphaNumSpace(values.specialty)) {
    errors.specialty = 'Este campo solo admite letras, números, puntos y espacios';
  }

  return errors;
};

export const validatePlan = (values) => {
  const errors = defaultServicesValidations(values);

  return errors;
};

export const validateService = (values) => {
  const errors = defaultServicesValidations(values);

  if (!hasValue(values.zuda_price)) {
    errors.zuda_price = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.zuda_price)) {
    errors.zuda_price = 'Este campo solo admite enteros positivos';
  }

  return errors;
};

export const validateEvaluation = (values) => {
  const errors = {};

  if (!hasValue(values.weight)) {
    errors.weight = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.weight)) {
    errors.weight = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.biceps)) {
    errors.biceps = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.biceps)) {
    errors.biceps = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.triceps)) {
    errors.triceps = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.triceps)) {
    errors.triceps = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.subscapular)) {
    errors.subscapular = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.subscapular)) {
    errors.subscapular = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.suprailiac)) {
    errors.suprailiac = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.suprailiac)) {
    errors.suprailiac = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.abdominal)) {
    errors.abdominal = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.abdominal)) {
    errors.abdominal = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.acro_acro)) {
    errors.acro_acro = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.acro_acro)) {
    errors.acro_acro = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.neck_hip)) {
    errors.neck_hip = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.neck_hip)) {
    errors.neck_hip = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.hip_ankle)) {
    errors.hip_ankle = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.hip_ankle)) {
    errors.hip_ankle = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.acro_wrist)) {
    errors.acro_wrist = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.acro_wrist)) {
    errors.acro_wrist = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.neck)) {
    errors.neck = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.neck)) {
    errors.neck = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.thorax)) {
    errors.thorax = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.thorax)) {
    errors.thorax = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.high_waist)) {
    errors.high_waist = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.high_waist)) {
    errors.high_waist = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.low_waist)) {
    errors.low_waist = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.low_waist)) {
    errors.low_waist = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.hip)) {
    errors.hip = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.hip)) {
    errors.hip = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.high_left_leg)) {
    errors.high_left_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.high_left_leg)) {
    errors.high_left_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.medium_left_leg)) {
    errors.medium_left_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.medium_left_leg)) {
    errors.medium_left_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.low_left_leg)) {
    errors.low_left_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.low_left_leg)) {
    errors.low_left_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.left_calf)) {
    errors.left_calf = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.left_calf)) {
    errors.left_calf = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.high_right_leg)) {
    errors.high_right_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.high_right_leg)) {
    errors.high_right_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.medium_right_leg)) {
    errors.medium_right_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.medium_right_leg)) {
    errors.medium_right_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.low_right_leg)) {
    errors.low_right_leg = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.low_right_leg)) {
    errors.low_right_leg = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.right_calf)) {
    errors.right_calf = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.right_calf)) {
    errors.right_calf = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.left_arm)) {
    errors.left_arm = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.left_arm)) {
    errors.left_arm = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.right_arm)) {
    errors.right_arm = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.right_arm)) {
    errors.right_arm = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.muscle_percentage)) {
    errors.muscle_percentage = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.muscle_percentage)) {
    errors.muscle_percentage = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.visceral_fat)) {
    errors.visceral_fat = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.visceral_fat)) {
    errors.visceral_fat = 'Este campo solo admite números decimales mayores a cero';
  }

  if (!hasValue(values.biological_age)) {
    errors.biological_age = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.biological_age)) {
    errors.biological_age = 'Este campo solo admite números decimales mayores a cero';
  }

  return errors;
};

export const validatePayment = (values) => {
  const errors = {};

  if (!hasValue(values.price)) {
    errors.price = 'Este campo no puede estar vacío';
  }

  if (!positiveInt(values.price)) {
    errors.price = 'Este campo solo admite enteros positivos';
  }

  if (!isValidDate(values.start_date)) {
    errors.birthdate = 'Este campo solo admite fechas';
  }

  if (!isValidDate(values.end_date)) {
    errors.joining_date = 'Este campo solo admite fechas';
  }

  return errors;
};
