import { addDecimals } from 'common/utils';
import { stringToDate } from './utils';

export const clientInitialValues = (values) => {
  const initialValues = {
    ...values,
    password: '',
    joining_date: stringToDate(values.joining_date),
    birthdate: stringToDate(values.birthdate),
  };

  delete initialValues.id;
  delete initialValues.deleted_at;
  delete initialValues.plan;
  delete initialValues.trainer;

  return initialValues;
};

export const trainerInitialValues = (values) => {
  const initialValues = {
    ...values,
    password: '',
    joining_date: stringToDate(values.joining_date),
    birthdate: stringToDate(values.birthdate),
  };

  delete initialValues.id;
  delete initialValues.deleted_at;
  delete initialValues.total_clients;

  return initialValues;
};

export const evaluationInitialValues = (values) => {
  const avoid = ['id', 'comments', 'user', 'user_birthdate', 'user_gender', 'user_height', 'user_id', 'created_at', 'deleted_at'];
  const initialValues = {};

  initialValues.user_id = {
    label: values.user,
    value: values.user_id,
    gender: values.user_gender,
    height: addDecimals(values.user_height),
    birthdate: values.user_birthdate,
  };

  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key) && !avoid.includes(key)) {
      initialValues[key] = key === 'age' ? values[key] : addDecimals(values[key]);
    }
  }

  return initialValues;
};

export const planInitialValues = (values) => {
  const initialValues = {
    ...values,
    price: values.price.replace(/[,$]/g, ''),
  };

  delete initialValues.id;
  delete initialValues.deleted_at;

  return initialValues;
};

export const serviceInitialValues = (values) => {
  const initialValues = {
    ...values,
    price: values.price.replace(/[,$]/g, ''),
    zuda_price: values.zuda_price.replace(/[,$]/g, ''),
  };

  delete initialValues.id;
  delete initialValues.deleted_at;

  return initialValues;
};

export const paymentInitialValues = (values) => {
  const initialValues = {
    user_id: {
      label: values.user,
      value: values.user_id,
    },
    beneficiaries_id: values.beneficiaries_id,
    price: values.price.replace(/[,$]/g, ''),
    start_date: stringToDate(values.start_date),
    end_date: stringToDate(values.end_date),
    plan_id: values.plan_id,
    services_id: values.services_id,
    comments: values.comments,
  };

  return initialValues;
};
