import { gql } from '@apollo/client';
import client from 'apollo/client';
import { evaluationFilters } from 'common/filters';

/**
 * Find evaluation by id method
 * @param {Object} payload
 * @returns {Promise}
 */
const find = (payload) => {
  const id = payload.queryKey[1];

  return client.query({
    query: gql`
      query evaluation {
        evaluation(id: ${id}) {
          user_id {
            id
            full_name
            first_name
            last_name
            gender
            height
            birthdate
            photo
          }
          age
          weight
          biceps
          triceps
          subscapular
          suprailiac
          abdominal
          acro_acro
          neck_hip
          hip_ankle
          acro_wrist
          neck
          thorax
          high_waist
          low_waist
          hip
          high_left_leg
          medium_left_leg
          low_left_leg
          left_calf
          high_right_leg
          medium_right_leg
          low_right_leg
          right_calf
          left_arm
          right_arm
          bmi
          fat_percentage
          body_density
          fat_weight
          lean_weight
          normal_fat_percentage
          ideal_fat_percentage
          metabolic_rate
          ideal_lean_weight
          muscle_percentage
          visceral_fat
          biological_age
          comments
          created_at
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * List evaluations method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = evaluationFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query evaluations {
        evaluations(
          first: ${first}
          page: ${page}
          ${filters.user_id}
          ${filters.soft_delete}
          ${filters.created_at}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            user_id {
              id
              full_name
              gender
              height
              birthdate
            }
            age
            weight
            biceps
            triceps
            subscapular
            suprailiac
            abdominal
            acro_acro
            neck_hip
            hip_ankle
            acro_wrist
            neck
            thorax
            high_waist
            low_waist
            hip
            high_left_leg
            medium_left_leg
            low_left_leg
            left_calf
            high_right_leg
            medium_right_leg
            low_right_leg
            right_calf
            left_arm
            right_arm
            bmi
            fat_percentage
            body_density
            fat_weight
            lean_weight
            normal_fat_percentage
            ideal_fat_percentage
            metabolic_rate
            ideal_lean_weight
            muscle_percentage
            visceral_fat
            biological_age
            comments
            created_at
            deleted_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Create evaluation method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createEvaluation($input: CreateEvaluationInput!) {
      createEvaluation(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/*
 * Update evaluation method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) => client.mutate({
  mutation: gql`
    mutation updateEvaluation($id: ID!, $input: UpdateEvaluationInput!) {
      updateEvaluation(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

/**
 * Remove evaluations method
 * @param  {String} ids
 * @return {Promise}
 */
const remove = (ids) => client.mutate({
  mutation: gql`
    mutation deleteEvaluations($id: [ID!]!) {
      deleteEvaluations(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Restore evaluations method
 * @param  {String} ids
 * @return {Promise}
 */
const restore = (ids) => client.mutate({
  mutation: gql`
    mutation restoreEvaluations($id: [ID!]!) {
      restoreEvaluations(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Get chart data method
 * @param {Object} payload
 * @return {Promise}
 */
const getChartData = (payload) => {
  const { user_id, field } = payload.queryKey[1];

  return client.query({
    query: gql`
      query getChartData {
        getChartData(user_id: ${Number(user_id)}, field: "${field}") {
          date
          value
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Get historical data method
 * @param {Object} payload
 * @returns {Promise}
 */
const getHistoricalData = (payload) => {
  const id = payload.queryKey[1];

  return client.query({
    query: gql`
      query getHistoricalData {
        getHistoricalData(id: ${id}) {
          current {
            user_id {
              id
              full_name
              first_name
              last_name
              gender
              height
              birthdate
              photo
            }
            age
            weight
            biceps
            triceps
            subscapular
            suprailiac
            abdominal
            acro_acro
            neck_hip
            hip_ankle
            acro_wrist
            neck
            thorax
            high_waist
            low_waist
            hip
            high_left_leg
            medium_left_leg
            low_left_leg
            left_calf
            high_right_leg
            medium_right_leg
            low_right_leg
            right_calf
            left_arm
            right_arm
            bmi
            fat_percentage
            body_density
            fat_weight
            lean_weight
            normal_fat_percentage
            ideal_fat_percentage
            metabolic_rate
            ideal_lean_weight
            muscle_percentage
            visceral_fat
            biological_age
            comments
            created_at
          }
          previous {
            weight
            biceps
            triceps
            subscapular
            suprailiac
            abdominal
            acro_acro
            neck_hip
            hip_ankle
            acro_wrist
            neck
            thorax
            high_waist
            low_waist
            hip
            high_left_leg
            medium_left_leg
            low_left_leg
            left_calf
            high_right_leg
            medium_right_leg
            low_right_leg
            right_calf
            left_arm
            right_arm
            bmi
            fat_percentage
            body_density
            fat_weight
            lean_weight
            normal_fat_percentage
            ideal_fat_percentage
            metabolic_rate
            ideal_lean_weight
            muscle_percentage
            visceral_fat
            biological_age
            created_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

export default {
  find,
  list,
  create,
  update,
  remove,
  restore,
  getChartData,
  getHistoricalData,
};
