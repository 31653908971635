import React from 'react';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import lightFormat from 'date-fns/lightFormat';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { defaultTableOptions, simpleTableLocalization, getDownloadButton } from 'common/table/utils';
import { dateFormat } from 'common/constants';
import { formatCurrency } from 'common/utils';
import routes from 'common/routes/index';
import { clientProfileTableClasses } from 'common/muiSharedClasses';
import payment from 'api/payment';

const useStyles = makeStyles((theme) => clientProfileTableClasses(theme, {
  borderRadius: '0px',
  padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  boxShadow: 'none',
}));

/**
 * PaymentsTable component
 *
 * @param {object} { payments }
 * @return {jsx} react component
 */
function PaymentsTable({ payments }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const invoicePdfUrlMutation = useMutation((id) => payment.getDownloadPdfUrl(id), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'invoice-pdf-url-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { downloadInvoice: { url } } }) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    },
  });

  const tableData = payments.map((item) => ({
    ...item,
    price: formatCurrency(item.price),
    created_at: lightFormat(new Date(item.created_at), dateFormat),
  }));

  const onDownload = (rowIndex) => {
    const { id } = tableData[rowIndex];
    invoicePdfUrlMutation.mutate(id);
  };

  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: 'Pagado', name: 'created_at' },
    { label: 'Precio', name: 'price' },
    {
      name: 'Descargar',
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getDownloadButton({
          rowIndex,
          buttonClasses: classes,
          disabled: invoicePdfUrlMutation.isLoading,
          onClick: onDownload,
        }),
      },
    },
  ];

  const options = {
    ...defaultTableOptions,
    viewColumns: false,
    pagination: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    jumpToPage: false,
    textLabels: simpleTableLocalization('Pagos'),
  };

  return (
    <article className={classes.card}>
      {tableData.length > 0 ? (
        <React.Fragment>
          <MUIDataTable
            title="Ultimos pagos"
            columns={columns}
            data={tableData}
            options={options}
          />
          <div className={classes.seeMoreContainer}>
            <Button
              className={classes.seeMore}
              variant="contained"
              color="secondary"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={routes.clientPayments.path}
            >
              Ver mas
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.emptyTableMessage}>No tienes pagos registrados</div>
      )}
    </article>
  );
}

PaymentsTable.propTypes = {
  payments: PropTypes.array.isRequired,
};

export default PaymentsTable;
