import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import AddButton from 'components/layout/AddButton';
import SectionTitle from 'components/layout/SectionTitle';
import ClientCount from 'components/shared/ClientsCount';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import routes from 'common/routes/index';
import { defaultClientFilters } from 'common/filters';
import user from 'api/user';
import ClientFilters from './ClientFilters';
import ClientTable from './ClientTable';
import CreateClientModal from './CreateClientModal';
import EditClientModal from './EditClientModal';

const useStyles = makeStyles((theme) => ({
  clientCountContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      padding: 0,
    },
  },
}));

/**
 * Client component
 *
 * @return {jsx} react component
 */
function Client() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [clientFilters, setClientFilters] = useLocalStorage('zudaClientFilter', defaultClientFilters);
  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data,
    isFetching,
    isLoading,
  } = useQuery(
    ['clients', { page: currentPage, first: itemsPerPage }, clientFilters],
    user.listClients,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-clients-error',
          variant: 'error',
        });
      },
    },
  );

  const toggleCreateModal = () => {
    setCreateModal(true);
  };

  return (
    <React.Fragment>
      <div className={classes.clientCountContainer}>
        <ClientCount />
      </div>
      <SectionTitle text={routes.clients.title}>
        <AddButton handleClick={toggleCreateModal} />
      </SectionTitle>
      <ClientFilters
        filters={clientFilters}
        setFilters={setClientFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <ClientTable
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
      />
      <CreateClientModal
        open={createModal}
        onClose={setCreateModal}
      />
      <EditClientModal
        open={editModal}
        onClose={setEditModal}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  );
}

export default Client;
