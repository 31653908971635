import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import IntegerFieldAdapter from 'components/shared/IntegerFieldAdapter';

/**
 * PlanFields component
 *
 * @param {object} { classes }
 * @return {jsx} react component
 */
function PlanFields({ classes }) {
  return (
    <Grid container spacing={3} className={classes.gridMargin}>
      <Grid item xs={12} sm={6}>
        <Field
          id="name"
          name="name"
          component={TextFieldAdapter}
          label="Nombre"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          id="price"
          name="price"
          component={IntegerFieldAdapter}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          label="Precio"
          required
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

PlanFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PlanFields;
