export const roles = {
  admin: 'ADMINISTRADOR',
  trainer: 'ENTRENADOR',
  nutritionist: 'NUTRICIONISTA',
  client: 'CLIENTE',
};

export const gender = {
  female: 'FEMENINO',
  male: 'MASCULINO',
};

export const genderOptions = [
  { value: '', label: 'Ninguno' },
  { value: gender.female, label: gender.female },
  { value: gender.male, label: gender.male },
];

export const numClasses = [
  12, 16, 20,
];

export const numClassesOptions = [
  { value: '', label: 'Ninguno' },
  { value: numClasses[0], label: numClasses[0] },
  { value: numClasses[1], label: numClasses[1] },
  { value: numClasses[2], label: numClasses[2] },
];

export const isZudaOptions = [
  { value: '', label: 'Ninguno' },
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
];

export const isActiveOptions = [
  { value: true, label: 'Si' },
  { value: false, label: 'No' },
];

export const trashed = {
  only: 'ONLY',
  without: 'WITHOUT',
};

export const trashedOptions = [
  { value: trashed.only, label: 'Si' },
  { value: trashed.without, label: 'No' },
];

export const membershipStatus = {
  expired: { text: 'MORA', color: '#FF595E' },
  closeToExpire: { text: 'CERCA A VENCER', color: '#FFCA3A' },
  upToDate: { text: 'AL DIA', color: '#1982C4' },
};

export const timePeriod = {
  thisMonth: 'THIS_MONTH',
  lastMonth: 'LAST_MONTH',
  sixMonths: 'SIX_MONTHS',
  thisYear: 'THIS_YEAR',
};

export const debounceWait = 500;

export const dateFormat = 'yyyy-MM-dd';

export const optionsLimit = 5;
