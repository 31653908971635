import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import { removeAuthInfo } from 'common/utils';
import routes from 'common/routes/index';
import { roles } from 'common/constants';
import auth from 'api/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100%',
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  homeIcon: {
    marginRight: 12,
  },
  logoutButton: {
    borderRadius: theme.spacing(2),
    marginRight: -12,
  },
  content: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

/**
 * MyClientsLayout component
 *
 * @param {object} { component: Component, ...rest }
 * @return {jsx} react component
 */
function MyClientsLayout({ component: Component, ...rest }) {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const logoutMutation = useMutation(auth.logout, {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'logout-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      window.localStorage.clear();
      removeAuthInfo();
      setAuthUser({});
    },
  });

  const goToMyClients = () => {
    history.push(routes.myClients.path);
  };

  const goToProfile = () => {
    if (authUser.role === roles.trainer) {
      history.push(routes.trainerProfile.path);
    } else {
      history.push(routes.nutritionistProfile.path);
    }
  };

  const logout = () => {
    logoutMutation.mutate();
  };

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <Tooltip title="Mis Clientes" aria-label="my-clients">
              <IconButton
                className={classes.homeIcon}
                edge="start"
                color="inherit"
                aria-label="my-clients"
                onClick={goToMyClients}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mi Perfil" aria-label="my-profile">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="profile"
                onClick={goToProfile}
              >
                <AccountCircleIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Typography variant="h6">
            Zuda
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            className={classes.logoutButton}
            onClick={logout}
          >
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Component {...rest} />
      </div>
    </Container>
  );
}

MyClientsLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default MyClientsLayout;
