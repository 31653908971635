import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import createDecorator from 'final-form-calculate';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validateEvaluation } from 'common/form/validations';
import { getAge } from 'common/utils';
import { resetForm, getFieldsToUpdate } from 'common/form/utils';
import { evaluationInitialValues } from 'common/form/initialValues';
import getDataToSave from 'common/form/dataToSave';
import {
  getBmi,
  getBodyDensity,
  getFatPercentage,
  getFatWeight,
  getLeanWeight,
  getNormalFatPercentage,
  getIdealFatPercentage,
  getMetabolicRate,
  getIdealLeanWeight,
} from 'common/evaluationUtils';
import { formModalClasses } from 'common/muiSharedClasses';
import evaluation from 'api/evaluation';
import EvaluationFields from './EvaluationFields';

const useStyles = makeStyles(formModalClasses);

const calculator = createDecorator(
  {
    field: 'user_id',
    updates: {
      gender: (userIdValue) => userIdValue?.gender || '',
      height: (userIdValue) => userIdValue?.height || '',
      age: (userIdValue, allValues, prevValues) => {
        if (Object.keys(prevValues).length > 0 && prevValues.user_id.value !== userIdValue.value) {
          return getAge(new Date(), new Date(userIdValue?.birthdate || ''));
        }

        return allValues.age;
      },
    },
  },
);

/**
 * EditEvaluationModal component
 *
 * @param {object} { open, onClose, selectedItem }
 * @return {jsx} react component
 */
function EditEvaluationModal({ open, onClose, selectedItem }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      const values = evaluationInitialValues(selectedItem);
      setInitialValues(values);
    }
  }, [selectedItem]);

  const closeModal = () => {
    onClose(false);
  };

  const updateEvaluationMutation = useMutation((payload) => evaluation.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-evaluation-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Valoración editada con éxito', {
        key: 'update-evaluation-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('evaluations');
    },
  });

  const calculateFields = (args, state, tools) => {
    const {
      formState: {
        values: {
          age, height, gender, weight, biceps, triceps, subscapular, suprailiac,
        },
      },
    } = state;

    const foldSum = Number(biceps) + Number(triceps) + Number(subscapular) + Number(suprailiac);
    const bmi = getBmi(weight, height);
    const bodyDensity = getBodyDensity(gender, age, foldSum);
    const fatPercentage = getFatPercentage(bodyDensity);
    const fatWeight = getFatWeight(fatPercentage, weight);
    const leanWeight = getLeanWeight(weight, fatWeight);
    const normalFatPercentage = getNormalFatPercentage(gender);
    const idealFatPercentage = getIdealFatPercentage(gender);
    const metabolicRate = getMetabolicRate(leanWeight);
    const idealLeanWeight = getIdealLeanWeight(gender, leanWeight);

    tools.changeValue(state, 'bmi', () => bmi);
    tools.changeValue(state, 'body_density', () => bodyDensity);
    tools.changeValue(state, 'fat_percentage', () => fatPercentage);
    tools.changeValue(state, 'fat_weight', () => fatWeight);
    tools.changeValue(state, 'lean_weight', () => leanWeight);
    tools.changeValue(state, 'normal_fat_percentage', () => normalFatPercentage);
    tools.changeValue(state, 'ideal_fat_percentage', () => idealFatPercentage);
    tools.changeValue(state, 'metabolic_rate', () => metabolicRate);
    tools.changeValue(state, 'ideal_lean_weight', () => idealLeanWeight);
  };

  const onSubmit = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const data = getDataToSave(fieldsToUpdate, 'evaluation');
    updateEvaluationMutation.mutate({ id: selectedItem.id, input: data });
  };

  if (initialValues === null) {
    return false;
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateEvaluation}
      onSubmit={onSubmit}
      decorators={[
        calculator,
      ]}
      mutators={{
        resetForm,
        calculateFields,
      }}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="update-evaluation-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={updateEvaluationMutation.isLoading} />
            <DialogTitle id="update-evaluation-modal">Editar Valoración</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <EvaluationFields classes={classes} form={form} />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={updateEvaluationMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={updateEvaluationMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || updateEvaluationMutation.isLoading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

EditEvaluationModal.defaultProps = {
  selectedItem: null,
};

EditEvaluationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedItem: PropTypes.object,
};

export default EditEvaluationModal;
