import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import MobileDrawerContext from 'context/MobileDrawerContext';
import NavigationMenu from './NavigationMenu';

const useStyles = makeStyles({
  drawerPaper: {
    width: (props) => props.width,
  },
});

/**
 * TemporaryDrawer component
 *
 * @param {object} props
 * @return {jsx} react component
 */
function TemporaryDrawer(props) {
  const classes = useStyles(props);
  const { mobileDrawer, setMobileDrawer } = useContext(MobileDrawerContext);
  const container = window !== undefined ? () => window.document.body : undefined;

  const onDrawerToggle = () => {
    setMobileDrawer(false);
  };

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileDrawer}
      onClose={onDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <NavigationMenu mobile />
    </Drawer>
  );
}

export default TemporaryDrawer;
