import formatISO9075 from 'date-fns/formatISO9075';
import isDate from 'date-fns/isDate';
import { roles, trashed } from 'common/constants';

const getIdsFromOptions = (options) => options.map((option) => option.value);

const isValidFilter = (filterObject, filter, type) => {
  if (!(filter in filterObject)) {
    return false;
  }

  if (typeof filterObject[filter] === 'boolean') {
    return filterObject[filter] !== null;
  }

  if (type === 'range') {
    return (
      filterObject[filter] !== null
      && 'from' in filterObject[filter]
      && filterObject[filter]?.from !== null
      && 'to' in filterObject[filter]
    );
  }

  if (Number.isInteger(filterObject[filter])) {
    return typeof filterObject[filter] !== 'undefined' && filterObject[filter] !== null;
  }

  if (typeof filterObject[filter] === 'object') {
    return filterObject[filter] !== null && Object.keys(filterObject[filter]).length > 0;
  }

  return typeof filterObject[filter] !== 'undefined' && filterObject[filter] !== null && filterObject[filter].length > 0;
};

export const getFormatedFilters = (values) => {
  if ('created_at' in values) {
    const { created_at: { from = null, to = null } } = values;

    const formatedFilter = {
      from: isDate(from) ? formatISO9075(from) : from,
      to: isDate(to) ? formatISO9075(to) : to,
    };

    return { ...values, created_at: formatedFilter };
  }

  return values;
};

export const defaultClientFilters = {
  user_id: [],
  gender: null,
  joining_date: null,
  plan_id: [],
  trainer_id: [],
  services_id: [],
  is_zuda: null,
  is_active: true,
  soft_delete: trashed.without,
};

export const defaultMyClientsFilters = (role, id) => {
  const filters = {
    user_id: [],
    trainer_id: [],
    is_zuda: true,
    is_active: true,
    soft_delete: trashed.without,
  };

  if (role === roles.trainer) {
    filters.trainer_id = [id];
    filters.plan_id = [];
  }

  return filters;
};

export const defaultTrainerFilters = {
  user_id: [],
  gender: null,
  joining_date: null,
  soft_delete: trashed.without,
};

export const defaultEvaluationFilters = {
  user_id: [],
  created_at: null,
  soft_delete: trashed.without,
};

export const defaultPlanFilters = {
  soft_delete: trashed.without,
};

export const defaultServiceFilters = {
  soft_delete: trashed.without,
};

export const defaultPaymentFilters = {
  user_id: [],
  plan_id: [],
  services_id: [],
  soft_delete: trashed.without,
};

export const userFilters = (filters) => {
  const user_id = isValidFilter(filters, 'user_id') ? `user_id: [${getIdsFromOptions(filters.user_id)}]` : '';
  const gender = isValidFilter(filters, 'gender') ? `gender: ${filters.gender}` : '';
  const joining_date = isValidFilter(filters, 'joining_date') ? `joining_date: { from: ${filters.joining_date.from}, to: ${filters.joining_date.to} }` : '';
  const plan_id = isValidFilter(filters, 'plan_id') ? `plan_id: [${filters.plan_id}]` : '';
  const trainer_id = isValidFilter(filters, 'trainer_id') ? `trainer_id: [${filters.trainer_id}]` : '';
  const services_id = isValidFilter(filters, 'services_id') ? `services_id: [${filters.services_id}]` : '';
  const is_zuda = isValidFilter(filters, 'is_zuda') ? `is_zuda: ${filters.is_zuda}` : '';
  const is_active = isValidFilter(filters, 'is_active') ? `is_active: ${filters.is_active}` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete') ? `trashed: ${filters.soft_delete}` : `trashed: ${trashed.without}`;

  return {
    user_id,
    gender,
    joining_date,
    plan_id,
    trainer_id,
    services_id,
    is_zuda,
    is_active,
    soft_delete,
  };
};

export const countClientsFilters = ({ is_active = true, trainer_id = null }) => {
  const filters = {
    is_zuda: true,
    is_active,
    soft_delete: trashed.without,
  };

  if (trainer_id) {
    filters.trainer_id = trainer_id;
  }

  return filters;
};

export const evaluationFilters = (filters) => {
  let user_id = '';
  let created_at = '';

  if (isValidFilter(filters, 'user_id')) {
    if (Array.isArray(filters.user_id)) {
      const ids = getIdsFromOptions(filters.user_id);
      user_id = `user_id: [${ids}]`;
    } else {
      user_id = `user_id: [${filters.user_id}]`;
    }
  }

  if (isValidFilter(filters, 'created_at', 'range')) {
    if (filters.created_at.to !== null) {
      created_at = `created_at_range: { from: "${filters.created_at.from}" , to: "${filters.created_at.to}" }`;
    } else {
      created_at = `created_at: "${filters.created_at.from}"`;
    }
  }

  const soft_delete = isValidFilter(filters, 'soft_delete') ? `trashed: ${filters.soft_delete}` : `trashed: ${trashed.without}`;

  return {
    user_id,
    created_at,
    soft_delete,
  };
};

export const planFilters = (filters) => {
  const soft_delete = isValidFilter(filters, 'soft_delete') ? `trashed: ${filters.soft_delete}` : `trashed: ${trashed.without}`;

  return {
    soft_delete,
  };
};

export const serviceFilters = (filters) => {
  const soft_delete = isValidFilter(filters, 'soft_delete') ? `trashed: ${filters.soft_delete}` : `trashed: ${trashed.without}`;

  return {
    soft_delete,
  };
};

export const paymentFilters = (filters) => {
  let userId = '';

  if (isValidFilter(filters, 'user_id')) {
    if (Array.isArray(filters.user_id)) {
      userId = getIdsFromOptions(filters.user_id);
    } else {
      userId = filters.user_id;
    }
  }

  const user_id = isValidFilter(filters, 'user_id') ? `user_id: [${userId}]` : '';
  const plan_id = isValidFilter(filters, 'plan_id') ? `plan_id: [${filters.plan_id}]` : '';
  const services_id = isValidFilter(filters, 'services_id') ? `services_id: [${filters.services_id}]` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete') ? `trashed: ${filters.soft_delete}` : `trashed: ${trashed.without}`;

  return {
    user_id,
    plan_id,
    services_id,
    soft_delete,
  };
};
