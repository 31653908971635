import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    borderRadius: (props) => props.borderRadius,
    position: 'absolute',
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.down(655)]: {
      borderRadius: () => 0,
    },
  },
}));

/**
 * DialogLoadingIndicator component
 *
 * @param {object} { open, borderRadius }
 * @return {jsx} react component
 */
function DialogLoadingIndicator({ open, borderRadius }) {
  const classes = useStyles({ borderRadius });

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress size={34} thickness={5} color="secondary" />
    </Backdrop>
  );
}

DialogLoadingIndicator.defaultProps = {
  borderRadius: '0',
};

DialogLoadingIndicator.propTypes = {
  open: PropTypes.bool.isRequired,
  borderRadius: PropTypes.string,
};

export default DialogLoadingIndicator;
