import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { roles } from 'common/constants';
import routes from 'common/routes/index';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';
import { getMyClientsData } from 'common/table/data';
import { getMyClientsColumns } from 'common/table/columns';
import { tableClickableRowsClasses } from 'common/muiSharedClasses';

const useStyles = makeStyles(tableClickableRowsClasses);

/**
 * MyClientsTable component
 *
 * @param {object} {
 *   userRole,
 *   data,
 *   isLoading,
 *   currentPage,
 *   setCurrentPage,
 *   itemsPerPage,
 *   setItemsPerPage,
 * }
 * @return {jsx} react component
 */
function MyClientsTable({
  userRole,
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (!data) {
    return (
      <Skeleton
        variant="rect"
        height={200}
        component="div"
        animation="wave"
        className={classes.skeleton}
      />
    );
  }

  const { data: { clients: { data: clientData, paginatorInfo: { total } } } } = data;
  const tableData = getMyClientsData(clientData);

  const columns = getMyClientsColumns(tableData, userRole === roles.trainer);

  const onCellClick = (colData, cellMeta) => {
    if (typeof colData !== 'function') {
      const { rowIndex } = cellMeta;
      const { id } = tableData[rowIndex];
      const path = routes.myClientEvaluations.path.replace(':id', id);

      history.push(path);
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    selectableRows: 'none',
    selectableRowsHeader: false,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: total,
    onCellClick,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    textLabels: tableLocalization('Clientes'),
  };

  return (
    <div className={classes.tableContainer}>
      <DialogLoadingIndicator open={isLoading && data} borderRadius="30px 5px" />
      <MUIDataTable
        title="Mis Clientes"
        columns={columns}
        data={tableData}
        options={options}
      />
    </div>
  );
}

MyClientsTable.propTypes = {
  userRole: PropTypes.string.isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
};

MyClientsTable.defaultProps = {
  data: undefined,
};

export default MyClientsTable;
