import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validateService } from 'common/form/validations';
import { resetForm, getFieldsToUpdate } from 'common/form/utils';
import { serviceInitialValues } from 'common/form/initialValues';
import getDataToSave from 'common/form/dataToSave';
import { formModalClasses } from 'common/muiSharedClasses';
import service from 'api/service';
import ServiceFields from './ServiceFields';

const useStyles = makeStyles(formModalClasses);

/**
 * EditServiceModal component
 *
 * @param {object} { open, onClose, selectedItem }
 * @return {jsx} react component
 */
function EditServiceModal({ open, onClose, selectedItem }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      const values = serviceInitialValues(selectedItem);
      setInitialValues(values);
    }
  }, [selectedItem]);

  const closeModal = () => {
    onClose(false);
  };

  const updateServiceMutation = useMutation((payload) => service.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-service-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Servicio editado con éxito', {
        key: 'update-service-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('services');
    },
  });

  const onSubmit = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const data = getDataToSave(fieldsToUpdate, 'service');
    updateServiceMutation.mutate({ id: selectedItem.id, input: data });
  };

  if (initialValues === null) {
    return false;
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateService}
      onSubmit={onSubmit}
      mutators={{
        resetForm,
      }}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="update-service-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={updateServiceMutation.isLoading} />
            <DialogTitle id="update-service-modal">Editar Servicio</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <ServiceFields classes={classes} />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={updateServiceMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={updateServiceMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || updateServiceMutation.isLoading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

EditServiceModal.defaultProps = {
  selectedItem: null,
};

EditServiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedItem: PropTypes.object,
};

export default EditServiceModal;
