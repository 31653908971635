import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import lightFormat from 'date-fns/lightFormat';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import usePaginationSettings from 'hooks/usePaginationSettings';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { defaultTableOptions, simpleTableLocalization, getDownloadButton } from 'common/table/utils';
import { clientProfileTableClasses } from 'common/muiSharedClasses';
import routes from 'common/routes';
import { trashed, dateFormat } from 'common/constants';
import { formatCurrency } from 'common/utils';
import payment from 'api/payment';

const useStyles = makeStyles((theme) => clientProfileTableClasses(theme, {
  borderRadius: '0px',
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
  boxShadow: 'none',
  minHeight: 250,
  mobileMinHeight: 'calc(100vh - 270.467px)',
}));

/**
 * ClientPayments component
 *
 * @param {object} { setTitle }
 * @return {jsx} react component
 */
function ClientPayments({ setTitle }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { authUser } = useContext(AuthUserContext);

  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();

  const [payments, setPayments] = useState({
    data: [],
    paginator: { currentPage: 1, hasMorePages: true, total: 0 },
  });

  const {
    isLoading,
    isFetching,
  } = useQuery(
    ['payments', {
      page: currentPage, first: itemsPerPage,
    }, {
      user_id: authUser.id, soft_delete: trashed.without,
    }],
    payment.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-payments-error',
          variant: 'error',
        });
      },
      onSuccess: (data) => {
        if (data && typeof data !== 'undefined') {
          setPayments({
            data: data.data.payments.data,
            paginator: data.data.payments.paginatorInfo,
          });
        }
      },
    },
  );

  const invoicePdfUrlMutation = useMutation((id) => payment.getDownloadPdfUrl(id), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'invoice-pdf-url-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { downloadInvoice: { url } } }) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    },
  });

  useEffect(() => {
    setTitle(routes.clientPayments.title);
  }, [setTitle]);

  const tableData = payments.data.map((item) => ({
    ...item,
    price: formatCurrency(item.price),
    range: `${lightFormat(new Date(item.start_date), dateFormat)} - ${lightFormat(new Date(item.end_date), dateFormat)}`,
    created_at: lightFormat(new Date(item.created_at), dateFormat),
  }));

  const onDownload = (rowIndex) => {
    const { id } = tableData[rowIndex];
    invoicePdfUrlMutation.mutate(id);
  };

  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: 'Pagado', name: 'created_at' },
    { label: 'Precio', name: 'price' },
    { label: 'Periodo facturado', name: 'range' },
    {
      name: 'Descargar',
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getDownloadButton({
          rowIndex,
          buttonClasses: classes,
          disabled: invoicePdfUrlMutation.isLoading,
          onClick: onDownload,
        }),
      },
    },
  ];

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    viewColumns: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: payments.paginator.total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    textLabels: simpleTableLocalization('Pagos'),
  };

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Typography className={classes.cardTitle}>{routes.clientPayments.title}</Typography>
      </Hidden>
      <div className={classes.card}>
        {(!isLoading && !isFetching) ? (
          <MUIDataTable
            columns={columns}
            data={tableData}
            options={options}
          />
        ) : (
          <DialogLoadingIndicator open borderRadius="5px 30px" />
        )}
      </div>
    </React.Fragment>
  );
}

ClientPayments.propTypes = {
  setTitle: PropTypes.func.isRequired,
};

export default ClientPayments;
