import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthUserContext from 'context/AuthUserContext';
import routes from 'common/routes/index';

const action = {
  ADMINISTRADOR: () => <Redirect to={routes.home.path} />,
  ENTRENADOR: () => <Redirect to={routes.myClients.path} />,
  NUTRICIONISTA: () => <Redirect to={routes.myClients.path} />,
  CLIENTE: () => <Redirect to={routes.clientProfile.path} />,
};

/**
 * Gate component
 *
 * @param {object} {
 *   component, Layout, allowedRoles, ...rest
 * }
 * @return {jsx} react component
 */
function Gate({
  component, Layout, allowedRoles, ...rest
}) {
  const { authUser } = useContext(AuthUserContext);

  if (allowedRoles.indexOf(authUser.role) >= 0) {
    return <Layout {...rest} component={component} />;
  }

  return action[authUser.role]();
}

Gate.propTypes = {
  component: PropTypes.func.isRequired,
  Layout: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gate;
