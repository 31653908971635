import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import _debounce from 'lodash/debounce';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/MultipleSelectFieldAdapter';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import useTrainerOptions from 'hooks/useTrainerOptions';
import useUserOptions from 'hooks/useUserOptions';
import usePlanOptions from 'hooks/usePlanOptions';
import { roles, debounceWait, isActiveOptions } from 'common/constants';
import { filtersClasses } from 'common/muiSharedClasses';

const useStyles = makeStyles(filtersClasses);

/**
 * MyClientsFilters component
 *
 * @param {object} {
 *   userId,
 *   userRole,
 *   filters,
 *   setFilters,
 *   setCurrentPage,
 *   isLoading,
 * }
 * @return {jsx} react component
 */
function MyClientsFilters({
  userId,
  userRole,
  filters,
  setFilters,
  setCurrentPage,
  isLoading,
}) {
  const trainerId = userRole === roles.trainer ? userId : null;
  const classes = useStyles();
  const { planOptions, isPlanLoading } = usePlanOptions();
  const { trainerOptions, isTrainerLoading } = useTrainerOptions();
  const {
    userOptions,
    isUserLoading,
    isUserFetching,
    setUserSearch,
  } = useUserOptions('default', trainerId);

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      setUserSearch(value);
    }
  };

  const onSubmit = (values) => {
    setCurrentPage(1);
    setFilters(values);
  };

  return (
    <Form
      initialValues={filters}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Accordion
            square
            defaultExpanded
            classes={{ root: classes.root }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={7}>
                  <Field
                    id="user_id"
                    name="user_id"
                    component={AutocompleteFieldAdapter}
                    options={userOptions}
                    isMultiple
                    filterSelectedOptions
                    limitTags={1}
                    loading={isUserLoading || isUserFetching}
                    onInputChange={_debounce(onUserIdChange, debounceWait)}
                    textProps={{
                      label: 'Clientes',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Field
                    id="is_active"
                    name="is_active"
                    component={SelectFieldAdapter}
                    options={isActiveOptions}
                    label="Activo"
                    fullWidth
                  />
                </Grid>
                {userRole === roles.nutritionist && (
                  <Grid item xs={12} sm={5} md={3}>
                    <Field
                      id="trainer_id"
                      name="trainer_id"
                      component={MultipleSelectFieldAdapter}
                      options={trainerOptions}
                      label="Entrenador"
                      disabled={isTrainerLoading}
                      fullWidth
                    />
                  </Grid>
                )}
                {userRole === roles.trainer && (
                  <Grid item xs={12} sm={5} md={3}>
                    <Field
                      id="plan_id"
                      name="plan_id"
                      component={MultipleSelectFieldAdapter}
                      options={planOptions}
                      label="Plan"
                      disabled={isPlanLoading}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
            <AccordionActions classes={{ root: classes.actions }}>
              <Button
                className={classes.button}
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                endIcon={isLoading ? <CircularProgress size={14} thickness={5} color="secondary" /> : <FilterListIcon />}
                disabled={pristine || isLoading}
              >
                Filtrar
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      )}
    />
  );
}

MyClientsFilters.propTypes = {
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MyClientsFilters;
