import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

/**
 * PublicRoute component
 *
 * @param {object} { component, layout: Layout, ...rest }
 * @return {jsx} react component
 */
function PublicRoute({ component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => <Layout {...routeProps} component={component} />}
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
};

export default PublicRoute;
