import React from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { countClientsFilters } from 'common/filters';
import { formatCurrency, toFixed } from 'common/utils';
import { timePeriod } from 'common/constants';
import user from 'api/user';
import payment from 'api/payment';

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: 'center',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  cardContent: {
    textAlign: 'center',
  },
  clientNumber: {
    fontSize: theme.typography.pxToRem(35),
    fontWeight: theme.typography.fontWeightBold,
  },
  clientLabel: {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.typography.pxToRem(1),
    textTransform: 'uppercase',
  },
  clientNote: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(1),
  },
  loading: {
    color: theme.palette.primary.dark,
  },
}));

/**
 * ClientCount component
 *
 * @param {object} {
 *   trainer_id
 * }
 * @return {jsx} react component
 */
function ClientCount({ trainer_id }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const activeFilters = trainer_id ? { trainer_id } : {};
  const inactiveFilters = trainer_id ? { is_active: false, trainer_id } : { is_active: false };
  const totalIncomeParams = trainer_id ? { trainer_id, time_period: timePeriod.thisMonth } : { time_period: timePeriod.thisMonth };

  const {
    data: activeCount,
    isLoading: activeLoading,
  } = useQuery(
    ['countActiveClients', countClientsFilters(activeFilters)],
    user.countClients,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'count-active-clients-error',
          variant: 'error',
        });
      },
    },
  );

  const {
    data: inactiveCount,
    isLoading: inactiveLoading,
  } = useQuery(
    ['countInactiveClients', countClientsFilters(inactiveFilters)],
    user.countClients,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'count-inactive-clients-error',
          variant: 'error',
        });
      },
    },
  );

  const {
    data: totalIncome,
    isLoading: totalIncomeLoading,
  } = useQuery(
    ['getTotalIncome', totalIncomeParams],
    payment.getTotalInvoice,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'total-income-error',
          variant: 'error',
        });
      },
    },
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardContent }}>
            {activeLoading ? (
              <CircularProgress classes={{ root: classes.loading }} size={20} thickness={5} color="secondary" />
            ) : (
              <Typography className={classes.clientNumber} align="center">
                {activeCount?.data?.countClients || 0}
              </Typography>
            )}
            <Typography className={classes.clientLabel} align="center">Clientes</Typography>
            <Typography className={classes.clientLabel} align="center">Activos</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={3}>
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardContent }}>
            {inactiveLoading ? (
              <CircularProgress classes={{ root: classes.loading }} size={20} thickness={5} />
            ) : (
              <Typography className={classes.clientNumber} align="center">
                {inactiveCount?.data?.countClients || 0}
              </Typography>
            )}
            <Typography className={classes.clientLabel} align="center">Clientes</Typography>
            <Typography className={classes.clientLabel} align="center">Inactivos</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={3}>
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardContent }}>
            {(inactiveLoading || activeLoading) ? (
              <CircularProgress classes={{ root: classes.loading }} size={20} thickness={5} />
            ) : (
              <Typography className={classes.clientNumber} align="center">
                { toFixed(((activeCount.data.countClients / (activeCount.data.countClients + inactiveCount.data.countClients)) * 100), 0) }
                %
              </Typography>
            )}
            <Typography className={classes.clientLabel} align="center">Tasa de</Typography>
            <Typography className={classes.clientLabel} align="center">Retención</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} md={3}>
        <Card classes={{ root: classes.card }}>
          <CardContent classes={{ root: classes.cardContent }}>
            {totalIncomeLoading ? (
              <CircularProgress classes={{ root: classes.loading }} size={20} thickness={5} />
            ) : (
              <Typography className={classes.clientNumber} align="center">
                {totalIncome?.data?.totalIncome[0]?.humanizedValue || 0}
              </Typography>
            )}
            <Typography className={classes.clientNote} align="center">{formatCurrency(totalIncome?.data?.totalIncome[0]?.value || 0)}</Typography>
            <Typography className={classes.clientLabel} align="center">Ingresos</Typography>
            <Typography className={classes.clientLabel} align="center">Mensuales</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

ClientCount.propTypes = {
  trainer_id: PropTypes.number,
};

ClientCount.defaultProps = {
  trainer_id: null,
};

export default ClientCount;
