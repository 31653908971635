import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import SectionTitle from 'components/layout/SectionTitle';
import AddButton from 'components/layout/AddButton';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import routes from 'common/routes/index';
import { defaultPlanFilters } from 'common/filters';
import plan from 'api/plan';
import PlanTable from './PlanTable';
import PlanFilters from './PlanFilters';
import CreatePlanModal from './CreatePlanModal';
import EditPlanModal from './EditPlanModal';

/**
 * Plan component
 *
 * @return {jsx} react component
 */
function Plan() {
  const { enqueueSnackbar } = useSnackbar();
  const [planFilters, setPlanFilters] = useLocalStorage('zudaPlanFilter', defaultPlanFilters);
  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['plans', { page: currentPage, first: itemsPerPage }, planFilters],
    plan.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-plans-error',
          variant: 'error',
        });
      },
    },
  );

  const toggleCreateModal = () => {
    setCreateModal(true);
  };

  return (
    <React.Fragment>
      <SectionTitle text={routes.plans.title}>
        <AddButton handleClick={toggleCreateModal} />
      </SectionTitle>
      <PlanFilters
        filters={planFilters}
        setFilters={setPlanFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <PlanTable
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
      />
      <CreatePlanModal
        open={createModal}
        onClose={setCreateModal}
      />
      <EditPlanModal
        open={editModal}
        onClose={setEditModal}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  );
}

export default Plan;
