import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { roles } from 'common/constants';
import user from 'api/user';

const useUserOptions = (type, trainerId) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userSearch, setUserSearch] = useState(null);
  let userOptions = [];

  const method = {
    evaluation: 'getEvaluationSearchOptions',
    trainer: 'getSearchOptions',
    default: 'getSearchOptions',
  };

  const userRole = {
    evaluation: roles.client,
    trainer: roles.trainer,
    default: roles.client,
  };

  let filters = { search: userSearch, role: userRole[type] };

  if (trainerId) {
    filters = { ...filters, trainer_id: Number(trainerId) };
  }

  const getFormatedData = (userData, dataType) => {
    const isEvaluation = (userOption) => ({
      label: userOption.full_name,
      value: userOption.id,
      gender: userOption.gender,
      height: userOption.height,
      birthdate: userOption.birthdate,
    });

    const isDefault = (userOption) => ({
      label: userOption.full_name,
      value: userOption.id,
    });

    const formatedData = {
      evaluation: isEvaluation,
      trainer: isDefault,
      default: isDefault,
    };

    return formatedData[dataType](userData);
  };

  const {
    data,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    isSuccess: isUserSuccess,
    isError: isUserError,
    error: userError,
    refetch,
  } = useQuery(
    ['userOptions', filters],
    user[method[type]],
    {
      enabled: false,
    },
  );

  if (isUserError) {
    enqueueSnackbar(userError.message, {
      key: 'options-user-error',
      variant: 'error',
    });
  }

  if (isUserSuccess && data && typeof data !== 'undefined') {
    const { data: { searchUser: response } } = data;
    userOptions = response.map((userOption) => getFormatedData(userOption, type));
  }

  useEffect(() => {
    if (userSearch && userSearch.length > 0) refetch();
  }, [refetch, userSearch]);

  return {
    userOptions,
    isUserLoading,
    isUserFetching,
    setUserSearch,
  };
};

export default useUserOptions;
