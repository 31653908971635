import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import lightFormat from 'date-fns/lightFormat';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { defaultTableOptions, simpleTableLocalization, getViewButton } from 'common/table/utils';
import routes from 'common/routes/index';
import { dateFormat } from 'common/constants';
import { clientProfileTableClasses } from 'common/muiSharedClasses';

const useStyles = makeStyles((theme) => clientProfileTableClasses(theme, {
  borderRadius: '0px',
  padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  boxShadow: 'none',
}));

/**
 * EvaluationsCard component
 *
 * @param {object} { evaluations }
 * @return {jsx} react component
 */
function EvaluationsCard({ evaluations }) {
  const classes = useStyles();
  const history = useHistory();

  const onCellClick = (colData, cellMeta) => {
    if (typeof colData !== 'function') {
      const { rowIndex } = cellMeta;
      const { id } = evaluations[rowIndex];
      const path = routes.clientEvaluation.path.replace(':id', id);

      history.push(path);
    }
  };

  const tableData = evaluations.map((evaluation, index) => ({
    ...evaluation,
    index: index + 1,
    created_at: lightFormat(new Date(evaluation.created_at), dateFormat),
  }));

  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: 'Numero', name: 'index' },
    { label: 'Ingresada', name: 'created_at' },
    {
      name: 'Ver',
      options: {
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getViewButton({
          rowIndex,
          buttonClasses: classes,
          onClick: onCellClick,
        }),
      },
    },
  ];

  const options = {
    ...defaultTableOptions,
    viewColumns: false,
    pagination: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    jumpToPage: false,
    textLabels: simpleTableLocalization('Valoraciones'),
    onCellClick,
  };

  return (
    <article className={classes.card}>
      {tableData.length > 0 ? (
        <React.Fragment>
          <MUIDataTable
            title="Ultimas valoraciones"
            columns={columns}
            data={tableData}
            options={options}
          />
          <div className={classes.seeMoreContainer}>
            <Button
              className={classes.seeMore}
              variant="contained"
              color="secondary"
              endIcon={<ArrowForwardIcon />}
              component={RouterLink}
              to={routes.clientEvaluations.path}
            >
              Ver mas
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.emptyTableMessage}>No tienes valoraciones registradas</div>
      )}
    </article>
  );
}

EvaluationsCard.propTypes = {
  evaluations: PropTypes.array.isRequired,
};

export default EvaluationsCard;
