import LoginLayout from 'components/layout/LoginLayout';
import DefaultLayout from 'components/layout/DefaultLayout';
import MyClientsLayout from 'components/layout/MyClientsLayout';
import ClientProfileLayout from 'components/layout/ClientProfileLayout';
import NotFoundLayout from 'components/layout/NotFoundLayout';
import { roles } from 'common/constants';
import routes from 'common/routes/index';

// Public components
import Login from 'components/login';
import NotFound from 'components/not-found';

// Private components
import VerifyAccount from 'components/auth/VerifyAccount';
import ClientProfile from 'components/client-profile';
import ClientInformation from 'components/client-profile/information';
import ClientEvaluations from 'components/client-profile/evaluations';
import ClientEvaluation from 'components/client-profile/evaluations/Evaluation';
import ClientPayments from 'components/client-profile/Payments';
import MyClients from 'components/my-clients';
import MyClientEvaluations from 'components/my-clients/MyClientEvaluations';
import StaffProfile from 'components/my-clients/staff-profile';
import Dashboard from 'components/dashboard';
import Client from 'components/client';
import Trainer from 'components/trainer';
import Evaluation from 'components/evaluation';
import EvaluationDetail from 'components/evaluation/EvaluationDetail';
import Plan from 'components/plan';
import Service from 'components/service';
import Payment from 'components/payment';

export const publicRoutes = [
  {
    key: 'verify-account',
    exact: true,
    path: routes.verifyAccount.path,
    component: VerifyAccount,
    layout: NotFoundLayout,
  },
  {
    key: 'root',
    exact: true,
    path: routes.login.path,
    component: Login,
    layout: LoginLayout,
  },
  {
    key: 'not-found',
    path: '*',
    component: NotFound,
    layout: NotFoundLayout,
  },
];

export const privateRoutes = [
  {
    key: 'client-profile',
    exact: true,
    path: routes.clientProfile.path,
    component: ClientProfile,
    layout: ClientProfileLayout,
    allowedRoles: [roles.client],
  },
  {
    key: 'client-information',
    exact: true,
    path: routes.clientInformation.path,
    component: ClientInformation,
    layout: ClientProfileLayout,
    allowedRoles: [roles.client],
  },
  {
    key: 'client-evaluations',
    exact: true,
    path: routes.clientEvaluations.path,
    component: ClientEvaluations,
    layout: ClientProfileLayout,
    allowedRoles: [roles.client],
  },
  {
    key: 'client-evaluation-detail',
    exact: true,
    path: routes.clientEvaluation.path,
    component: ClientEvaluation,
    layout: ClientProfileLayout,
    allowedRoles: [roles.client],
  },
  {
    key: 'client-payments',
    exact: true,
    path: routes.clientPayments.path,
    component: ClientPayments,
    layout: ClientProfileLayout,
    allowedRoles: [roles.client],
  },
  {
    key: 'my-clients',
    exact: true,
    path: routes.myClients.path,
    component: MyClients,
    layout: MyClientsLayout,
    allowedRoles: [roles.trainer, roles.nutritionist],
  },
  {
    key: 'my-client-evaluations',
    exact: true,
    path: routes.myClientEvaluations.path,
    component: MyClientEvaluations,
    layout: MyClientsLayout,
    allowedRoles: [roles.trainer, roles.nutritionist],
  },
  {
    key: 'my-client-evaluation-detail',
    exact: true,
    path: routes.myClientEvaluationDetail.path,
    component: EvaluationDetail,
    layout: MyClientsLayout,
    allowedRoles: [roles.trainer, roles.nutritionist],
  },
  {
    key: 'trainer-profile',
    exact: true,
    path: routes.trainerProfile.path,
    component: StaffProfile,
    layout: MyClientsLayout,
    allowedRoles: [roles.trainer],
  },
  {
    key: 'nutritionist-profile',
    exact: true,
    path: routes.nutritionistProfile.path,
    component: StaffProfile,
    layout: MyClientsLayout,
    allowedRoles: [roles.nutritionist],
  },
  {
    key: 'home',
    exact: true,
    path: routes.home.path,
    component: Dashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'client',
    exact: true,
    path: routes.clients.path,
    component: Client,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'trainer',
    exact: true,
    path: routes.trainers.path,
    component: Trainer,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'evaluations',
    exact: true,
    path: routes.evaluations.path,
    component: Evaluation,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'evaluation',
    exact: true,
    path: routes.evaluation.path,
    component: EvaluationDetail,
    layout: DefaultLayout,
    allowedRoles: [roles.admin, roles.trainer, roles.nutritionist],
  },
  {
    key: 'plan',
    exact: true,
    path: routes.plans.path,
    component: Plan,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'service',
    exact: true,
    path: routes.services.path,
    component: Service,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
  {
    key: 'payment',
    exact: true,
    path: routes.payments.path,
    component: Payment,
    layout: DefaultLayout,
    allowedRoles: [roles.admin],
  },
];
