const authRoutes = {
  verifyAccount: {
    path: '/verificar-cuenta',
    title: 'Verifica Tu Cuenta',
  },
  login: {
    path: '/ingresar',
    title: 'Ingresar',
  },
};

export default authRoutes;
