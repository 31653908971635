import { customAction, daysLeftColumn } from 'common/table/utils';

export const getClientColumns = (tableData, editFunc, notificationFunc) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Nombres', name: 'first_name' },
  { label: 'Apellidos', name: 'last_name' },
  { label: 'Fecha de corte', name: 'payment_end_date' },
  { label: 'Plan', name: 'plan' },
  { label: 'Entrenador', name: 'trainer' },
  daysLeftColumn(tableData),
  editFunc ? customAction('edit', tableData, editFunc) : '',
  notificationFunc ? customAction('notification', tableData, notificationFunc) : '',
];

export const getMyClientsColumns = (tableData, isTrainer = true) => {
  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: 'Nombres', name: 'first_name' },
    { label: 'Apellidos', name: 'last_name' },
    { label: 'Fecha de corte', name: 'payment_end_date' },
    { label: 'Valoraciones', name: 'total_evaluations' },
  ];

  if (isTrainer) {
    columns.push({ label: 'Plan', name: 'plan' });
    columns.push({ label: 'Número de clases', name: 'num_classes' });
    columns.push(daysLeftColumn(tableData));
  } else {
    columns.push({ label: 'Entrenador', name: 'trainer' });
  }

  return columns;
};

export const getTrainerColumns = (tableData, editFunc) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Nombres', name: 'first_name' },
  { label: 'Apellidos', name: 'last_name' },
  { label: 'Fecha de ingreso', name: 'joining_date' },
  { label: 'Clientes', name: 'total_clients' },
  customAction('edit', tableData, editFunc),
];

export const getPlanColumns = (tableData, editFunc) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Nombre', name: 'name' },
  { label: 'Precio', name: 'price' },
  { label: 'Clientes', name: 'clients' },
  customAction('edit', tableData, editFunc),
];

export const getServiceColumns = (tableData, editFunc) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Nombre', name: 'name' },
  { label: 'Precio', name: 'price' },
  { label: 'Precio Afiliado', name: 'zuda_price' },
  { label: 'Clientes', name: 'clients' },
  customAction('edit', tableData, editFunc),
];

export const getEvaluationColumns = (tableData, editFunc = null) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Cliente', name: 'user' },
  { label: 'Fecha de creación', name: 'created_at' },
  editFunc ? customAction('edit', tableData, editFunc) : '',
];

export const getPaymentColumns = (tableData, editFunc = null) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: 'Cliente', name: 'user' },
  { label: 'Monto', name: 'price' },
  { label: 'Fecha de creación', name: 'created_at' },
  { label: 'Periodo facturado', name: 'time_frame' },
  editFunc ? customAction('edit', tableData, editFunc) : '',
];
