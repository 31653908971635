import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { Form, Field } from 'react-final-form';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { validateUpdatePassword } from 'common/form/validations';
import user from 'api/user';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 311,
    padding: theme.spacing(4),
    backgroundColor: (props) => (
      props.altColors ? theme.palette.primary.dark : theme.palette.background.paper
    ),
  },
  dialogRoot: {
    borderRadius: 5,
  },
  title: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  button: {
    width: 160,
    borderRadius: theme.spacing(2),
  },
}));

/**
 * SetPasswordModal component
 *
 * @param {object} { authUser, setAuthUser, altColors }
 * @return {jsx} react component
 */
function SetPasswordModal({ authUser, setAuthUser, altColors }) {
  const [isOpen, setIsOpen] = useState(!authUser.password_set);
  const classes = useStyles({ altColors });
  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setIsOpen(false);
  };

  const updateUserMutation = useMutation((payload) => user.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-client-profile-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      onClose();
      setAuthUser({ ...authUser, password_set: true });

      enqueueSnackbar('Contraseña establecida con éxito', {
        key: 'update-client-profile-success',
        variant: 'success',
      });
    },
  });

  const onSubmit = (values) => {
    updateUserMutation.mutate({
      id: authUser.id,
      input: { password: values.password, password_set: true },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="set-password-modal"
      open={isOpen}
      maxWidth
      classes={{
        root: classes.dialogRoot,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className={classes.container}>
        <Typography className={classes.title}>Establece tu contraseña</Typography>
        <Form
          validate={validateUpdatePassword}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.field}>
                <Field
                  id="password"
                  name="password"
                  component={PasswordFieldAdapter}
                  label="Contraseña"
                  autoComplete="new-password"
                  disabled={updateUserMutation.isLoading}
                  required
                  fullWidth
                />
              </div>
              <div>
                <Field
                  id="confirm"
                  name="confirm"
                  component={PasswordFieldAdapter}
                  label="Confirmar Contraseña"
                  autoComplete="new-password"
                  disabled={updateUserMutation.isLoading}
                  required
                  fullWidth
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  color={altColors ? 'secondary' : 'primary'}
                  disabled={invalid || pristine || updateUserMutation.isLoading}
                >
                  {updateUserMutation.isLoading ? (
                    <CircularProgress size={24.5} thickness={5} color="secondary" />
                  ) : (
                    <span>Establecer</span>
                  )}
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Dialog>
  );
}

SetPasswordModal.defaultProps = {
  altColors: false,
};

SetPasswordModal.propTypes = {
  authUser: PropTypes.object.isRequired,
  setAuthUser: PropTypes.func.isRequired,
  altColors: PropTypes.bool,
};

export default SetPasswordModal;
