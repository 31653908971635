import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field } from 'react-final-form';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import FloatFieldAdapter from 'components/shared/FloatFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/MultipleSelectFieldAdapter';
import usePlanOptions from 'hooks/usePlanOptions';
import useTrainerOptions from 'hooks/useTrainerOptions';
import useServiceOptions from 'hooks/useServiceOptions';
import { genderOptions, numClassesOptions } from 'common/constants';

/**
 * ClientFields component
 *
 * @param {object} {
 *   form,
 *   classes,
 *   requiredFields,
 *   disableFuture,
 * }
 * @return {jsx} react component
 */
function ClientFields({
  form,
  classes,
  requiredFields,
  disableFuture,
}) {
  const { planOptions, isPlanLoading } = usePlanOptions();
  const { trainerOptions, isTrainerLoading } = useTrainerOptions();
  const { serviceOptions, isServiceLoading } = useServiceOptions();

  const isRequired = (field) => requiredFields.includes(field);

  const isFutureDisabled = (field) => disableFuture.includes(field);

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={4}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label="Nombres"
            required={isRequired('first_name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label="Apellidos"
            required={isRequired('last_name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label="Email"
            type="email"
            required={isRequired('email')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label="Contraseña"
            shuffle
            onShuffle={form.mutators.generatePassword}
            autoComplete="new-password"
            required={isRequired('password')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="phone"
            name="phone"
            component={TextFieldAdapter}
            label="Teléfono"
            type="tel"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={genderOptions}
            label="Sexo"
            required={isRequired('gender')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label="Fecha de nacimiento"
            disableFuture={isFutureDisabled('birthdate')}
            openTo="year"
            views={['year', 'month', 'date']}
            required={isRequired('birthdate')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="height"
            name="height"
            component={FloatFieldAdapter}
            label="Altura"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required={isRequired('height')}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={4}>
          <Field
            id="joining_date"
            name="joining_date"
            component={DatePickerFieldAdapter}
            label="Fecha de inscripción"
            disableFuture={isFutureDisabled('joining_date')}
            openTo="year"
            views={['year', 'month', 'date']}
            required={isRequired('joining_date')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="plan_id"
            name="plan_id"
            component={SelectFieldAdapter}
            options={planOptions}
            label="Personalizado"
            disabled={isPlanLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="num_classes"
            name="num_classes"
            component={SelectFieldAdapter}
            options={numClassesOptions}
            label="Número de clases"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="trainer_id"
            name="trainer_id"
            component={SelectFieldAdapter}
            options={trainerOptions}
            label="Entrenador"
            disabled={isTrainerLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="services_id"
            name="services_id"
            component={MultipleSelectFieldAdapter}
            options={serviceOptions}
            label="Servicios"
            disabled={isServiceLoading}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={6}>
          <Field
            id="eps"
            name="eps"
            component={TextFieldAdapter}
            label="EPS"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            id="note"
            name="note"
            component={TextFieldAdapter}
            label="Observaciones"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ClientFields.defaultProps = {
  requiredFields: [],
  disableFuture: [],
};

ClientFields.propTypes = {
  form: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disableFuture: PropTypes.arrayOf(PropTypes.string),
};

export default ClientFields;
