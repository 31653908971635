import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { ReactComponent as Logo } from 'images/logo-white.svg';
import AuthUserContext from 'context/AuthUserContext';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import { validateLogin } from 'common/form/validations';
import { saveAuthInfo, redirectOnLogin } from 'common/utils';
import auth from 'api/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 200,
    '& .cls-1': {
      fill: theme.palette.common.white,
    },
  },
  form: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
  },
  gridContainer: {
    marginTop: theme.spacing(3),
  },
  email: {
    marginBottom: 15,
  },
  buttonContainer: {
    marginTop: 25,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    marginTop: -12,
  },
}));

const initialValues = {
  email: '',
  password: '',
};

/**
 * Login component
 *
 * @return {jsx} react component
 */
function Login() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  const loginMutation = useMutation((formData) => auth.login(formData), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'login-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { login } }) => {
      saveAuthInfo(login);
      setAuthUser(login.user);
    },
  });

  const onSubmit = (values) => {
    loginMutation.mutate(values);
  };

  if (authUser && Object.keys(authUser).length > 0) {
    return redirectOnLogin(authUser);
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Grid item>
        <Logo
          className={classes.logo}
          title="Zuda Team"
        />
      </Grid>
      <Grid item className={classes.form}>
        <Form
          initialValues={initialValues}
          validate={validateLogin}
          onSubmit={onSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" className={classes.gridContainer}>
                <Grid item xs={12} className={classes.email}>
                  <Field
                    id="login-email"
                    name="username"
                    component={TextFieldAdapter}
                    label="Email"
                    type="text"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    id="login-password"
                    name="password"
                    component={PasswordFieldAdapter}
                    label="Contraseña"
                    autoComplete="current-password"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || loginMutation.isLoading}
                    fullWidth
                  >
                    Ingresar
                  </Button>
                  {loginMutation.isLoading && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      color="secondary"
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Login;
