import { gql } from '@apollo/client';
import client from 'apollo/client';
import { planFilters } from 'common/filters';

/**
 * List plans method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = planFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query plans {
        plans(
          first: ${first}
          page: ${page}
          ${filters.soft_delete}
          orderBy: {
            column: NAME,
            order: ASC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            name
            price
            clients
            deleted_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Get all plans method
 * @returns {Promise}
 */
const getAll = () => client.query({
  query: gql`
    query allPlans {
      allPlans {
        id
        name
      }
    }
  `,
  fetchPolicy: 'network-only',
});

/**
 * Create plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createPlan($input: CreatePlanInput!) {
      createPlan(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Update plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) => client.mutate({
  mutation: gql`
    mutation updatePlan($id: ID!, $input: UpdatePlanInput!) {
      updatePlan(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

/**
 * Remove plans method
 * @param  {String} ids
 * @return {Promise}
 */
const remove = (ids) => client.mutate({
  mutation: gql`
    mutation deletePlans($id: [ID!]!) {
      deletePlans(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Restore plans method
 * @param  {String} ids
 * @return {Promise}
 */
const restore = (ids) => client.mutate({
  mutation: gql`
    mutation restorePlans($id: [ID!]!) {
      restorePlans(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

export default {
  list,
  getAll,
  create,
  update,
  remove,
  restore,
};
