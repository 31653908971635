import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';
import localforage from 'localforage';
import errorHandler from './errorHandler';

const { REACT_APP_GRAPHQL_SERVER_BASE_URL } = process.env;

const getAuthToken = () => localforage.getItem('zuda_access_token')
  .then((value) => `Bearer ${value}`)
  .then((error) => error);

const authLink = setContext(async (operation, { headers }) => {
  const token = await getAuthToken();

  if (token) {
    return {
      headers: { ...headers, Authorization: token },
    };
  }

  return { headers };
});

const uploadLink = createUploadLink({ uri: `${REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql` || '' });
const errorLink = onError(errorHandler);

const link = ApolloLink.from([authLink, errorLink, uploadLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
