import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthUserContext from 'context/AuthUserContext';
import routes from 'common/routes/index';
import Gate from './Gate';

/**
 * Authenticated component
 *
 * @param {object} props
 * @return {jsx} react component
 */
function Authenticated(props) {
  const { layout, component, ...rest } = props;
  const { authUser } = useContext(AuthUserContext);

  if (authUser && Object.keys(authUser).length > 0) {
    return (
      <Gate {...rest} component={component} Layout={layout} />
    );
  }

  return <Redirect to={routes.login.path} />;
}

Authenticated.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
};

export default Authenticated;
