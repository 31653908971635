import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import SetPasswordModal from 'components/shared/SetPasswordModal';
import routes from 'common/routes/index';
import BasicInfoCard from './BasicInfoCard';
import PieChartCard from './PieChartCard';
import LineChartCard from './LineChartCard';
import EvaluationsCard from './EvaluationsCard';
import PaymentsCard from './PaymentsCard';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    [theme.breakpoints.down(655)]: {
      flexDirection: 'column',
    },
  },
  leftContainer: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      marginRight: 0,
    },
  },
  rightContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      marginLeft: 0,
    },
  },
  cardsContainer: {
    display: 'flex',
    [theme.breakpoints.down(1070)]: {
      flexDirection: 'column',
    },
  },
  card: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: `calc(50% - ${theme.spacing(2)}px)`,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(1070)]: {
      flexBasis: '100%',
      width: '100%',
    },
  },
  leftCard: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(1070)]: {
      marginRight: 0,
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down(655)]: {
      marginBottom: 0,
    },
  },
  rightCard: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(1070)]: {
      marginLeft: 0,
    },
  },
}));

/**
 * ClientProfile component
 *
 * @param {object} { setTitle }
 * @return {jsx} react component
 */
function ClientProfile({ setTitle }) {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const evaluations = _get(authUser, 'evaluations.data', []);
  const payments = _get(authUser, 'payments.data', []);

  useEffect(() => {
    setTitle(routes.clientProfile.title);
  }, [setTitle]);

  return (
    <div className={classes.mainContainer}>

      <section className={classes.leftContainer}>
        <BasicInfoCard user={authUser} />
        <PieChartCard evaluations={evaluations} />
      </section>

      <section className={classes.rightContainer}>
        <LineChartCard userId={authUser.id} />

        <div className={classes.cardsContainer}>
          <div className={clsx(classes.card, classes.leftCard)}>
            <EvaluationsCard evaluations={evaluations} />
          </div>

          <div className={clsx(classes.card, classes.rightCard)}>
            <PaymentsCard payments={payments} />
          </div>
        </div>
      </section>

      {!authUser.password_set && (
        <SetPasswordModal authUser={authUser} setAuthUser={setAuthUser} altColors />
      )}
    </div>
  );
}

ClientProfile.propTypes = {
  setTitle: PropTypes.func.isRequired,
};

export default ClientProfile;
