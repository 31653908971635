import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { defaultTableOptions, tableLocalization, getCustomSelectToolBar } from 'common/table/utils';
import { getEvaluationData } from 'common/table/data';
import { getEvaluationColumns } from 'common/table/columns';
import { tableClickableRowsClasses } from 'common/muiSharedClasses';
import routes from 'common/routes/index';
import evaluation from 'api/evaluation';

const useStyles = makeStyles(tableClickableRowsClasses);

/**
 * EvaluationTable component
 *
 * @param {object} {
 *   data,
 *   isLoading,
 *   currentPage,
 *   setCurrentPage,
 *   itemsPerPage,
 *   setItemsPerPage,
 *   setSelectedItem,
 *   setEditModal,
 * }
 * @return {jsx} react component
 */
function EvaluationTable({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  setSelectedItem,
  setEditModal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteEvaluationMutation = useMutation((payload) => evaluation.remove(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'delete-evaluation-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { deleteEvaluations } } = response;
      const message = `${deleteEvaluations.length > 1 ? 'Valoraciones eliminadas' : 'Valoración eliminada'} con éxito`;

      enqueueSnackbar(message, {
        key: 'delete-evaluation-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('evaluations');
    },
  });

  const restoreEvaluationMutation = useMutation((payload) => evaluation.restore(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'restore-evaluation-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { restoreEvaluations } } = response;
      const message = `${restoreEvaluations.length > 1 ? 'Valoraciones restauradas' : 'Valoración restaurada'} con éxito`;

      enqueueSnackbar(message, {
        key: 'restore-evaluation-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('evaluations');
    },
  });

  if (!data) {
    return (
      <Skeleton
        variant="rect"
        height={200}
        component="div"
        animation="wave"
      />
    );
  }

  const { data: { evaluations: { data: evaluationData, paginatorInfo: { total } } } } = data;
  const tableData = getEvaluationData(evaluationData);

  const openEditModal = (row) => {
    setSelectedItem(row);
    setEditModal(true);
  };

  const columns = getEvaluationColumns(tableData, openEditModal);

  const onCellClick = (colData, cellMeta) => {
    if (typeof colData !== 'function') {
      const { rowIndex } = cellMeta;
      const { id } = tableData[rowIndex];
      const path = routes.evaluation.path.replace(':id', id);

      history.push(path);
    }
  };

  const onDelete = (ids) => {
    deleteEvaluationMutation.mutate(ids);
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRestore = (ids) => {
    restoreEvaluationMutation.mutate(ids);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    onCellClick,
    textLabels: tableLocalization('Valoraciones'),
    customToolbarSelect: (selectedRows, displayData) => getCustomSelectToolBar({
      id: 'zudaEvaluationFilter',
      rows: selectedRows,
      data: displayData,
      onDelete,
      onRestore,
    }),
  };

  return (
    <div className={classes.tableContainer}>
      <DialogLoadingIndicator open={isLoading && data} borderRadius="30px 5px" />
      <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
      />
    </div>
  );
}

EvaluationTable.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setEditModal: PropTypes.func.isRequired,
};

EvaluationTable.defaultProps = {
  data: undefined,
};

export default EvaluationTable;
