/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: (size) => theme.spacing(size.default),
    height: (size) => theme.spacing(size.default),
    [theme.breakpoints.down('xs')]: {
      width: (size) => theme.spacing(size.xs),
      height: (size) => theme.spacing(size.xs),
    },
  },
}));

/**
 * ProfileAvatar component
 *
 * @param {object} { user, size }
 * @return {jsx} react component
 */
function ProfileAvatar({ user, size }) {
  const classes = useStyles(size);

  return (
    <React.Fragment>
      {user.photo ? (
        <Avatar
          src={user.photo}
          alt={user.full_name}
          title={user.full_name}
          className={classes.avatar}
        />
      ) : (
        <Avatar
          alt={user.full_name}
          className={classes.avatar}
        >
          {user.first_name.charAt(0)}
          {user.last_name.charAt(0)}
        </Avatar>
      )}
    </React.Fragment>
  );
}

ProfileAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
};

export default ProfileAvatar;
