export const filtersClasses = (theme) => ({
  root: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    marginBottom: `${theme.spacing(4)}px !important`,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      marginBottom: '0 !important',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  actions: {
    paddingBottom: 20,
  },
  button: {
    borderRadius: theme.spacing(2),
  },
});

export const tableClasses = {
  tableContainer: {
    position: 'relative',
  },
};

export const tableClickableRowsClasses = {
  tableContainer: {
    position: 'relative',
    '& .MuiTableRow-root': {
      cursor: 'pointer',
    },
  },
};

export const formModalClasses = (theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.dark,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  gridMargin: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  calculateContainer: {
    marginBottom: theme.spacing(3),
    textAlign: 'end',
  },
  commentsContainer: {
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  resetContainer: {
    paddingLeft: theme.spacing(2),
  },
  roundedButton: {
    borderRadius: theme.spacing(2),
  },
  cancelButton: {
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

export const clientProfileTableClasses = (theme, options) => ({
  cardTitle: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(655)]: {
      backgroundColor: theme.palette.primary.dark,
      fontSize: theme.typography.pxToRem(18),
      padding: theme.spacing(2),
    },
  },
  card: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
    minHeight: options?.minHeight || 'auto',
    position: 'relative',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2],
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiToolbar-root': {
      paddingLeft: theme.spacing(2),
    },
    '& .MuiTableCell-head': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
    '& .MuiTableCell-root': {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      padding: `5px ${theme.spacing(2)}px`,
    },
    '& .MuiTableRow-footer': {
      '& .MuiTableCell-root': {
        border: 0,
      },
    },
    [theme.breakpoints.down(655)]: {
      borderRadius: options?.borderRadius || '5px 30px',
      padding: options?.padding || theme.spacing(3),
      minHeight: options?.mobileMinHeight || 'auto',
      boxShadow: options?.boxShadow || theme.shadows[2],
    },
  },
  iconButtonContainer: {
    textAlign: 'center',
  },
  iconButton: {
    padding: 6,
  },
  seeMoreContainer: {
    textAlign: 'right',
  },
  seeMore: {
    borderRadius: 16,
    marginTop: theme.spacing(2),
  },
  emptyTableMessage: {
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
});
