import { gender } from 'common/constants';
import { toFixed } from 'common/utils';

const getAgeToLetter = (age) => {
  if (age >= 16 && age <= 19) {
    return 'a';
  }

  if (age >= 20 && age <= 29) {
    return 'b';
  }

  if (age >= 30 && age <= 39) {
    return 'b';
  }

  if (age >= 40 && age <= 49) {
    return 'b';
  }

  return 'e';
};

const getDurninConstants = (clientGender, clientAge) => {
  const letter = getAgeToLetter(clientAge);
  let constants;

  if (clientGender === gender.female) {
    constants = {
      a: () => ({ c: 1.1549, m: 0.0678 }),
      b: () => ({ c: 1.1599, m: 0.0717 }),
      c: () => ({ c: 1.1423, m: 0.0632 }),
      d: () => ({ c: 1.1333, m: 0.0612 }),
      e: () => ({ c: 1.1339, m: 0.0645 }),
    };
  } else {
    constants = {
      a: () => ({ c: 1.1620, m: 0.0630 }),
      b: () => ({ c: 1.1631, m: 0.0632 }),
      c: () => ({ c: 1.1422, m: 0.0544 }),
      d: () => ({ c: 1.1620, m: 0.0700 }),
      e: () => ({ c: 1.1715, m: 0.0779 }),
    };
  }

  return constants[letter]();
};

export const getBmi = (weight, height) => {
  const value = weight / (height ** 2);
  return toFixed(value, 2);
};

export const getBodyDensity = (clientGender, clientAge, foldSum) => {
  const { c, m } = getDurninConstants(clientGender, clientAge);
  const value = c - (m * Math.log10(foldSum));

  return toFixed(value, 2);
};

export const getFatPercentage = (bDensity) => {
  const value = ((4.95 / bDensity) - 4.5) * 100;
  return toFixed(value, 2);
};

export const getFatWeight = (fPercentage, weight) => {
  const value = (fPercentage * weight) / 100;
  return toFixed(value, 2);
};

export const getLeanWeight = (weight, fWeight) => {
  const value = weight - fWeight;
  return toFixed(value, 2);
};

export const getNormalFatPercentage = (clientGender) => {
  if (clientGender === gender.female) {
    return 25.00;
  }

  return 20.00;
};

export const getIdealFatPercentage = (clientGender) => {
  if (clientGender === gender.female) {
    return 22.00;
  }

  return 15.00;
};

export const getMetabolicRate = (lWeight) => {
  const value = 370 + (21.6 * lWeight);
  return toFixed(value, 2);
};

export const getIdealLeanWeight = (clientGender, lWeight) => {
  let value = 0;

  if (clientGender === gender.female) {
    value = lWeight / 0.78;
  } else {
    value = lWeight / 0.85;
  }

  return toFixed(value, 2);
};
