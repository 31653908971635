import { useState } from 'react';

const usePaginationSettings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  return {
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
  };
};

export default usePaginationSettings;
