import React from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import BackButton from 'components/layout/BackButton';
import Evaluation from 'components/shared/Evaluation';
import evaluation from 'api/evaluation';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    width: '100%',
    minHeight: 250,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
      marginTop: 0,
      minHeight: 'calc(100vh - 259.467px)',
      boxShadow: 'none',
    },
  },
}));

/**
 * ClientEvaluation component
 *
 * @return {jsx} react component
 */
function ClientEvaluation() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['evaluation', id],
    evaluation.getHistoricalData,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'view-evaluation-error',
          variant: 'error',
        });
      },
    },
  );

  const evaluationData = data?.data?.getHistoricalData || {};

  return (
    <React.Fragment>
      <BackButton inverseColors />
      <div className={classes.card}>
        {(!isLoading && !isFetching && Object.keys(evaluationData).length > 0) ? (
          <Evaluation data={evaluationData} showComments={false} />
        ) : (
          <DialogLoadingIndicator open borderRadius="5px 30px" />
        )}
      </div>
    </React.Fragment>
  );
}

export default ClientEvaluation;
