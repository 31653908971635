import _isEmpty from 'lodash/isEmpty';
import _isDate from 'lodash/isDate';
import _isInteger from 'lodash/isInteger';

const isValid = (value) => typeof value !== 'undefined' && value !== null;

export const hasValue = (value) => !_isEmpty(value);

export const minLength = (value, min) => {
  if (!isValid(value)) {
    return false;
  }

  return value.length >= min;
};

export const lengthBetween = (value, min, max) => {
  if (!isValid(value)) {
    return false;
  }

  return value.length >= min && value.length <= max;
};

export const email = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

export const password = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^(?=.*[0-9]+.*)(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})[0-9a-zA-Z]{8,}$/.test(value);
};

export const alphaSpace = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Za-zñÑ]+[A-Za-zñÑ\s]*$/i.test(value);
};

export const alphaNumSpace = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Za-záÁéÉíÍóÓúÚñÑ]+[A-Za-z0-9áÁéÉíÍóÓúÚñÑ\\.\s]*$/i.test(value);
};

export const isValidDate = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return _isDate(value);
};

export const isFloat = (value) => {
  if (typeof value === 'number' && value !== null) {
    return Number(value) === value && value % 1 !== 0;
  }

  return false;
};

export const positiveFloat = (value) => {
  if (!isValid(value)) {
    return false;
  }

  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    const formatedValue = parseFloat(convertedValue);

    return isFloat(formatedValue) && formatedValue >= 0;
  }

  return false;
};

export const positiveInt = (value) => {
  if (!isValid(value)) {
    return false;
  }

  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    const formatedValue = parseInt(convertedValue, 10);

    return _isInteger(formatedValue) && formatedValue >= 0;
  }

  return false;
};
