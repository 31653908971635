const clientRoutes = {
  clientProfile: {
    path: '/mi-perfil/inicio',
    title: 'Inicio',
  },
  clientInformation: {
    path: '/mi-perfil/datos',
    title: 'Mi Información',
  },
  clientEvaluations: {
    path: '/mi-perfil/valoraciones',
    title: 'Mis Valoraciones',
  },
  clientEvaluation: {
    path: '/mi-perfil/valoraciones/:id',
    title: 'Valoracion',
  },
  clientPayments: {
    path: '/mi-perfil/pagos',
    title: 'Mis Pagos',
  },
};

export default clientRoutes;
