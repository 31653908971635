import React from 'react';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * CustomMask component
 *
 * @param {object} props
 * @return {jsx} react component
 */
function CustomMask(props) {
  const { inputRef, mask, ...other } = props;

  const maskToApply = mask.length > 0 ? mask : createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: true,
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskToApply}
    />
  );
}

/**
 * FloatFieldAdapter component
 *
 * @param {object} props
 * @return {jsx} react component
 */
function FloatFieldAdapter(props) {
  const {
    input,
    meta,
    mask,
    InputProps,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <TextField
      {...input}
      {...rest}
      InputProps={{
        ...InputProps,
        mask,
        inputComponent: CustomMask,
      }}
      InputLabelProps={{
        classes: { focused: classes.focusedLabel },
      }}
      onChange={(event) => input.onChange(event.target.value)}
      error={meta.touched ? meta.invalid : false}
      helperText={(meta.touched && meta.invalid) ? meta.error : ''}
    />
  );
}

CustomMask.defaultProps = {
  mask: [],
};

CustomMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
};

FloatFieldAdapter.defaultProps = {
  InputProps: {},
  mask: [],
};

FloatFieldAdapter.propTypes = {
  InputProps: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mask: PropTypes.array,
};

export default FloatFieldAdapter;
