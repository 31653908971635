import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(7),
    alignItems: (props) => (props.backButton ? 'center' : 'flex-end'),
    justifyContent: (props) => (props.backButton ? 'flex-start' : 'space-between'),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      padding: '0 12px',
    },
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    margin: (props) => (props.backButton ? `0 0 0 ${theme.spacing(3)}px` : 0),
  },
}));

/**
 * SectionTitle component
 *
 * @param {object} props
 * @return {jsx} react component
 */
function SectionTitle(props) {
  const { text, children, backButton } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.titleContainer}>
      {backButton ? (
        <React.Fragment>
          {children}
          <Typography color="textPrimary" className={classes.title} variant="h1">{text}</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography color="textPrimary" className={classes.title} variant="h1">{text}</Typography>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

SectionTitle.defaultProps = {
  backButton: false,
};

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  backButton: PropTypes.bool,
};

export default SectionTitle;
