import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field } from 'react-final-form';
import useUserOptions from 'hooks/useUserOptions';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import FloatFieldAdapter from 'components/shared/FloatFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import { debounceWait } from 'common/constants';

/**
 * EvaluationFields component
 *
 * @param {object} { classes, form }
 * @return {jsx} react component
 */
function EvaluationFields({ classes, form }) {
  const {
    userOptions,
    isUserLoading,
    isUserFetching,
    setUserSearch,
  } = useUserOptions('evaluation');

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      setUserSearch(value);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="user_id"
            name="user_id"
            component={AutocompleteFieldAdapter}
            options={userOptions}
            isMultiple={false}
            filterSelectedOptions
            loading={isUserLoading || isUserFetching}
            onInputChange={_debounce(onUserIdChange, debounceWait)}
            textProps={{
              label: 'Cliente',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="gender"
            name="gender"
            component={TextFieldAdapter}
            label="Genero"
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="age"
            name="age"
            component={TextFieldAdapter}
            label="Edad"
            InputProps={{
              endAdornment: <InputAdornment position="end">años</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="height"
            name="height"
            component={FloatFieldAdapter}
            label="Altura"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="weight"
            name="weight"
            component={FloatFieldAdapter}
            label="Peso"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="biceps"
            name="biceps"
            component={FloatFieldAdapter}
            label="Biceps"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="triceps"
            name="triceps"
            component={FloatFieldAdapter}
            label="Triceps"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="subscapular"
            name="subscapular"
            component={FloatFieldAdapter}
            label="Subescapular"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="suprailiac"
            name="suprailiac"
            component={FloatFieldAdapter}
            label="Suprailiaco"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="abdominal"
            name="abdominal"
            component={FloatFieldAdapter}
            label="Abdominal"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="acro_acro"
            name="acro_acro"
            component={FloatFieldAdapter}
            label="Acro-Acro"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="neck_hip"
            name="neck_hip"
            component={FloatFieldAdapter}
            label="Cuello-Cadera"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="hip_ankle"
            name="hip_ankle"
            component={FloatFieldAdapter}
            label="Cadera-Tobillo"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="acro_wrist"
            name="acro_wrist"
            component={FloatFieldAdapter}
            label="Acro-Muñeca"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="neck"
            name="neck"
            component={FloatFieldAdapter}
            label="Cuello"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="thorax"
            name="thorax"
            component={FloatFieldAdapter}
            label="Torax"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="high_waist"
            name="high_waist"
            component={FloatFieldAdapter}
            label="Cintura Alta"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="low_waist"
            name="low_waist"
            component={FloatFieldAdapter}
            label="Cintura Baja"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="hip"
            name="hip"
            component={FloatFieldAdapter}
            label="Cadera"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="high_left_leg"
            name="high_left_leg"
            component={FloatFieldAdapter}
            label="Pierna Izq Alta"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="medium_left_leg"
            name="medium_left_leg"
            component={FloatFieldAdapter}
            label="Pierna Izq Media"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="low_left_leg"
            name="low_left_leg"
            component={FloatFieldAdapter}
            label="Pierna Izq Baja"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="left_calf"
            name="left_calf"
            component={FloatFieldAdapter}
            label="Pantorrilla Izq"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="high_right_leg"
            name="high_right_leg"
            component={FloatFieldAdapter}
            label="Pierna Der Alta"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="medium_right_leg"
            name="medium_right_leg"
            component={FloatFieldAdapter}
            label="Pierna Der Media"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="low_right_leg"
            name="low_right_leg"
            component={FloatFieldAdapter}
            label="Pierna Der Baja"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="right_calf"
            name="right_calf"
            component={FloatFieldAdapter}
            label="Pantorrilla Der"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="left_arm"
            name="left_arm"
            component={FloatFieldAdapter}
            label="Brazo Izq"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="right_arm"
            name="right_arm"
            component={FloatFieldAdapter}
            label="Brazo Der"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={4}>
          <Field
            id="muscle_percentage"
            name="muscle_percentage"
            component={FloatFieldAdapter}
            label="% de Musculo"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="visceral_fat"
            name="visceral_fat"
            component={FloatFieldAdapter}
            label="Grasa Visceral"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            id="biological_age"
            name="biological_age"
            component={FloatFieldAdapter}
            label="Edad Biologica"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">años</InputAdornment>,
            }}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.calculateContainer}>
        <Grid item xs={12}>
          <Button
            className={classes.roundedButton}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => { form.mutators.calculateFields(); }}
          >
            Calcular
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="bmi"
            name="bmi"
            component={TextFieldAdapter}
            label="IMC"
            placeholder="0.00"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  kg/m
                  <sup>2</sup>
                </InputAdornment>
              ),
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="fat_percentage"
            name="fat_percentage"
            component={TextFieldAdapter}
            label="% de Grasa"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="body_density"
            name="body_density"
            component={TextFieldAdapter}
            label="Densidad Corporal"
            placeholder="0.00"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  kg l
                  <sup>-1</sup>
                </InputAdornment>
              ),
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="fat_weight"
            name="fat_weight"
            component={TextFieldAdapter}
            label="Peso Graso"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="lean_weight"
            name="lean_weight"
            component={TextFieldAdapter}
            label="Peso Magro"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="normal_fat_percentage"
            name="normal_fat_percentage"
            component={TextFieldAdapter}
            label="% Graso Normal"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="ideal_fat_percentage"
            name="ideal_fat_percentage"
            component={TextFieldAdapter}
            label="% Graso Ideal"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="metabolic_rate"
            name="metabolic_rate"
            component={TextFieldAdapter}
            label="Tasa Metabolica"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kcal</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Field
            id="ideal_lean_weight"
            name="ideal_lean_weight"
            component={TextFieldAdapter}
            label="Peso Magro Ideal"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            required
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.commentsContainer}>
        <Grid item xs={12}>
          <Field
            id="comments"
            name="comments"
            component={TextFieldAdapter}
            label="Comentarios"
            multiline
            rowsMax="4"
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

EvaluationFields.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default EvaluationFields;
