import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (inverseColors) => (
      inverseColors ? theme.palette.primary.dark : theme.palette.primary.main
    ),
    transition: theme.transitions.create(['background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: (inverseColors) => (
        inverseColors ? theme.palette.primary.light : theme.palette.primary.dark
      ),
    },
  },
}));

/**
 * BackButton component
 *
 * @param {object} { inverseColors }
 * @return {jsx} react component
 */
function BackButton({ inverseColors }) {
  const classes = useStyles({ inverseColors });
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Tooltip title="Ir atrás">
      <IconButton
        aria-label="go-back"
        onClick={goBack}
        classes={{
          root: classes.root,
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
}

BackButton.defaultProps = {
  inverseColors: false,
};

BackButton.propTypes = {
  inverseColors: PropTypes.bool,
};

export default BackButton;
