import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from 'components/layout/SectionTitle';
import BackButton from 'components/layout/BackButton';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import usePaginationSettings from 'hooks/usePaginationSettings';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';
import { getEvaluationData } from 'common/table/data';
import { getEvaluationColumns } from 'common/table/columns';
import { tableClickableRowsClasses } from 'common/muiSharedClasses';
import routes from 'common/routes/index';
import evaluation from 'api/evaluation';

const useStyles = makeStyles(tableClickableRowsClasses);

/**
 * MyClientEvaluations component
 *
 * @return {jsx} react component
 */
function MyClientEvaluations() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();

  const filters = { user_id: id };

  const {
    data,
    isLoading,
  } = useQuery(
    ['evaluations', { page: currentPage, first: itemsPerPage }, filters],
    evaluation.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-evaluations-error',
          variant: 'error',
        });
      },
    },
  );

  if (!data) {
    return (
      <Skeleton
        variant="rect"
        height={200}
        component="div"
        animation="wave"
      />
    );
  }

  const { data: { evaluations: { data: evaluationData, paginatorInfo: { total } } } } = data;

  const tableData = getEvaluationData(evaluationData);
  const columns = getEvaluationColumns(tableData);

  const onCellClick = (colData, cellMeta) => {
    const { rowIndex } = cellMeta;
    const { id: evaluationId } = tableData[rowIndex];
    const path = routes.myClientEvaluationDetail.path.replace(':id', evaluationId);

    history.push(path);
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    selectableRows: 'none',
    selectableRowsHeader: false,
    count: total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    onCellClick,
    textLabels: tableLocalization('Valoraciones'),
  };

  return (
    <div className={classes.tableContainer}>
      <SectionTitle text="" backButton>
        <BackButton />
      </SectionTitle>
      <div>
        <DialogLoadingIndicator open={isLoading && data} />
        <MUIDataTable
          title="Valoraciones"
          columns={columns}
          data={tableData}
          options={options}
        />
      </div>
    </div>
  );
}

export default MyClientEvaluations;
