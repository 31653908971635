import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import intervalToDuration from 'date-fns/intervalToDuration';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import Ballot from 'mdi-material-ui/Ballot';
import LogoutButton from 'components/layout/LogoutButton';
import AuthUserContext from 'context/AuthUserContext';
import { removeAuthInfo } from 'common/utils';
import routes from 'common/routes';
import auth from 'api/auth';
import { ReactComponent as Logo } from 'images/logo-white.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
    padding: theme.spacing(4),
    [theme.breakpoints.down(1110)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down(960)]: {
      position: 'relative',
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down(655)]: {
      backgroundColor: theme.palette.primary.dark,
      padding: 0,
    },
  },
  profileContainer: {
    display: 'flex',
    width: 1200,
    [theme.breakpoints.down(1264)]: {
      width: '100%',
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(9),
    },
  },
  headerMobile: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
      marginBottom: 0,
    },
  },
  headerMobileTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(655)]: {
      justifyContent: 'center',
    },
  },
  headerMobileButtons: {
    [theme.breakpoints.down(960)]: {
      display: 'flex',
    },
  },
  sidebar: {
    borderRadius: '5px 40px',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 150,
    padding: theme.spacing(4),
    boxShadow: theme.shadows[2],
  },
  navContainer: {
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      width: 100,
      marginBottom: 0,
    },
  },
  navMobile: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    padding: theme.spacing(2),
    width: '100%',
    zIndex: 100,
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(3),
    outline: 'none',
    textDecoration: 'none',
    lineHeight: 0,
    [theme.breakpoints.down(960)]: {
      marginTop: 0,
      marginRight: theme.spacing(4),
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  navItem: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: 16,
    padding: theme.spacing(2),
    textAlign: 'center',
    boxShadow: theme.shadows[1],
    transition: theme.transitions.create(['background-color', 'color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down(960)]: {
      borderRadius: 10,
      padding: 8,
    },
  },
  navItemSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  navItemIconSelected: {
    color: '#FFCA3A',
  },
  navItemLabel: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
  },
  main: {
    flexGrow: 1,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 0,
    letterSpacing: 1,
    [theme.breakpoints.down(960)]: {
      fontSize: theme.typography.pxToRem(22),
      marginBottom: theme.spacing(1),
    },
  },
  daysContainer: {
    [theme.breakpoints.down(655)]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(4),
    },
  },
  daysCounter: {
    borderRadius: 9999,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `5px ${theme.spacing(2)}px`,
    [theme.breakpoints.down(960)]: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
  },
  daysCounterSm: {
    marginRight: theme.spacing(3),
  },
  daysCounterXs: {
    width: 180,
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

/**
 * ProfileLayout component
 *
 * @param {object} { component: Component, ...rest }
 * @return {jsx} react component
 */
function ProfileLayout({ component: Component, ...rest }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const [title, setTitle] = useState('');
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    const { payments: { data: payments } } = authUser;

    if (payments.length > 0) {
      const lastPayment = payments[0];
      const endDate = new Date(lastPayment.end_date);
      const today = new Date();
      const duration = intervalToDuration({ start: today, end: endDate });
      setDaysLeft(duration.days);
    }
  }, [authUser]);

  const logoutMutation = useMutation(auth.logout, {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'logout-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      window.localStorage.clear();
      removeAuthInfo();
      setAuthUser({});
    },
  });

  const logout = () => {
    logoutMutation.mutate();
  };

  const isSelected = (path) => pathname.includes(path);

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <div className={classes.profileContainer}>
        <Hidden mdUp>
          <div className={classes.headerMobile}>
            <div className={classes.headerMobileTop}>
              <Logo className={classes.logo} />
              <div className={classes.headerMobileButtons}>
                {typeof daysLeft === 'number' && (
                  <Hidden xsDown>
                    <div className={clsx(classes.daysCounter, classes.daysCounterSm)}>
                      {daysLeft <= 0 ? (
                        'Te encuentras en mora'
                      ) : (
                        `Próximo pago: ${daysLeft} ${daysLeft === 1 ? ' día' : ' días'}`
                      )}
                    </div>
                  </Hidden>
                )}
                <Hidden xsDown>
                  <LogoutButton
                    isLoading={logoutMutation.isLoading}
                    label="Salir"
                    onClick={logout}
                  />
                </Hidden>
              </div>
            </div>
            {typeof daysLeft === 'number' && (
              <Hidden only="sm">
                <div className={classes.daysContainer}>
                  <div className={clsx(classes.daysCounter, classes.daysCounterXs)}>
                    {daysLeft <= 0 ? (
                      'Te encuentras en mora'
                    ) : (
                      `Próximo pago: ${daysLeft} ${daysLeft === 1 ? ' día' : ' días'}`
                    )}
                  </div>
                  <Hidden smUp>
                    <LogoutButton
                      isLoading={logoutMutation.isLoading}
                      label="Salir"
                      onClick={logout}
                    />
                  </Hidden>
                </div>
              </Hidden>
            )}
          </div>
        </Hidden>

        <Hidden smDown>
          <section className={classes.sidebar}>
            <div className={classes.navContainer}>
              <Logo className={classes.logo} />
              <nav>
                <Link to={routes.clientProfile.path} className={classes.link}>
                  <div className={clsx(classes.navItem, {
                    [classes.navItemSelected]: isSelected(routes.clientProfile.path),
                  })}
                  >
                    <HomeIcon className={clsx({
                      [classes.navItemIconSelected]: isSelected(routes.clientProfile.path),
                    })}
                    />
                    <Typography className={classes.navItemLabel}>Inicio</Typography>
                  </div>
                </Link>
                <Link to={routes.clientEvaluations.path} className={classes.link}>
                  <div className={clsx(classes.navItem, {
                    [classes.navItemSelected]: isSelected(routes.clientEvaluations.path),
                  })}
                  >
                    <Ballot className={clsx({
                      [classes.navItemIconSelected]: isSelected(routes.clientEvaluations.path),
                    })}
                    />
                    <Typography className={classes.navItemLabel}>Controles</Typography>
                  </div>
                </Link>
                <Link to={routes.clientPayments.path} className={classes.link}>
                  <div className={clsx(classes.navItem, {
                    [classes.navItemSelected]: isSelected(routes.clientPayments.path),
                  })}
                  >
                    <PaymentIcon className={clsx({
                      [classes.navItemIconSelected]: isSelected(routes.clientPayments.path),
                    })}
                    />
                    <Typography className={classes.navItemLabel}>Pagos</Typography>
                  </div>
                </Link>
                <Link to={routes.clientInformation.path} className={classes.link}>
                  <div className={clsx(classes.navItem, {
                    [classes.navItemSelected]: isSelected(routes.clientInformation.path),
                  })}
                  >
                    <AccountCircleIcon className={clsx({
                      [classes.navItemIconSelected]: isSelected(routes.clientInformation.path),
                    })}
                    />
                    <Typography className={classes.navItemLabel}>Perfil</Typography>
                  </div>
                </Link>
              </nav>
            </div>
            <LogoutButton
              isLoading={logoutMutation.isLoading}
              label="Salir"
              onClick={logout}
              variant="contained"
            />
          </section>
        </Hidden>

        <main className={classes.main}>
          <Hidden smDown>
            <header className={classes.titleContainer}>
              <Typography color="textPrimary" className={classes.title} variant="h1">{title}</Typography>
              {typeof daysLeft === 'number' && (
                <div className={classes.daysCounter}>
                  {daysLeft <= 0 ? (
                    'Te encuentras en mora'
                  ) : (
                    `Próximo pago: ${daysLeft} ${daysLeft === 1 ? ' día' : ' días'}`
                  )}
                </div>
              )}
            </header>

            <Divider className={classes.divider} />
          </Hidden>

          <div>
            <Component setTitle={setTitle} {...rest} />
          </div>
        </main>
      </div>

      <Hidden mdUp>
        <nav className={classes.navMobile}>
          <Link to={routes.clientProfile.path} className={classes.link}>
            <div
              className={clsx(classes.navItem, {
                [classes.navItemSelected]: isSelected(routes.clientProfile.path),
              })}
            >
              <HomeIcon className={clsx({
                [classes.navItemIconSelected]: isSelected(routes.clientProfile.path),
              })}
              />
            </div>
          </Link>
          <Link to={routes.clientEvaluations.path} className={classes.link}>
            <div
              className={clsx(classes.navItem, {
                [classes.navItemSelected]: isSelected(routes.clientEvaluations.path),
              })}
            >
              <Ballot className={clsx({
                [classes.navItemIconSelected]: isSelected(routes.clientEvaluations.path),
              })}
              />
            </div>
          </Link>
          <Link to={routes.clientPayments.path} className={classes.link}>
            <div
              className={clsx(classes.navItem, {
                [classes.navItemSelected]: isSelected(routes.clientPayments.path),
              })}
            >
              <PaymentIcon className={clsx({
                [classes.navItemIconSelected]: isSelected(routes.clientPayments.path),
              })}
              />
            </div>
          </Link>
          <Link to={routes.clientInformation.path} className={classes.link}>
            <div
              className={clsx(classes.navItem, {
                [classes.navItemSelected]: isSelected(routes.clientInformation.path),
              })}
            >
              <AccountCircleIcon className={clsx({
                [classes.navItemIconSelected]: isSelected(routes.clientInformation.path),
              })}
              />
            </div>
          </Link>
        </nav>
      </Hidden>
    </Container>
  );
}

ProfileLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProfileLayout;
