import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpBold from 'mdi-material-ui/ArrowUpBold';
import ArrowDownBold from 'mdi-material-ui/ArrowDownBold';
import Equal from 'mdi-material-ui/Equal';
import { toFixed, addDecimals } from 'common/utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  evaluationButton: {
    height: '100%',
    width: '100%',
  },
  evaluationItem: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    wordWrap: 'break-word',
  },
  fieldLabel: {
    marginTop: 0,
    marginBottom: 5,
    textAlign: 'left',
  },
  fieldValue: {
    alignItems: 'center',
    display: 'flex',
    margin: 0,
  },
  icon: {
    fontSize: theme.typography.pxToRem(12),
  },
  arrowUp: {
    color: '#1982C4',
  },
  arrowDown: {
    color: '#FF595E',
  },
  equal: {
    color: '#FFCA3A',
  },
}));

/**
 * EvaluationItem component
 *
 * @param {object} { current, label, name, onClick, previous, showDecimals, unit }
 * @return {jsx} react component
 */
function EvaluationItem({
  current,
  label,
  name,
  onClick,
  previous,
  showDecimals,
  unit,
}) {
  const classes = useStyles();

  const getSymbol = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return <ArrowUpBold className={clsx(classes.icon, classes.arrowUp)} />;
    }

    if (previousValue > currentValue) {
      return <ArrowDownBold className={clsx(classes.icon, classes.arrowDown)} />;
    }

    return <Equal className={clsx(classes.icon, classes.equal)} />;
  };

  const itemContent = (
    <React.Fragment>
      <p className={classes.fieldLabel}>
        {label}
      </p>
      <p className={classes.fieldValue}>
        {previous.length > 0 && getSymbol(current[name], previous.at(-1)[name])}
        &nbsp;
        {showDecimals ? addDecimals(toFixed(current[name], 2)) : current[name]}
        &nbsp;
        {unit}
      </p>
    </React.Fragment>
  );

  if (previous.length <= 0) {
    return itemContent;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      classes={{ root: classes.evaluationButton, label: classes.evaluationItem }}
      onClick={() => onClick(name, label, unit)}
    >
      { itemContent }
    </Button>
  );
}

EvaluationItem.defaultProps = {
  showDecimals: true,
};

EvaluationItem.propTypes = {
  current: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  previous: PropTypes.array.isRequired,
  showDecimals: PropTypes.bool,
  unit: PropTypes.string.isRequired,
};

export default EvaluationItem;
