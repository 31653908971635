import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import createDecorator from 'final-form-calculate';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validateEvaluation } from 'common/form/validations';
import { getAge } from 'common/utils';
import { resetForm } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import {
  getBmi,
  getBodyDensity,
  getFatPercentage,
  getFatWeight,
  getLeanWeight,
  getNormalFatPercentage,
  getIdealFatPercentage,
  getMetabolicRate,
  getIdealLeanWeight,
} from 'common/evaluationUtils';
import { formModalClasses } from 'common/muiSharedClasses';
import evaluation from 'api/evaluation';
import EvaluationFields from './EvaluationFields';

const useStyles = makeStyles(formModalClasses);

const initialValues = {
  user_id: [],
  gender: '',
  comments: null,
};

const calculator = createDecorator(
  {
    field: 'user_id',
    updates: {
      gender: (userIdValue) => userIdValue?.gender || '',
      height: (userIdValue) => userIdValue?.height || '',
      age: (userIdValue) => getAge(new Date(), new Date(userIdValue.birthdate || '')),
    },
  },
);

/**
 * CreateEvaluationModal component
 *
 * @param {object} { open, onClose }
 * @return {jsx} react component
 */
function CreateEvaluationModal({ open, onClose }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isCalculated, setIsCalculated] = useState(false);

  const closeModal = () => {
    onClose(false);
  };

  const createEvaluationMutation = useMutation((payload) => evaluation.create(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'create-evaluation-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Valoración creada con éxito', {
        key: 'create-evaluation-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('evaluations');
    },
  });

  const calculateFields = (args, state, tools) => {
    const {
      formState: {
        values: {
          age, height, gender, weight, biceps, triceps, subscapular, suprailiac,
        },
      },
    } = state;

    const foldSum = Number(biceps) + Number(triceps) + Number(subscapular) + Number(suprailiac);
    const bmi = getBmi(weight, height);
    const bodyDensity = getBodyDensity(gender, age, foldSum);
    const fatPercentage = getFatPercentage(bodyDensity);
    const fatWeight = getFatWeight(fatPercentage, weight);
    const leanWeight = getLeanWeight(weight, fatWeight);
    const normalFatPercentage = getNormalFatPercentage(gender);
    const idealFatPercentage = getIdealFatPercentage(gender);
    const metabolicRate = getMetabolicRate(leanWeight);
    const idealLeanWeight = getIdealLeanWeight(gender, leanWeight);

    tools.changeValue(state, 'bmi', () => bmi);
    tools.changeValue(state, 'body_density', () => bodyDensity);
    tools.changeValue(state, 'fat_percentage', () => fatPercentage);
    tools.changeValue(state, 'fat_weight', () => fatWeight);
    tools.changeValue(state, 'lean_weight', () => leanWeight);
    tools.changeValue(state, 'normal_fat_percentage', () => normalFatPercentage);
    tools.changeValue(state, 'ideal_fat_percentage', () => idealFatPercentage);
    tools.changeValue(state, 'metabolic_rate', () => metabolicRate);
    tools.changeValue(state, 'ideal_lean_weight', () => idealLeanWeight);

    if (!isCalculated) {
      setIsCalculated(true);
    }
  };

  const onSubmit = (values) => {
    const data = getDataToSave(values, 'evaluation');
    createEvaluationMutation.mutate(data);
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validateEvaluation}
      onSubmit={onSubmit}
      decorators={[
        calculator,
      ]}
      mutators={{
        resetForm,
        calculateFields,
      }}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="create-evaluation-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={createEvaluationMutation.isLoading} />
            <DialogTitle id="create-evaluation-modal">Crear Valoración</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <EvaluationFields classes={classes} form={form} />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={createEvaluationMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={createEvaluationMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={
                      invalid || pristine || !isCalculated || createEvaluationMutation.isLoading
                    }
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

CreateEvaluationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateEvaluationModal;
