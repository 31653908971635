import { gql } from '@apollo/client';
import client from 'apollo/client';
import { roles, optionsLimit } from 'common/constants';
import { userFilters } from 'common/filters';

/**
 * List clients method
 * @param {Object} payload
 * @returns {Promise}
 */
const listClients = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = userFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query clients {
        clients(
          first: ${first}
          page: ${page}
          ${filters.user_id}
          ${filters.gender}
          ${filters.joining_date}
          ${filters.plan_id}
          ${filters.trainer_id}
          ${filters.services_id}
          ${filters.is_zuda}
          ${filters.is_active}
          ${filters.soft_delete}
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            email
            first_name
            last_name
            full_name
            gender
            birthdate
            height
            phone
            photo
            joining_date
            membership_days_left
            last_payment {
              start_date
              end_date
            }
            plan_id {
              id
              name
            }
            num_classes
            trainer_id {
              id
              full_name
            }
            services_id {
              id
            }
            is_zuda
            eps
            note
            deleted_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * List my clients method
 * @param {Object} payload
 * @returns {Promise}
 */
const listMyClients = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = userFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query clients {
        clients(
          first: ${first}
          page: ${page}
          ${filters.user_id}
          ${filters.plan_id}
          ${filters.trainer_id}
          ${filters.is_zuda}
          ${filters.is_active}
          ${filters.soft_delete}
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            first_name
            last_name
            plan_id {
              name
            }
            num_classes
            membership_days_left
            last_payment {
              start_date
              end_date
            }
            trainer_id {
              full_name
            }
            total_evaluations
            is_zuda
            eps
            note
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Count clients method
 * @param {Object} payload
 * @returns {Promise}
 */
const countClients = (payload) => {
  const filters = userFilters(payload.queryKey[1]);

  return client.query({
    query: gql`
      query countClients {
        countClients(
          ${filters.trainer_id}
          ${filters.is_active}
          ${filters.soft_delete}
        )
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * List trainers method
 * @param {Object} payload
 * @returns {Promise}
 */
const listTrainers = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = userFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query trainers {
        trainers(
          first: ${first}
          page: ${page}
          ${filters.user_id}
          ${filters.gender}
          ${filters.joining_date}
          ${filters.soft_delete}
          role: ${roles.trainer}
          orderBy: {
            column: JOINING_DATE,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            email
            first_name
            last_name
            full_name
            gender
            birthdate
            phone
            photo
            joining_date
            total_clients
            specialty
            deleted_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Get trainers options method
 * @returns {Promise}
 */
const getTrainersSelectOptions = () => client.query({
  query: gql`
    query allTrainers {
      allTrainers(
        role: ${roles.trainer}
      ) {
        id
        full_name
      }
    }
  `,
  fetchPolicy: 'network-only',
});

/**
 * Get user search options method
 * @param {Object} payload
 * @returns {Promise}
 */
const getSearchOptions = (payload) => {
  const input = { ...payload.queryKey[1], limit: optionsLimit };

  return client.query({
    query: gql`
      query searchUser($input: SearchUserInput!) {
        searchUser(input: $input) {
          id
          full_name
        }
      }
    `,
    variables: { input },
    fetchPolicy: 'network-only',
  });
};

/**
 * Get evaluation user search options method
 * @param {Object} payload
 * @returns {Promise}
 */
const getEvaluationSearchOptions = (payload) => {
  const input = { ...payload.queryKey[1], limit: optionsLimit };

  return client.query({
    query: gql`
      query searchUser($input: SearchUserInput!) {
        searchUser(input: $input) {
          id
          full_name
          height
          gender
          birthdate
        }
      }
    `,
    variables: { input },
    fetchPolicy: 'network-only',
  });
};

/**
 * Create user method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createUser($input: CreateUserInput!) {
      createUser(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Update user method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) => client.mutate({
  mutation: gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput!) {
      updateUser(id: $id, input: $input) {
        id
        first_name
        last_name
        email
        phone
        gender
        height
        birthdate
        specialty
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

/**
 * Remove clients method
 * @param  {String} ids
 * @return {Promise}
 */
const remove = (ids) => client.mutate({
  mutation: gql`
    mutation deleteUsers($id: [ID!]!) {
      deleteUsers(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Restore clients method
 * @param  {String} ids
 * @return {Promise}
 */
const restore = (ids) => client.mutate({
  mutation: gql`
    mutation restoreUsers($id: [ID!]!) {
      restoreUsers(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

export default {
  listClients,
  listMyClients,
  countClients,
  listTrainers,
  getTrainersSelectOptions,
  getSearchOptions,
  getEvaluationSearchOptions,
  create,
  update,
  remove,
  restore,
};
