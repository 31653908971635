import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import add from 'date-fns/add';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validatePayment } from 'common/form/validations';
import { resetForm, getFieldsToUpdate } from 'common/form/utils';
import { paymentInitialValues } from 'common/form/initialValues';
import getDataToSave from 'common/form/dataToSave';
import { formModalClasses } from 'common/muiSharedClasses';
import payment from 'api/payment';
import PaymentFields from './PaymentFields';

const useStyles = makeStyles(formModalClasses);

const calculator = createDecorator(
  {
    field: 'start_date',
    updates: {
      end_date: (startDateValue) => add(startDateValue, { days: 30 }),
    },
  },
);

/**
 * EditPaymentModal component
 *
 * @param {object} { open, onClose, selectedItem }
 * @return {jsx} react component
 */
function EditPaymentModal({ open, onClose, selectedItem }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      const values = paymentInitialValues(selectedItem);
      setInitialValues(values);
    }
  }, [selectedItem]);

  const closeModal = () => {
    onClose(false);
  };

  const updatePaymentMutation = useMutation((payload) => payment.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-payment-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Pago editado con éxito', {
        key: 'update-payment-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('payments');
    },
  });

  const onSubmit = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const data = getDataToSave(fieldsToUpdate, 'payment');
    updatePaymentMutation.mutate({ id: selectedItem.id, input: data });
  };

  if (initialValues === null) {
    return false;
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validatePayment}
      onSubmit={onSubmit}
      mutators={{
        resetForm,
      }}
      decorators={[
        calculator,
      ]}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="update-payment-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={updatePaymentMutation.isLoading} />
            <DialogTitle id="update-client-modal">Editar Cliente</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <PaymentFields classes={classes} />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={updatePaymentMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={updatePaymentMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || updatePaymentMutation.isLoading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

EditPaymentModal.defaultProps = {
  selectedItem: null,
};

EditPaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedItem: PropTypes.object,
};

export default EditPaymentModal;
