import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import plan from 'api/plan';

const usePlanOptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  let planOptions = [];

  const {
    isLoading: isPlanLoading,
    isSuccess: isPlanSuccess,
    isError: isPlanError,
    error: planError,
    data: plans,
  } = useQuery('allPlans', plan.getAll);

  if (isPlanError) {
    enqueueSnackbar(planError.message, {
      key: 'get-all-plans-error',
      variant: 'error',
    });
  }

  if (isPlanSuccess && plans && typeof plans !== 'undefined') {
    planOptions = plans.data.allPlans.map((option) => ({ value: option.id, label: option.name }));
    planOptions.unshift({ value: null, label: 'Ninguno' });
  }

  return {
    planOptions,
    isPlanLoading,
  };
};

export default usePlanOptions;
