import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { esES } from '@material-ui/core/locale';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { publicRoutes, privateRoutes } from './Routes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

/**
 * Router component
 *
 * @return {jsx} react component
 */
function Router() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 'Infinity',
        refetchOnWindowFocus: false,
      },
    },
  });

  const breakpoints = createBreakpoints({});

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6a4c93',
      },
      secondary: {
        main: '#ff595e',
      },
      type: 'dark',
    },
    overrides: {
      MUIDataTable: {
        caption: {
          display: 'none',
        },
        paper: {
          backgroundColor: '#4a3566',
          borderRadius: '30px 5px',
          boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
          [breakpoints.down(655)]: {
            borderRadius: 0,
          },
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: '#4a3566',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: '#4a3566',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          borderColor: '#6a4c93',
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor: '#ff595e',
          borderRadius: 0,
        },
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
          borderTop: '0 !important',
          borderBottom: '1px solid rgba(81, 81, 81, 1) !important',
          '&:first-child': {
            borderTop: '1px solid rgba(81, 81, 81, 1) !important',
          },
        },
      },
      MUIDataTablePagination: {
        tableCellContainer: {
          borderBottom: 0,
        },
        navContainer: {
          [breakpoints.down('sm')]: {
            justifyContent: 'center',
          },
        },
        toolbar: {
          [breakpoints.down('xs')]: {
            display: 'none',
          },
        },
      },
    },
  }, esES);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Switch>
              {privateRoutes.map(({
                key, exact, path, component, layout, allowedRoles,
              }) => (
                <PrivateRoute
                  key={key}
                  exact={exact}
                  path={path}
                  component={component}
                  layout={layout}
                  allowedRoles={allowedRoles}
                />
              ))}

              {publicRoutes.map(({
                key, exact, path, component, layout,
              }) => (
                <PublicRoute
                  key={key}
                  exact={exact}
                  path={path}
                  component={component}
                  layout={layout}
                />
              ))}
            </Switch>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default Router;
