import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * TextFieldAdapter component
 *
 * @param {object} { input, meta, ...rest }
 * @return {jsx} react component
 */
function TextFieldAdapter({ input, meta, ...rest }) {
  const classes = useStyles();

  return (
    <TextField
      {...input}
      {...rest}
      InputLabelProps={{
        classes: { focused: classes.focusedLabel },
      }}
      onChange={(event) => input.onChange(event.target.value)}
      error={meta.touched ? meta.invalid : false}
      helperText={(meta.touched && meta.invalid) ? meta.error : ''}
    />
  );
}

TextFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default TextFieldAdapter;
