import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import { makeStyles } from '@material-ui/core/styles';
import useLocalStorage from 'hooks/useLocalStorage';
import { trashed } from 'common/constants';
import { getSelectedIds } from 'common/table/utils';

const useStyles = makeStyles({
  buttonsContainer: {
    marginRight: 14,
  },
});

/**
 * CustomToolbarSelect component
 *
 * @param {object} {
 *   displayData,
 *   filtersId,
 *   onDelete,
 *   onRestore,
 *   selectedRows,
 * }
 * @return {jsx} react component
 */
function CustomToolbarSelect({
  displayData,
  filtersId,
  onDelete,
  onRestore,
  selectedRows,
}) {
  const classes = useStyles();
  const [filters] = useLocalStorage(filtersId, {});
  const softDelete = filters.soft_delete === trashed.only;

  const onClick = () => {
    const ids = getSelectedIds(selectedRows, displayData);

    if (softDelete) {
      onRestore(ids);
    } else {
      onDelete(ids);
    }
  };

  return (
    <div className={classes.buttonsContainer}>
      {softDelete ? (
        <Tooltip title="Restaurar">
          <IconButton onClick={onClick} aria-label="restore">
            <RestoreIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Eliminar">
          <IconButton onClick={onClick} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

CustomToolbarSelect.propTypes = {
  displayData: PropTypes.array.isRequired,
  filtersId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  selectedRows: PropTypes.object.isRequired,
};

export default CustomToolbarSelect;
