import React from 'react';
import PropTypes from 'prop-types';

/**
 * LoginLayout component
 *
 * @param {object} { component: Component, ...rest }
 * @return {jsx} react component
 */
function LoginLayout({ component: Component, ...rest }) {
  return (
    <Component {...rest} />
  );
}

LoginLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoginLayout;
