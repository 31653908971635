/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, darken } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { membershipStatus } from 'common/constants';
import { getMembershipStatus } from 'common/utils';

const useStyles = makeStyles({
  root: {
    backgroundColor: (props) => darken(props.bgColor, 0.3),
    textTransform: 'lowercase',
  },
  avatar: {
    backgroundColor: (props) => darken(props.bgColor, 0.5),
  },
});

/**
 * DaysLeft component
 *
 * @param {object} { data }
 * @return {jsx} react component
 */
function DaysLeft({ data }) {
  const { membership_days_left: daysLeft } = data;
  const status = getMembershipStatus(daysLeft);
  const classes = useStyles({ bgColor: status.color });

  return (
    <React.Fragment>
      {status.text === membershipStatus.expired.text ? (
        <Chip
          classes={{ root: classes.root }}
          avatar={<Avatar classes={{ root: classes.avatar }}>{daysLeft}</Avatar>}
          label={status.text}
        />
      ) : (
        <Chip
          classes={{ root: classes.root }}
          avatar={<Avatar classes={{ root: classes.avatar }}>{daysLeft}</Avatar>}
          label={daysLeft > 1 ? 'días' : 'día'}
        />
      )}
    </React.Fragment>
  );
}

DaysLeft.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DaysLeft;
