import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, darken } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create(['background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.dark, 0.3),
    },
  },
}));

/**
 * AddButton component
 *
 * @param {object} { handleClick }
 * @return {jsx} react component
 */
function AddButton({ handleClick }) {
  const classes = useStyles();

  return (
    <Tooltip title="Crear">
      <IconButton
        aria-label="create"
        onClick={handleClick}
        classes={{
          root: classes.root,
        }}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

AddButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default AddButton;
