import React from 'react';
import lightFormat from 'date-fns/lightFormat';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProfileAvatar from 'components/shared/ProfileAvatar';
import { dateFormat } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    width: 300,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      width: '100%',
      boxShadow: 'none',
      marginBottom: 0,
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  userName: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  userInfo: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 16,
    fontSize: theme.typography.pxToRem(13),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  userInfoLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

/**
 * BasicInfoCard component
 *
 * @param {object} { user }
 * @return {jsx} react component
 */
function BasicInfoCard({ user }) {
  const classes = useStyles();

  return (
    <article className={classes.card}>
      <div className={classes.avatarContainer}>
        <ProfileAvatar user={user} size={{ default: 16, xs: 14 }} />
      </div>
      <Typography color="textPrimary" className={classes.userName}>{user.full_name}</Typography>
      <div className={classes.userInfo}>
        <span className={classes.userInfoLabel}>Email:&nbsp;</span>
        {user.email}
      </div>
      <div className={classes.userInfo}>
        <span className={classes.userInfoLabel}>Miembro desde:&nbsp;</span>
        {lightFormat(new Date(user.joining_date), dateFormat)}
      </div>
      <div className={classes.userInfo}>
        <span className={classes.userInfoLabel}>Plan:&nbsp;</span>
        {user?.plan_id?.name || ''}
      </div>
      <div className={classes.userInfo}>
        <span className={classes.userInfoLabel}>Entrenador:&nbsp;</span>
        {user?.trainer_id?.full_name || ''}
      </div>
    </article>
  );
}

BasicInfoCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BasicInfoCard;
