import React from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Evaluation from 'components/shared/Evaluation';
import BackButton from 'components/layout/BackButton';
import SectionTitle from 'components/layout/SectionTitle';
import evaluation from 'api/evaluation';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    padding: `20px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },
}));

/**
 * EvaluationDetail component
 *
 * @return {jsx} react component
 */
function EvaluationDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { id } = useParams();

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['evaluation', id],
    evaluation.getHistoricalData,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'view-evaluation-error',
          variant: 'error',
        });
      },
    },
  );

  const evaluationData = data?.data?.getHistoricalData || {};

  return (
    <React.Fragment>
      <SectionTitle text="" backButton>
        <BackButton />
      </SectionTitle>
      {(!isLoading && !isFetching && Object.keys(evaluationData).length > 0) ? (
        <Paper square className={classes.paper}>
          <Evaluation data={evaluationData} />
        </Paper>
      ) : (
        <Skeleton
          variant="rect"
          height={200}
          component="div"
          animation="wave"
        />
      )}
    </React.Fragment>
  );
}

export default EvaluationDetail;
