import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
});

/**
 * NotFound component
 *
 * @return {jsx} react component
 */
function NotFound() {
  const classes = useStyles();

  return (
    <main className={classes.main}>Not found</main>
  );
}

export default NotFound;
