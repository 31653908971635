import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';

const icon = {
  edit: () => <EditIcon />,
  notification: () => <SendIcon />,
};

/**
 * TableAction component
 *
 * @param {object} {
 *   type, onClick, row, disabled,
 * }
 * @return {jsx} react component
 */
function TableAction({
  type, onClick, row, disabled,
}) {
  const title = {
    edit: () => 'Editar',
    notification: () => 'Reenviar verificación',
  };

  const handleClick = () => {
    onClick(row);
  };

  return (
    <Tooltip title={title[type]()}>
      <span>
        <IconButton
          aria-label={type}
          onClick={handleClick}
          disabled={disabled}
        >
          {icon[type]()}
        </IconButton>
      </span>
    </Tooltip>
  );
}

TableAction.defaultProps = {
  disabled: false,
};

TableAction.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default TableAction;
