import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from 'react-query';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { defaultTableOptions, tableLocalization, getCustomSelectToolBar } from 'common/table/utils';
import { getPaymentData } from 'common/table/data';
import { getPaymentColumns } from 'common/table/columns';
import { tableClasses } from 'common/muiSharedClasses';
import payment from 'api/payment';

const useStyles = makeStyles(tableClasses);

/**
 * PaymentTable component
 *
 * @param {object} {
 *   data,
 *   isLoading,
 *   currentPage,
 *   setCurrentPage,
 *   itemsPerPage,
 *   setItemsPerPage,
 *   setSelectedItem,
 *   setEditModal,
 * }
 * @return {jsx} react component
 */
function PaymentTable({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  setSelectedItem,
  setEditModal,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deletePaymentMutation = useMutation((payload) => payment.remove(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'delete-payment-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { deletePayments } } = response;
      const message = `${deletePayments.length > 1 ? 'Pagos eliminados' : 'Pago eliminado'} con éxito`;

      enqueueSnackbar(message, {
        key: 'delete-payment-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('payments');
    },
  });

  const restorePaymentMutation = useMutation((payload) => payment.restore(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'restore-payment-error',
        variant: 'error',
      });
    },
    onSuccess: (response) => {
      const { data: { restorePayments } } = response;
      const message = `${restorePayments.length > 1 ? 'Pagos restaurados' : 'Pago restaurado'} con éxito`;

      enqueueSnackbar(message, {
        key: 'restore-payment-success',
        variant: 'success',
      });

      queryClient.invalidateQueries('payments');
    },
  });

  if (!data) {
    return (
      <Skeleton
        variant="rect"
        height={200}
        component="div"
        animation="wave"
      />
    );
  }

  const { data: { payments: { data: paymentData, paginatorInfo: { total } } } } = data;
  const tableData = getPaymentData(paymentData);

  const openEditModal = (row) => {
    setSelectedItem(row);
    setEditModal(true);
  };

  const columns = getPaymentColumns(tableData, openEditModal);

  const onDelete = (ids) => {
    deletePaymentMutation.mutate(ids);
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onRestore = (ids) => {
    restorePaymentMutation.mutate(ids);
  };

  const onRowsPerPageChange = (items) => {
    setCurrentPage(1);
    setItemsPerPage(items);
  };

  const options = {
    ...defaultTableOptions,
    page: currentPage - 1,
    rowsPerPage: itemsPerPage,
    count: total,
    onChangePage: onPageChange,
    onChangeRowsPerPage: onRowsPerPageChange,
    textLabels: tableLocalization('Pagos'),
    customToolbarSelect: (selectedRows, displayData) => getCustomSelectToolBar({
      id: 'zudaPaymentFilter',
      rows: selectedRows,
      data: displayData,
      onDelete,
      onRestore,
    }),
  };

  return (
    <div className={classes.tableContainer}>
      <DialogLoadingIndicator open={isLoading && data} borderRadius="30px 5px" />
      <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
      />
    </div>
  );
}

PaymentTable.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setEditModal: PropTypes.func.isRequired,
};

PaymentTable.defaultProps = {
  data: undefined,
};

export default PaymentTable;
