import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * SelectFieldAdapter component
 *
 * @param {object} {
 *   input, id, label, options, ...rest
 * }
 * @return {jsx} react component
 */
function SelectFieldAdapter({
  input, id, label, options, ...rest
}) {
  const classes = useStyles();

  return (
    <FormControl {...rest}>
      <InputLabel
        id={id}
        classes={{ focused: classes.focusedLabel }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        value={input.value}
        onChange={(event) => input.onChange(event.target.value)}
        input={<Input />}
        {...rest}
      >
        {options.map(
          (option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>,
        )}
      </Select>
    </FormControl>
  );
}

SelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectFieldAdapter;
