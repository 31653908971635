import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Authenticated from 'components/auth';

/**
 * PrivateRoute component
 *
 * @param {object} {
 *   component,
 *   layout,
 *   allowedRoles,
 *   ...rest
 * }
 * @return {jsx} react component
 */
function PrivateRoute({
  component,
  layout,
  allowedRoles,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Authenticated
          {...routeProps}
          component={component}
          layout={layout}
          allowedRoles={allowedRoles}
        />
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoute;
