import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from 'hooks/useLocalStorage';
import AuthUserContext from 'context/AuthUserContext';

/**
 * AppContextProvider provider
 *
 * @param {object} { children }
 * @return {jsx} react component
 */
function AppContextProvider({ children }) {
  const [authUser, setAuthUser] = useLocalStorage('zudaAuthUser', {});

  const updateAuthUser = (user) => {
    setAuthUser(user);
  };

  const authUserValue = useMemo(() => ({
    authUser,
    setAuthUser: updateAuthUser,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [authUser]);

  return (
    <AuthUserContext.Provider
      value={authUserValue}
    >
      {children}
    </AuthUserContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppContextProvider;
