import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import { genderOptions } from 'common/constants';
import { validateUpdateTrainerProfile } from 'common/form/validations';
import { getFieldsToUpdate } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import user from 'api/user';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    textAlign: 'end',
  },
  button: {
    borderRadius: theme.spacing(2),
    width: 122,
  },
}));

/**
 * BasicInfo component
 *
 * @param {object} { authUser, setAuthUser }
 * @return {jsx} react component
 */
function BasicInfo({ authUser, setAuthUser }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    first_name: authUser.first_name,
    last_name: authUser.last_name,
    email: authUser.email,
    phone: authUser.phone,
    gender: authUser.gender,
    birthdate: new Date(authUser.birthdate),
    specialty: authUser.specialty,
  };

  const updateUserMutation = useMutation((payload) => user.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-staff-profile-error',
        variant: 'error',
      });
    },
    onSuccess: (data) => {
      const { data: { updateUser } } = data;
      setAuthUser({ ...authUser, ...updateUser });

      enqueueSnackbar('Información actualizada con éxito', {
        key: 'update-staff-profile-success',
        variant: 'success',
      });
    },
  });

  const onSubmit = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const data = getDataToSave(fieldsToUpdate, 'user');
    updateUserMutation.mutate({ id: authUser.id, input: data });
  };

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        validate={validateUpdateTrainerProfile}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          invalid,
          pristine,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              className={classes.field}
              id="first_name"
              name="first_name"
              component={TextFieldAdapter}
              label="Nombres"
              required
              disabled={updateUserMutation.isLoading}
              fullWidth
            />
            <Field
              className={classes.field}
              id="last_name"
              name="last_name"
              component={TextFieldAdapter}
              label="Apellidos"
              required
              disabled={updateUserMutation.isLoading}
              fullWidth
            />
            <Field
              className={classes.field}
              id="email"
              name="email"
              component={TextFieldAdapter}
              label="Email"
              type="email"
              required
              disabled={updateUserMutation.isLoading}
              fullWidth
            />
            <Field
              className={classes.field}
              id="phone"
              name="phone"
              component={TextFieldAdapter}
              label="Teléfono"
              type="tel"
              disabled={updateUserMutation.isLoading}
              fullWidth
            />
            <div className={classes.field}>
              <Field
                id="gender"
                name="gender"
                component={SelectFieldAdapter}
                options={genderOptions}
                label="Sexo"
                required
                disabled={updateUserMutation.isLoading}
                fullWidth
              />
            </div>
            <Field
              className={classes.field}
              id="birthdate"
              name="birthdate"
              component={DatePickerFieldAdapter}
              label="Fecha de nacimiento"
              disableFuture
              openTo="year"
              views={['year', 'month', 'date']}
              required
              disabled={updateUserMutation.isLoading}
            />
            <Field
              className={classes.field}
              id="specialty"
              name="specialty"
              component={TextFieldAdapter}
              label="Especialidad"
              required
              multiline
              fullWidth
            />
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={invalid || pristine || updateUserMutation.isLoading}
              >
                {updateUserMutation.isLoading ? (
                  <CircularProgress size={24.5} thickness={5} color="secondary" />
                ) : (
                  <span>Actualizar</span>
                )}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

BasicInfo.propTypes = {
  authUser: PropTypes.object.isRequired,
  setAuthUser: PropTypes.func.isRequired,
};

export default BasicInfo;
