import React, { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import EmoticonCool from 'mdi-material-ui/EmoticonCool';
import EmoticonSad from 'mdi-material-ui/EmoticonSad';
import AuthUserContext from 'context/AuthUserContext';
import { ReactComponent as Logo } from 'images/logo.svg';
import { saveAuthInfo, redirectOnLogin } from 'common/utils';
import auth from 'api/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(8),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
  },
  link: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:visited': {
      color: theme.palette.secondary.main,
    },
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    width: '100vw',
  },
}));

/**
 * VerifyAccount component
 *
 * @return {jsx} react component
 */
function VerifyAccount() {
  const classes = useStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const [error, setError] = useState(false);

  const verifyEmailMutation = useMutation((authToken) => auth.verifyEmail(authToken), {
    onError: () => {
      setError(true);
    },
    onSuccess: ({ data: { verifyEmail } }) => {
      saveAuthInfo(verifyEmail);
      setAuthUser(verifyEmail.user);
    },
  });

  if (!error) {
    verifyEmailMutation.mutate(token);
  }

  if (authUser && Object.keys(authUser).length > 0) {
    return redirectOnLogin(authUser);
  }

  return (
    <div className={classes.container}>
      <Logo
        className={classes.logo}
        title="Zuda Team"
      />
      {error ? (
        <React.Fragment>
          <Typography color="textPrimary" className={classes.text}>
            <EmoticonSad
              className={classes.icon}
              color="secondary"
            />
            Tu cuenta no pudo ser verificada
          </Typography>
          <Typography color="textPrimary" className={classes.text}>
            Por favor ponte en contacto con&nbsp;
            <a
              className={classes.link}
              href="mailto:soporteapp@zuda.fit?subject=Mi cuenta no pudo ser verificada"
              rel="noopener noreferrer"
              target="_blank"
            >
              soporte t&eacute;cnico
            </a>
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography color="textPrimary" className={classes.text}>
            <EmoticonCool
              className={classes.icon}
              color="secondary"
            />
            Estamos verificando tu cuenta
          </Typography>
          <Typography color="textPrimary" className={classes.text}>
            En un momento ser&aacute;s redirigido a tu perfil
          </Typography>
        </React.Fragment>
      )}
      {!error && <LinearProgress color="secondary" className={classes.progressBar} />}
    </div>
  );
}

export default VerifyAccount;
