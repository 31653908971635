const adminRoutes = {
  adminProfile: {
    path: '/administrador',
    title: 'Mi Perfil',
  },
  home: {
    path: '/',
    title: 'Inicio',
  },
  clients: {
    path: '/clientes',
    title: 'Clientes',
  },
  trainers: {
    path: '/entrenadores',
    title: 'Entrenadores',
  },
  evaluations: {
    path: '/valoraciones',
    title: 'Valoraciones',
  },
  payments: {
    path: '/pagos',
    title: 'Pagos',
  },
  plans: {
    path: '/planes',
    title: 'Planes',
  },
  services: {
    path: '/servicios',
    title: 'Servicios',
  },
};

export default adminRoutes;
