import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EmoticonSad from 'mdi-material-ui/EmoticonSad';
import { ResponsivePie } from '@nivo/pie';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '30px 5px',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
    height: 303,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      width: '100%',
      boxShadow: 'none',
    },
  },
  tooltip: {
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
  },
  tooltipColor: {
    width: 12,
    height: 12,
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
  infoNotFound: {
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
}));

/**
 * PieChartCard component
 *
 * @param {object} { evaluations }
 * @return {jsx} react component
 */
function PieChartCard({ evaluations }) {
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);

  const customTooltip = ({ datum }) => (
    <div className={classes.tooltip}>
      <span className={classes.tooltipColor} style={{ backgroundColor: datum.color }} />
      {datum.label}
      :&nbsp;
      {datum.value}
      %
    </div>
  );

  useEffect(() => {
    if (evaluations.length > 0) {
      const firstEvaluation = evaluations[0];

      setChartData([
        {
          id: 'fat_percentage',
          label: '% Grasa',
          value: firstEvaluation.fat_percentage,
          color: '#FF595E',
        },
        {
          id: 'muscle_percentage',
          label: '% Musculo',
          value: firstEvaluation.muscle_percentage,
          color: '#1982C4',
        },
      ]);
    }
  }, [evaluations]);

  return (
    <article className={classes.card}>
      {evaluations.length > 0 ? (
        <ResponsivePie
          data={chartData}
          height={250}
          margin={{
            top: 0,
            right: 0,
            bottom: 60,
            left: 0,
          }}
          innerRadius={0.5}
          padAngle={5}
          colors={{ datum: 'data.color' }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor="#fff"
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 40,
              itemsSpacing: 0,
              itemWidth: 80,
              itemHeight: 18,
              itemDirection: 'top-to-bottom',
              symbolSize: 18,
              symbolShape: 'circle',
            },
          ]}
          theme={{
            textColor: '#fff',
            fontSize: 12,
          }}
          tooltip={customTooltip}
        />
      ) : (
        <React.Fragment>
          <EmoticonSad
            className={classes.icon}
            color="secondary"
          />
          <div className={classes.infoNotFound}>No tienes valoraciones registradas</div>
        </React.Fragment>
      )}
    </article>
  );
}

PieChartCard.propTypes = {
  evaluations: PropTypes.array.isRequired,
};

export default PieChartCard;
