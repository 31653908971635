import React from 'react';
import localforage from 'localforage';
import differenceInYears from 'date-fns/differenceInYears';
import { Redirect } from 'react-router-dom';
import routes from 'common/routes/index';
import { membershipStatus } from 'common/constants';

export const getLocalStorage = (key) => localforage.getItem(key)
  .then((value) => value)
  .catch((error) => error);

export const saveLocalStorage = (key, value) => {
  localforage.setItem(key, value)
    .then((savedValue) => savedValue)
    .catch((error) => error);
};

export const removeLocalStorage = (key) => {
  localforage.removeItem(key)
    .then((value) => value)
    .catch((error) => error);
};

export const saveAuthInfo = (data) => {
  saveLocalStorage('zuda_access_token', data.access_token);
  saveLocalStorage('zuda_refresh_token', data.refresh_token);
  saveLocalStorage('zuda_expires_in', data.expires_in);
};

export const removeAuthInfo = () => {
  removeLocalStorage('zuda_access_token');
  removeLocalStorage('zuda_refresh_token');
  removeLocalStorage('zuda_expires_in');
};

export const addDecimals = (num) => {
  if (Number.isInteger(num)) {
    return `${num}.00`;
  }

  const numberOfDecimals = num.toString().split('.')[1].length;

  if (numberOfDecimals < 2) {
    return `${num}0`;
  }

  return num;
};

export const toFixed = (value, precision = 0) => {
  const parsedFloat = parseFloat(`${value}e${precision}`);
  return Number(`${Math.round(parsedFloat)}e-${precision}`);
};

export const getAge = (today, birthdate) => differenceInYears(today, birthdate);

export const redirectOnLogin = (authUser) => {
  const action = {
    ADMINISTRADOR: () => <Redirect to={routes.home.path} />,
    ENTRENADOR: () => <Redirect to={routes.myClients.path} />,
    NUTRICIONISTA: () => <Redirect to={routes.myClients.path} />,
    CLIENTE: () => <Redirect to={routes.clientProfile.path} />,
  };

  return action[authUser.role]();
};

export const getMembershipStatus = (daysLeft) => {
  if (daysLeft <= 0) {
    return membershipStatus.expired;
  }

  if (daysLeft <= 10 && daysLeft > 0) {
    return membershipStatus.closeToExpire;
  }

  return membershipStatus.upToDate;
};

export const formatCurrency = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value).replace(/(\.|,)00$/g, '');
