import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import Grid from '@material-ui/core/grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ProfileAvatar from 'components/shared/ProfileAvatar';
import EvaluationItem from 'components/shared/EvaluationItem';
import DialogChart from 'components/shared/DialogChart';
import { toFixed, addDecimals } from 'common/utils';
import { dateFormat } from 'common/constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  user: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
  userInfo: {
    marginLeft: theme.spacing(4),
  },
  name: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  userProfileInfo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  secondaryInfo: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  secondaryInfoLeft: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginTop: 15,
    },
  },
  secondaryInfoTop: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  evaluation: {
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  date: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  folds: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(4),
    marginBottom: 5,
  },
  evaluationFields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  evaluationButton: {
    height: '100%',
    width: '100%',
  },
  evaluationItem: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    wordWrap: 'break-word',
  },
  fieldLabel: {
    marginTop: 0,
    marginBottom: 5,
    textAlign: 'left',
  },
  fieldValue: {
    alignItems: 'center',
    display: 'flex',
    margin: 0,
  },
  icon: {
    fontSize: theme.typography.pxToRem(12),
  },
  arrowUp: {
    color: '#1982C4',
  },
  arrowDown: {
    color: '#FF595E',
  },
  equal: {
    color: '#FFCA3A',
  },
}));

/**
 * Evaluation component
 *
 * @param {object} { data, showComments }
 * @return {jsx} react component
 */
function Evaluation({ data, showComments }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [chartInfo, setChartInfo] = useState({});
  const { current, previous } = data;

  useEffect(() => {
    if (Object.keys(chartInfo).length > 0) {
      setIsOpen(true);
    }
  }, [chartInfo]);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const getChartData = (prop) => {
    const chartArray = previous.map((evaluation) => {
      const date = lightFormat(parseISO(evaluation.created_at), 'MM-yy');
      return { date, [prop]: Number(evaluation[prop]) };
    });

    // insert current evaluation info to chartData
    chartArray.push({
      date: lightFormat(parseISO(current.created_at), 'MM-yy'),
      [prop]: Number(current[prop]),
    });

    return chartArray;
  };

  const showDialog = (prop, label, unit) => {
    const chartData = getChartData(prop);

    const chart = {
      prop, label, unit, data: chartData,
    };

    setChartInfo(chart);
  };

  return (
    <React.Fragment>
      <div className={classes.user}>
        <ProfileAvatar user={current.user_id} size={{ default: 16, xs: 14 }} />
        <div className={classes.userInfo}>
          <Typography className={classes.name} variant="h2">{current.user_id.full_name}</Typography>
          <div className={classes.secondaryInfo}>
            <div className={classes.secondaryInfoLeft}>
              <Typography className={clsx(classes.secondaryInfoTop, classes.userProfileInfo)}>
                Genero:&nbsp;
                {capitalize(current.user_id.gender)}
              </Typography>
              <Typography className={classes.userProfileInfo}>
                Edad:&nbsp;
                {current.age}
                &nbsp;años
              </Typography>
            </div>
            <div>
              <Typography className={clsx(classes.secondaryInfoTop, classes.userProfileInfo)}>
                Altura:&nbsp;
                {addDecimals(toFixed(current.user_id.height, 2))}
                &nbsp;cm
              </Typography>
              <Typography className={classes.userProfileInfo}>
                Peso:&nbsp;
                {addDecimals(toFixed(current.weight, 2))}
                &nbsp;kg
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.evaluation}>
        <Typography className={classes.date}>
          Fecha:&nbsp;
          {lightFormat(parseISO(current.created_at), dateFormat)}
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.folds}>Pliegues</Typography>
        <Grid container spacing={3} className={classes.evaluationFields}>
          <Grid item xs={6} sm={3} md={2}>
            <EvaluationItem current={current} label="Bíceps" name="biceps" onClick={showDialog} previous={previous} unit="mm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Tríceps" name="triceps" onClick={showDialog} previous={previous} unit="mm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Subescapular" name="subscapular" onClick={showDialog} previous={previous} unit="mm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Suprailíaco" name="suprailiac" onClick={showDialog} previous={previous} unit="mm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Abdominal" name="abdominal" onClick={showDialog} previous={previous} unit="mm" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={3} className={classes.evaluationFields}>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Acro-Acro" name="acro_acro" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cuello-Cadera" name="neck_hip" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cadera-Tobillo" name="hip_ankle" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Acro-Muñeca" name="acro_wrist" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cuello" name="neck" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Tórax" name="thorax" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cintura Alta" name="high_waist" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cintura Baja" name="low_waist" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Izq Alta" name="high_left_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Der Alta" name="high_right_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Izq Media" name="medium_left_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Der Media" name="medium_right_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Izq Baja" name="low_left_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pierna Der Baja" name="low_right_leg" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pantorrilla Izq" name="left_calf" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Pantorrilla Der" name="right_calf" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Brazo Izq" name="left_arm" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Brazo Der" name="right_arm" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Cadera" name="hip" onClick={showDialog} previous={previous} unit="cm" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={3} className={classes.evaluationFields}>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="% de Musculo" name="muscle_percentage" onClick={showDialog} previous={previous} unit="%" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Grasa Visceral" name="visceral_fat" onClick={showDialog} previous={previous} unit="kg" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Edad Biológica" name="biological_age" onClick={showDialog} previous={previous} showDecimals={false} unit="años" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="IMC" name="bmi" onClick={showDialog} previous={previous} unit="kg/m2" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="% de Grasa" name="fat_percentage" onClick={showDialog} previous={previous} unit="%" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Densidad Corporal" name="body_density" onClick={showDialog} previous={previous} unit="kg l-1" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Peso Graso" name="fat_weight" onClick={showDialog} previous={previous} unit="kg" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Peso Magro" name="lean_weight" onClick={showDialog} previous={previous} unit="kg" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="% Graso Normal" name="normal_fat_percentage" onClick={showDialog} previous={previous} unit="%" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="% Graso Ideal" name="ideal_fat_percentage" onClick={showDialog} previous={previous} unit="%" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Tasa Metabólica" name="metabolic_rate" onClick={showDialog} previous={previous} unit="kcal" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.gridItem}>
            <EvaluationItem current={current} label="Peso Magro Ideal" name="ideal_lean_weight" onClick={showDialog} previous={previous} unit="kg" />
          </Grid>
        </Grid>
        {(current.comments && showComments) && (
          <React.Fragment>
            <Divider />
            <Grid container spacing={3} className={classes.evaluationFields}>
              <Grid item xs={12}>
                Comentarios:&nbsp;
                {current.comments}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
      <DialogChart
        chartInfo={chartInfo}
        onClose={closeDialog}
        open={isOpen}
      />
    </React.Fragment>
  );
}

Evaluation.defaultProps = {
  showComments: true,
};

Evaluation.propTypes = {
  data: PropTypes.object.isRequired,
  showComments: PropTypes.bool,
};

export default Evaluation;
