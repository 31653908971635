import React, { useContext } from 'react';
import AuthUserContext from 'context/AuthUserContext';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BasicInfo from './BasicInfo';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px 5px',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    padding: '20px 12px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      flexDirection: 'column',
    },
  },
  leftContainer: {
    flex: '1 0 calc(50% - 12px)',
    marginRight: 12,
    [theme.breakpoints.down('xs')]: {
      flexBasic: '100%',
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  rightContainer: {
    flex: '1 0 calc(50% - 12px)',
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      flexBasic: '100%',
      marginLeft: 0,
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
  },
}));

/**
 * StaffProfile component
 *
 * @return {jsx} react component
 */
function StaffProfile() {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  return (
    <Paper square className={classes.paper}>
      <div className={classes.leftContainer}>
        <Typography className={classes.title}>Mi información</Typography>
        <BasicInfo authUser={authUser} setAuthUser={setAuthUser} />
      </div>
      <div className={classes.rightContainer}>
        <Typography className={classes.title}>Mi contraseña</Typography>
        <Password authUser={authUser} />
      </div>
    </Paper>

  );
}

export default StaffProfile;
