import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validateUpdateClient } from 'common/form/validations';
import { resetForm, getFieldsToUpdate, generatePassword } from 'common/form/utils';
import { clientInitialValues } from 'common/form/initialValues';
import getDataToSave from 'common/form/dataToSave';
import { formModalClasses } from 'common/muiSharedClasses';
import user from 'api/user';
import ClientFields from './ClientFields';

const useStyles = makeStyles(formModalClasses);

/**
 * EditClientModal
 *
 * @param {object} { open, onClose, selectedItem }
 * @return {jsx} react component
 */
function EditClientModal({ open, onClose, selectedItem }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      const values = clientInitialValues(selectedItem);
      setInitialValues(values);
    }
  }, [selectedItem]);

  const closeModal = () => {
    onClose(false);
  };

  const updateUserMutation = useMutation((payload) => user.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-client-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Cliente editado con éxito', {
        key: 'update-client-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('clients');
    },
  });

  const onSubmit = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(
      { ...values, is_zuda: values.plan_id !== null },
      initialValues,
    );
    const data = getDataToSave(fieldsToUpdate, 'user');
    updateUserMutation.mutate({ id: selectedItem.id, input: data });
  };

  if (initialValues === null) {
    return false;
  }

  return (
    <Form
      initialValues={initialValues}
      validate={validateUpdateClient}
      onSubmit={onSubmit}
      mutators={{
        resetForm,
        generatePassword,
      }}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="update-client-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={updateUserMutation.isLoading} />
            <DialogTitle id="update-client-modal">Editar Cliente</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <ClientFields
                form={form}
                classes={classes}
                requiredFields={[
                  'first_name',
                  'last_name',
                  'email',
                  'gender',
                  'birthdate',
                  'height',
                  'joining_date',
                ]}
                disableFuture={[
                  'birthdate',
                  'joining_date',
                ]}
              />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={updateUserMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={updateUserMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || updateUserMutation.isLoading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

EditClientModal.defaultProps = {
  selectedItem: null,
};

EditClientModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedItem: PropTypes.object,
};

export default EditClientModal;
