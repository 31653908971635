import { gql } from '@apollo/client';
import client from 'apollo/client';
import { paymentFilters } from 'common/filters';

/**
 * List payments method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { first, page } = payload.queryKey[1];
  const filters = paymentFilters(payload.queryKey[2]);

  return client.query({
    query: gql`
      query payments {
        payments(
          first: ${first}
          page: ${page}
          ${filters.user_id}
          ${filters.plan_id}
          ${filters.services_id}
          ${filters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            price
            user_id {
              id
              full_name
            }
            beneficiaries_id {
              id
              full_name
            }
            plan_id {
              id
              name
            }
            services_id {
              id
              name
            }
            start_date
            end_date
            comments
            created_at
            deleted_at
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
  });
};

/**
 * Create payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createPayment($input: CreatePaymentInput!) {
      createPayment(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Update payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) => client.mutate({
  mutation: gql`
    mutation updatePayment($id: ID!, $input: UpdatePaymentInput!) {
      updatePayment(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

/**
 * Remove payments method
 * @param  {String} ids
 * @return {Promise}
 */
const remove = (ids) => client.mutate({
  mutation: gql`
    mutation deletePayments($id: [ID!]!) {
      deletePayments(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Restore payments method
 * @param  {String} ids
 * @return {Promise}
 */
const restore = (ids) => client.mutate({
  mutation: gql`
    mutation restorePayments($id: [ID!]!) {
      restorePayments(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Create payment invoice method
 * @param  {String} id
 * @return {Promise}
 */
const createInvoice = (id) => client.mutate({
  mutation: gql`
    mutation {
      createInvoice(id: ${id}) {
        created
        id
      }
    }
  `,
});

/**
 * Send payment invoice method
 * @param  {String} id
 * @return {Promise}
 */
const sendInvoice = (id) => client.mutate({
  mutation: gql`
    mutation {
      sendInvoice(id: ${id}) {
        sent
        error
      }
    }
  `,
});

/**
 * Get download pdf url method
 * @param  {String} id
 * @return {Promise}
 */
const getDownloadPdfUrl = (id) => client.query({
  query: gql`
    query {
      downloadInvoice(id: ${id}) {
        url
      }
    }
  `,
});

/**
 * Get total income by period of time
 * @param {Object} payload
 * @returns {Promise}
 */
const getTotalInvoice = (payload) => {
  const input = payload.queryKey[1];

  return client.query({
    query: gql`
      query totalIncome($input: TotalIncomeInput!) {
        totalIncome(input: $input) {
          date,
          value,
          humanizedValue
        }
      }
    `,
    variables: { input },
    fetchPolicy: 'network-only',
  });
};

export default {
  list,
  create,
  update,
  remove,
  restore,
  createInvoice,
  sendInvoice,
  getDownloadPdfUrl,
  getTotalInvoice,
};
