import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { dateFormat } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  from: {
    marginRight: theme.spacing(2),
  },
  to: {
    marginLeft: theme.spacing(2),
  },
}));

const DateTextField = function DateTextField(props) {
  return (
    <TextField fullWidth {...props} />
  );
};

/**
 * DateRangePickerFieldAdapter component
 *
 * @param {object} { input, label, disableFuture }
 * @return {jsx} react component
 */
function DateRangePickerFieldAdapter({ input, label, disableFuture }) {
  const classes = useStyles();
  const [dateRange, setDateRange] = useState({
    from: input.value?.from || null,
    to: input.value?.to || null,
  });

  const onFromDateChange = (date) => {
    setDateRange({
      from: date,
      to: null,
    });
  };

  const ontoDateChange = (date) => {
    setDateRange({
      ...dateRange,
      to: date,
    });
  };

  useEffect(() => {
    input.onChange(dateRange);
  }, [input, dateRange]);

  return (
    <div className={classes.container}>
      <DatePicker
        id={`${input.name}-from`}
        name={`${input.name}.from`}
        label={`${label} - desde`}
        className={classes.from}
        clearable
        animateYearScrolling
        value={dateRange.from}
        format={dateFormat}
        initialFocusedDate={new Date()}
        onChange={onFromDateChange}
        TextFieldComponent={DateTextField}
        cancelLabel="Cancelar"
        clearLabel="Borrar"
        autoOk
        disableFuture={disableFuture}
      />
      -
      <DatePicker
        id={`${input.name}-to`}
        name={`${input.name}.to`}
        label={`${label} - hasta`}
        className={classes.to}
        clearable
        animateYearScrolling
        value={dateRange.to}
        format={dateFormat}
        initialFocusedDate={dateRange.from || new Date()}
        onChange={ontoDateChange}
        TextFieldComponent={DateTextField}
        cancelLabel="Cancelar"
        clearLabel="Borrar"
        autoOk
        disableFuture={disableFuture}
        disabled={!dateRange.from}
        minDate={dateRange.from || new Date()}
      />
    </div>
  );
}

DateRangePickerFieldAdapter.defaultProps = {
  disableFuture: false,
};

DateRangePickerFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool,
};

export default DateRangePickerFieldAdapter;
