import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { makeStyles, darken } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import Ballot from 'mdi-material-ui/Ballot';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemLink from 'components/shared/ListItemLink';
import ProfileAvatar from 'components/shared/ProfileAvatar';
import AuthUserContext from 'context/AuthUserContext';
import MiniDrawerContext from 'context/MiniDrawerContext';
import routes from 'common/routes/index';
import { removeAuthInfo } from 'common/utils';
import auth from 'api/auth';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: theme.palette.primary.dark,
  },
  profile: {
    backgroundColor: darken(theme.palette.primary.dark, 0.3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px 0`,
    boxShadow: theme.shadows[1],
    zIndex: 1,
  },
  avatarLink: {
    textDecoration: 'none',
  },
  name: {
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  genderIcon: {
    height: 16,
  },
  menu: {
    backgroundColor: theme.palette.primary.dark,
    flexGrow: 1,
    paddingTop: theme.spacing(5),
  },
  selected: {
    backgroundColor: theme.palette.background.default,
  },
  miniDrawer: {
    justifyContent: 'center',
  },
  logoutContainer: {
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    padding: `${theme.spacing(1)}px 0 0 0`,
  },
  logout: {
    borderRadius: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    marginTop: -8,
  },
}));

/**
 * NavigationMenu component
 *
 * @param {object} { mobile = false }
 * @return {jsx} react component
 */
function NavigationMenu({ mobile = false }) {
  const { authUser, setAuthUser } = useContext(AuthUserContext);
  const { miniDrawer, setMiniDrawer } = useContext(MiniDrawerContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const logoutMutation = useMutation(auth.logout, {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'logout-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      window.localStorage.clear();
      removeAuthInfo();
      setAuthUser({});
    },
  });

  const onToggleMiniDrawer = () => {
    setMiniDrawer(!miniDrawer);
  };

  const logout = () => {
    logoutMutation.mutate();
  };

  return (
    <React.Fragment>
      <div className={classes.profile}>
        <Link to={routes.adminProfile.path} className={classes.avatarLink}>
          <Tooltip title="Ver mi perfil">
            <div>
              <ProfileAvatar user={authUser} size={{ default: 6, xs: 6 }} />
            </div>
          </Tooltip>
        </Link>
        {!miniDrawer && (
          <Typography className={classes.name}>
            {authUser.full_name}
          </Typography>
        )}
      </div>
      <List classes={{ root: classes.menu }} component="nav">
        <ListItemLink
          to={routes.home.path}
          primary="Inicio"
          icon={<DashboardIcon />}
          customClasses={{
            selected: classes.selected,
          }}
        />
        <ListItemLink to={routes.clients.path} primary="Clientes" icon={<PeopleIcon />} />
        <ListItemLink to={routes.trainers.path} primary="Entrenadores" icon={<FitnessCenterIcon />} />
        <ListItemLink to={routes.evaluations.path} primary="Valoraciones" icon={<Ballot />} />
        <ListItemLink to={routes.payments.path} primary="Pagos" icon={<PaymentIcon />} />
        <ListItemLink to={routes.plans.path} primary="Planes" icon={<AssignmentIcon />} />
        <ListItemLink to={routes.services.path} primary="Servicios" icon={<AccountTreeIcon />} />
      </List>
      {!mobile && (
        <List classes={{ root: classes.bgColor }}>
          <ListItem
            disableGutters
            classes={{
              root: classes.miniDrawer,
            }}
          >
            <Tooltip title={miniDrawer ? 'Expandir' : 'Colapsar'}>
              <IconButton
                aria-label="collapse"
                color="default"
                onClick={onToggleMiniDrawer}
              >
                {miniDrawer ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
              </IconButton>
            </Tooltip>
          </ListItem>
        </List>
      )}
      <div className={classes.logoutContainer}>
        <Button
          classes={{
            root: classes.logout,
          }}
          variant="contained"
          color="secondary"
          startIcon={<ExitToAppIcon />}
          fullWidth
          onClick={logout}
          disabled={logoutMutation.isLoading}
        >
          {miniDrawer ? '' : 'Salir'}
        </Button>
        {(logoutMutation.isLoading && !miniDrawer) && (
          <CircularProgress
            size={24}
            thickness={5}
            color="secondary"
            className={classes.buttonProgress}
          />
        )}
      </div>
    </React.Fragment>
  );
}

NavigationMenu.defaultProps = {
  mobile: false,
};

NavigationMenu.propTypes = {
  mobile: PropTypes.bool,
};

export default NavigationMenu;
