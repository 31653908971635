import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import SectionTitle from 'components/layout/SectionTitle';
import AddButton from 'components/layout/AddButton';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import routes from 'common/routes/index';
import { defaultPaymentFilters } from 'common/filters';
import payment from 'api/payment';
import PaymentTable from './PaymentTable';
import PaymentFilters from './PaymentFilters';
import CreatePaymentModal from './CreatePaymentModal';
import EditPaymentModal from './EditPaymentModal';

/**
 * Payment component
 *
 * @return {jsx} react component
 */
function Payment() {
  const { enqueueSnackbar } = useSnackbar();
  const [paymentFilters, setPaymentFilters] = useLocalStorage('zudaPaymentFilter', defaultPaymentFilters);
  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['payments', { page: currentPage, first: itemsPerPage }, paymentFilters],
    payment.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-payments-error',
          variant: 'error',
        });
      },
    },
  );

  const toggleCreateModal = () => {
    setCreateModal(true);
  };

  return (
    <React.Fragment>
      <SectionTitle text={routes.payments.title}>
        <AddButton handleClick={toggleCreateModal} />
      </SectionTitle>
      <PaymentFilters
        filters={paymentFilters}
        setFilters={setPaymentFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <PaymentTable
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
      />
      <CreatePaymentModal
        open={createModal}
        onClose={setCreateModal}
      />
      <EditPaymentModal
        open={editModal}
        onClose={setEditModal}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  );
}

export default Payment;
