import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import useLocalStorage from 'hooks/useLocalStorage';
import MiniDrawerContext from 'context/MiniDrawerContext';
import MobileDrawerContext from 'context/MobileDrawerContext';
import PermanentDrawer from './PermanentDrawer';
import TemporaryDrawer from './TemporaryDrawer';
import MobileHeader from './MobileHeader';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    minHeight: '100%',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: `0 0 ${theme.spacing(3)}px 0`,
    },
  },
}));

/**
 * DefaultLayout component
 *
 * @param {object} { component: Component, ...rest }
 * @return {jsx} react component
 */
function DefaultLayout({ component: Component, ...rest }) {
  const [miniDrawer, setMiniDrawer] = useLocalStorage('zudaMiniDrawer', false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const classes = useStyles();

  const toggleMiniDrawer = (value) => {
    setMiniDrawer(value);
  };

  const toggleMobileDrawer = (value) => {
    setMobileDrawer(value);
  };

  const miniDrawerValue = useMemo(() => ({
    miniDrawer,
    setMiniDrawer: toggleMiniDrawer,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [miniDrawer]);

  const mobileDrawerValue = useMemo(() => ({
    mobileDrawer,
    setMobileDrawer: toggleMobileDrawer,
  }), [mobileDrawer]);

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <React.Fragment>
        <Hidden smUp>
          <MiniDrawerContext.Provider
            value={miniDrawerValue}
          >
            <MobileDrawerContext.Provider
              value={mobileDrawerValue}
            >
              <TemporaryDrawer width={drawerWidth} />
            </MobileDrawerContext.Provider>
          </MiniDrawerContext.Provider>
        </Hidden>

        <Hidden xsDown>
          <MiniDrawerContext.Provider
            value={miniDrawerValue}
          >
            <PermanentDrawer width={drawerWidth} />
          </MiniDrawerContext.Provider>
        </Hidden>
      </React.Fragment>

      <main className={classes.content}>
        <Hidden smUp>
          <MobileDrawerContext.Provider
            value={mobileDrawerValue}
          >
            <MobileHeader />
          </MobileDrawerContext.Provider>
        </Hidden>

        <Component {...rest} />
      </main>
    </Container>
  );
}

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default DefaultLayout;
