import React from 'react';
import PropTypes from 'prop-types';
import add from 'date-fns/add';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { validatePayment } from 'common/form/validations';
import { resetForm } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import { formModalClasses } from 'common/muiSharedClasses';
import payment from 'api/payment';
import PaymentFields from './PaymentFields';

const useStyles = makeStyles(formModalClasses);

const initialValues = {
  user_id: [],
  beneficiaries_id: [],
  price: null,
  start_date: new Date(),
  end_date: add(new Date(), { days: 30 }),
  plan_id: null,
  services_id: [],
  comments: null,
};

const calculator = createDecorator(
  {
    field: 'start_date',
    updates: {
      end_date: (startDateValue) => add(startDateValue, { days: 30 }),
    },
  },
);

/**
 * CreatePaymentModal component
 *
 * @param {object} { open, onClose }
 * @return {jsx} react component
 */
function CreatePaymentModal({ open, onClose }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = () => {
    onClose(false);
  };

  const sendInvoiceMutation = useMutation((id) => payment.sendInvoice(id), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'send-invoice-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { sendInvoice: { sent, error } } }) => {
      if (sent) {
        enqueueSnackbar('Factura enviada con éxito', {
          key: 'send-invoice-success',
          variant: 'success',
        });

        closeModal();
      } else {
        enqueueSnackbar(error, {
          key: 'send-invoice-error',
          variant: 'error',
        });
      }
    },
  });

  const createInvoiceMutation = useMutation((id) => payment.createInvoice(id), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'create-invoice-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { createInvoice: { created, id } } }) => {
      if (created) {
        sendInvoiceMutation.mutate(id);
      } else {
        enqueueSnackbar('La factura no pudo ser creada', {
          key: 'create-invoice-error',
          variant: 'error',
        });
      }
    },
  });

  const createPaymentMutation = useMutation((payload) => payment.create(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'create-payment-error',
        variant: 'error',
      });
    },
    onSuccess: ({ data: { createPayment: { id } } }) => {
      enqueueSnackbar('Pago creado con éxito', {
        key: 'create-payment-success',
        variant: 'success',
      });

      createInvoiceMutation.mutate(id);
      queryClient.invalidateQueries('payments');
    },
  });

  const onSubmit = (values) => {
    const data = getDataToSave(values, 'payment');
    createPaymentMutation.mutate(data);
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validatePayment}
      onSubmit={onSubmit}
      mutators={{
        resetForm,
      }}
      decorators={[
        calculator,
      ]}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="create-payment-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator
              open={
                createPaymentMutation.isLoading
                || createInvoiceMutation.isLoading
                || sendInvoiceMutation.isLoading
              }
            />
            <DialogTitle id="create-payment-modal">Registrar Pago</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <PaymentFields classes={classes} />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={createPaymentMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={createPaymentMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || createPaymentMutation.isLoading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

CreatePaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreatePaymentModal;
