import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import _debounce from 'lodash/debounce';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import useUserOptions from 'hooks/useUserOptions';
import { genderOptions, trashedOptions, debounceWait } from 'common/constants';
import { filtersClasses } from 'common/muiSharedClasses';

const useStyles = makeStyles(filtersClasses);

/**
 * TrainerFilters component
 *
 * @param {object} {
 *   filters,
 *   setFilters,
 *   setCurrentPage,
 *   isLoading,
 * }
 * @return {jsx} react component
 */
function TrainerFilters({
  filters,
  setFilters,
  setCurrentPage,
  isLoading,
}) {
  const classes = useStyles();

  const {
    userOptions,
    isUserLoading,
    isUserFetching,
    setUserSearch,
  } = useUserOptions('trainer');

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      setUserSearch(value);
    }
  };

  const onSubmit = (values) => {
    setCurrentPage(1);
    setFilters(values);
  };

  return (
    <Form
      initialValues={filters}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Accordion
            square
            defaultExpanded
            classes={{ root: classes.root }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={6}>
                  <Field
                    id="user_id"
                    name="user_id"
                    component={AutocompleteFieldAdapter}
                    options={userOptions}
                    isMultiple
                    limitTags={1}
                    loading={isUserLoading || isUserFetching}
                    onInputChange={_debounce(onUserIdChange, debounceWait)}
                    textProps={{
                      label: 'Entrenadores',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Field
                    id="gender"
                    name="gender"
                    component={SelectFieldAdapter}
                    options={genderOptions}
                    label="Sexo"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Field
                    id="soft_delete"
                    name="soft_delete"
                    component={SelectFieldAdapter}
                    options={trashedOptions}
                    label="Eliminado"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionActions classes={{ root: classes.actions }}>
              <Button
                className={classes.button}
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                endIcon={isLoading ? <CircularProgress size={14} thickness={5} color="secondary" /> : <FilterListIcon />}
                disabled={pristine || isLoading}
              >
                Filtrar
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      )}
    />
  );
}

TrainerFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TrainerFilters;
