import authRoutes from './authRoutes';
import clientRoutes from './clientRoutes';
import adminRoutes from './adminRoutes';
import staffRoutes from './staffRoutes';
import sharedRoutes from './sharedRoutes';

const routes = {
  ...authRoutes,
  ...clientRoutes,
  ...adminRoutes,
  ...staffRoutes,
  ...sharedRoutes,
};

export default routes;
