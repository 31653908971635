import React from 'react';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import AppContextProvider from 'context/AppContextProvider';
import client from './apollo/client';
import Router from './router';
import 'styles/base.css';

/**
 * App component
 *
 * @return {jsx} react component
 */
function App() {
  return (
    <ApolloProvider client={client}>
      <React.Fragment>
        <CssBaseline />
        <AppContextProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Router />
          </MuiPickersUtilsProvider>
        </AppContextProvider>
      </React.Fragment>
    </ApolloProvider>
  );
}

export default App;
