import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Field } from 'react-final-form';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import IntegerFieldAdapter from 'components/shared/IntegerFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/MultipleSelectFieldAdapter';
import useUserOptions from 'hooks/useUserOptions';
import usePlanOptions from 'hooks/usePlanOptions';
import useServiceOptions from 'hooks/useServiceOptions';
import { debounceWait } from 'common/constants';

/**
 * PaymentFields component
 *
 * @param {object} { classes }
 * @return {jsx} react component
 */
function PaymentFields({ classes }) {
  const { planOptions, isPlanLoading } = usePlanOptions();
  const { serviceOptions, isServiceLoading } = useServiceOptions();

  const {
    userOptions,
    isUserLoading,
    isUserFetching,
    setUserSearch,
  } = useUserOptions('evaluation');

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      setUserSearch(value);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridMargin}>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="user_id"
            name="user_id"
            component={AutocompleteFieldAdapter}
            options={userOptions}
            isMultiple={false}
            filterSelectedOptions
            loading={isUserLoading || isUserFetching}
            onInputChange={_debounce(onUserIdChange, debounceWait)}
            textProps={{
              label: 'Cliente',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="beneficiaries_id"
            name="beneficiaries_id"
            component={AutocompleteFieldAdapter}
            options={userOptions}
            isMultiple
            filterSelectedOptions
            loading={isUserLoading || isUserFetching}
            onInputChange={_debounce(onUserIdChange, debounceWait)}
            textProps={{
              label: 'Beneficiario',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="price"
            name="price"
            component={IntegerFieldAdapter}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            label="Precio"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="start_date"
            name="start_date"
            component={DatePickerFieldAdapter}
            label="Fecha de pago"
            openTo="year"
            views={['year', 'month', 'date']}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="end_date"
            name="end_date"
            component={DatePickerFieldAdapter}
            label="Fecha de corte"
            openTo="year"
            views={['year', 'month', 'date']}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="plan_id"
            name="plan_id"
            component={SelectFieldAdapter}
            options={planOptions}
            label="Personalizado"
            disabled={isPlanLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            id="services_id"
            name="services_id"
            component={MultipleSelectFieldAdapter}
            options={serviceOptions}
            label="Servicios"
            disabled={isServiceLoading}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.commentsContainer}>
        <Grid item xs={12}>
          <Field
            id="comments"
            name="comments"
            component={TextFieldAdapter}
            label="Comentarios"
            multiline
            rowsMax="4"
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

PaymentFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PaymentFields;
