import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { dateFormat } from 'common/constants';
import { formatCurrency } from 'common/utils';

const getIds = (field) => {
  if (field && field.length > 0) {
    return field.map((item) => Number(item.id));
  }

  return [];
};

const getNames = (field, prop) => {
  if (field && field.length > 0) {
    const names = field.map((item) => item[prop]);
    return names.join(', ');
  }

  return '';
};

const getBeneficiaries = (options) => options.map((option) => ({
  value: option.id,
  label: option.full_name,
}));

export const getClientData = (data) => data.map((client) => ({
  id: client.id,
  first_name: client.first_name,
  last_name: client.last_name,
  joining_date: lightFormat(parseISO(client.joining_date), dateFormat),
  payment_end_date: lightFormat(parseISO(client.last_payment.end_date), dateFormat),
  membership_days_left: client.membership_days_left,
  plan: client.plan_id?.name,
  trainer: client.trainer_id?.full_name || '',
  email: client.email,
  phone: client?.phone || '',
  gender: client.gender,
  birthdate: lightFormat(parseISO(client.birthdate), dateFormat),
  height: client.height,
  plan_id: client.plan_id?.id || null,
  num_classes: client.num_classes,
  trainer_id: client.trainer_id?.id || null,
  services_id: getIds(client.services_id),
  is_zuda: client.is_zuda,
  eps: client?.eps || '',
  note: client?.note || '',
  deleted_at: client.deleted_at,
}));

export const getMyClientsData = (data) => data.map((client) => ({
  id: client.id,
  first_name: client.first_name,
  last_name: client.last_name,
  plan: client.plan_id?.name || '',
  payment_end_date: lightFormat(parseISO(client.last_payment.end_date), dateFormat),
  membership_days_left: client.membership_days_left,
  trainer: client.trainer_id?.full_name || '',
  num_classes: client.num_classes,
  total_evaluations: client.total_evaluations,
  eps: client?.eps || '',
  note: client?.note || '',
}));

export const getTrainerData = (data) => data.map((trainer) => ({
  id: trainer.id,
  first_name: trainer.first_name,
  last_name: trainer.last_name,
  joining_date: lightFormat(parseISO(trainer.joining_date), dateFormat),
  total_clients: trainer.total_clients,
  email: trainer.email,
  phone: trainer?.phone || '',
  gender: trainer.gender,
  birthdate: lightFormat(parseISO(trainer.birthdate), dateFormat),
  specialty: trainer?.specialty || '',
  deleted_at: trainer.deleted_at,
}));

export const getPlanData = (data) => data.map((plan) => ({
  id: plan.id,
  name: plan.name,
  price: formatCurrency(plan.price),
  clients: plan.clients,
  deleted_at: plan.deleted_at,
}));

export const getServiceData = (data) => data.map((service) => ({
  id: service.id,
  name: service.name,
  price: formatCurrency(service.price),
  zuda_price: formatCurrency(service.zuda_price),
  clients: service.clients,
  deleted_at: service.deleted_at,
}));

export const getEvaluationData = (data) => data.map((evaluation) => ({
  id: evaluation.id,
  user_id: evaluation.user_id.id,
  user: evaluation.user_id.full_name,
  user_gender: evaluation.user_id.gender,
  user_height: evaluation.user_id.height,
  user_birthdate: evaluation.user_id.birthdate,
  created_at: lightFormat(parseISO(evaluation.created_at), dateFormat),
  deleted_at: evaluation.deleted_at,
  age: evaluation.age,
  weight: evaluation.weight,
  biceps: evaluation.biceps,
  triceps: evaluation.triceps,
  subscapular: evaluation.subscapular,
  suprailiac: evaluation.suprailiac,
  abdominal: evaluation.abdominal,
  acro_acro: evaluation.acro_acro,
  neck_hip: evaluation.neck_hip,
  hip_ankle: evaluation.hip_ankle,
  acro_wrist: evaluation.acro_wrist,
  neck: evaluation.neck,
  thorax: evaluation.thorax,
  high_waist: evaluation.high_waist,
  low_waist: evaluation.low_waist,
  hip: evaluation.hip,
  high_left_leg: evaluation.high_left_leg,
  medium_left_leg: evaluation.medium_left_leg,
  low_left_leg: evaluation.low_left_leg,
  left_calf: evaluation.left_calf,
  high_right_leg: evaluation.high_right_leg,
  medium_right_leg: evaluation.medium_right_leg,
  low_right_leg: evaluation.low_right_leg,
  right_calf: evaluation.right_calf,
  left_arm: evaluation.left_arm,
  right_arm: evaluation.right_arm,
  bmi: evaluation.bmi,
  fat_percentage: evaluation.fat_percentage,
  body_density: evaluation.body_density,
  fat_weight: evaluation.fat_weight,
  lean_weight: evaluation.lean_weight,
  normal_fat_percentage: evaluation.normal_fat_percentage,
  ideal_fat_percentage: evaluation.ideal_fat_percentage,
  metabolic_rate: evaluation.metabolic_rate,
  ideal_lean_weight: evaluation.ideal_lean_weight,
  muscle_percentage: evaluation.muscle_percentage,
  visceral_fat: evaluation.visceral_fat,
  biological_age: evaluation.biological_age,
  comments: evaluation.comments,
}));

export const getPaymentData = (data) => data.map((payment) => {
  const startDate = lightFormat(parseISO(payment.start_date), dateFormat);
  const endDate = lightFormat(parseISO(payment.end_date), dateFormat);

  return {
    id: payment.id,
    price: formatCurrency(payment.price),
    user: payment.user_id.full_name,
    user_id: payment.user_id.id,
    beneficiaries: getNames(payment.beneficiaries_id, 'full_name'),
    beneficiaries_id: getBeneficiaries(payment.beneficiaries_id),
    services: getNames(payment.services_id, 'name'),
    services_id: getIds(payment.services_id),
    plan: payment.plan_id?.name || '',
    plan_id: payment.plan_id?.id || null,
    time_frame: `${startDate} - ${endDate}`,
    start_date: startDate,
    end_date: endDate,
    comments: payment.comments,
    created_at: lightFormat(parseISO(payment.created_at), dateFormat),
    deleted_at: payment.deleted_at,
  };
});
