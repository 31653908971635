import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * AutocompleteFieldAdapter component
 *
 * @param {object} {
 *   input,
 *   meta,
 *   isMultiple,
 *   loading,
 *   textProps,
 *   ...rest
 * }
 * @return {jsx} react component
 */
function AutocompleteFieldAdapter({
  input,
  meta,
  isMultiple,
  loading,
  textProps,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Autocomplete
      getOptionLabel={(option) => (option && typeof option.label !== 'undefined' ? option.label : '')}
      value={input.value}
      multiple={isMultiple}
      filterOptions={(options) => {
        let currentValues = [];

        if (Array.isArray(input.value)) {
          currentValues = input.value.map((item) => item.value);
        } else {
          currentValues = [input.value.value];
        }

        const filteredOptions = options.filter((option) => !currentValues.includes(option.value));
        return filteredOptions;
      }}
      onChange={(event, value) => input.onChange(value)}
      onFocus={(event) => input.onFocus(event)}
      onBlur={(event) => input.onBlur(event)}
      noOptionsText={loading ? 'Cargando opciones' : 'Sin opciones'}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {(loading && meta.active) ? <CircularProgress color="secondary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          InputLabelProps={{
            classes: { focused: classes.focusedLabel },
          }}
        />
      )}
      {...rest}
    />
  );
}

AutocompleteFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  textProps: PropTypes.object.isRequired,
};

AutocompleteFieldAdapter.defaultProps = {
  loading: false,
};

export default AutocompleteFieldAdapter;
