const staffRoutes = {
  trainerProfile: {
    path: '/entrenador/mi-perfil',
    title: 'Mi Perfil',
  },
  nutritionistProfile: {
    path: '/nutricionista/mi-perfil',
    title: 'Mi Perfil',
  },
  myClients: {
    path: '/mis-clientes',
    title: 'Mis Clientes',
  },
  myClientEvaluations: {
    path: '/mis-clientes/valoraciones/:id',
    title: 'Valoraciones',
  },
  myClientEvaluationDetail: {
    path: '/mis-clientes/valoracion/:id',
    title: 'Valoración',
  },
};

export default staffRoutes;
