import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateUpdatePassword } from 'common/form/validations';
import user from 'api/user';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    textAlign: 'end',
  },
  button: {
    borderRadius: theme.spacing(2),
    width: 122,
  },
}));

/**
 * Password component
 *
 * @param {object} { authUser }
 * @return {jsx} react component
 */
function Password({ authUser }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const updateUserMutation = useMutation((payload) => user.update(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'update-staff-profile-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Contraseña actualizada con éxito', {
        key: 'update-staff-profile-success',
        variant: 'success',
      });
    },
  });

  const onSubmit = (values) => {
    updateUserMutation.mutate({ id: authUser.id, input: { password: values.password } });
  };

  return (
    <div className={classes.container}>
      <Form
        validate={validateUpdatePassword}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          invalid,
          pristine,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.field}>
              <Field
                id="password"
                name="password"
                component={PasswordFieldAdapter}
                label="Contraseña"
                autoComplete="new-password"
                required
                disabled={updateUserMutation.isLoading}
                fullWidth
              />
            </div>
            <div className={classes.field}>
              <Field
                id="confirm"
                name="confirm"
                component={PasswordFieldAdapter}
                label="Confirmar Contraseña"
                autoComplete="new-password"
                required
                disabled={updateUserMutation.isLoading}
                fullWidth
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={invalid || pristine || updateUserMutation.isLoading}
              >
                {updateUserMutation.isLoading ? (
                  <CircularProgress size={24.5} thickness={5} color="secondary" />
                ) : (
                  <span>Actualizar</span>
                )}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

Password.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default Password;
