import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Dumbbell from 'mdi-material-ui/Dumbbell';
import { makeStyles } from '@material-ui/core/styles';
import AuthUserContext from 'context/AuthUserContext';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import SectionTitle from 'components/layout/SectionTitle';
import SetPasswordModal from 'components/shared/SetPasswordModal';
import ClientCount from 'components/shared/ClientsCount';
import { defaultMyClientsFilters } from 'common/filters';
import user from 'api/user';
import MyClientsFilters from './MyClientsFilters';
import MyClientsTable from './MyClientsTable';

const useStyles = makeStyles((theme) => ({
  welcome: {
    borderRadius: 9999,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    transform: 'perspective(1000px) rotateY(0)',
    transition: theme.transitions.create(['background-color', 'color', 'transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: '#FFCA3A',
      transform: 'perspective(1000px) rotateY(180deg)',
    },
  },
  clientsCardContainer: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      padding: 0,
    },
  },
}));

/**
 * MyClients component
 *
 * @return {jsx} react component
 */
function MyClients() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { authUser, setAuthUser } = useContext(AuthUserContext);

  const [clientFilters, setClientFilters] = useLocalStorage('zudaMyClientsFilter', defaultMyClientsFilters(
    authUser.role,
    authUser.id,
  ));

  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['myClients', { page: currentPage, first: itemsPerPage }, clientFilters],
    user.listMyClients,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-clients-error',
          variant: 'error',
        });
      },
    },
  );

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={4}>
          <SectionTitle text={`Bienvenido ${authUser.first_name}`} backButton>
            <div className={classes.welcome}>
              <Dumbbell />
            </div>
          </SectionTitle>
        </Grid>
        <Grid classes={{ root: classes.clientsCardContainer }} item xs={12} lg={8}>
          <ClientCount trainer_id={Number(authUser.id)} />
        </Grid>
      </Grid>
      <MyClientsFilters
        userId={authUser.id}
        userRole={authUser.role}
        filters={clientFilters}
        setFilters={setClientFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <MyClientsTable
        userRole={authUser.role}
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
      />
      {!authUser.password_set && (
        <SetPasswordModal authUser={authUser} setAuthUser={setAuthUser} />
      )}
    </React.Fragment>
  );
}

export default MyClients;
