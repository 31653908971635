import React from 'react';

/**
 * Dashboard component
 *
 * @return {jsx} react component
 */
function Dashboard() {
  return <div>Dashboard</div>;
}

export default Dashboard;
