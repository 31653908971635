import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import SlideUpTransition from 'components/shared/SlideUpTransition';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import { roles, gender } from 'common/constants';
import { validateTrainer } from 'common/form/validations';
import { resetForm, generatePassword } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import { formModalClasses } from 'common/muiSharedClasses';
import user from 'api/user';
import TrainerFields from './TrainerFields';

const useStyles = makeStyles(formModalClasses);

const initialValues = {
  email: null,
  first_name: null,
  last_name: null,
  password: null,
  phone: null,
  gender: gender.female,
  birthdate: null,
  joining_date: new Date(),
  role: roles.trainer,
  specialty: null,
};

/**
 * CreateTrainerModal component
 *
 * @param {object} { open, onClose }
 * @return {jsx} react component
 */
function CreateTrainerModal({ open, onClose }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = () => {
    onClose(false);
  };

  const createUserMutation = useMutation((payload) => user.create(payload), {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        key: 'create-trainer-error',
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Entrenador creado con éxito', {
        key: 'create-trainer-success',
        variant: 'success',
      });

      closeModal();
      queryClient.invalidateQueries('trainers');
    },
  });

  const onSubmit = (values) => {
    const data = getDataToSave(values, 'user');
    createUserMutation.mutate(data);
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validateTrainer}
      onSubmit={onSubmit}
      mutators={{
        resetForm,
        generatePassword,
      }}
      render={({
        handleSubmit,
        form,
        values,
        invalid,
        pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullScreen
            TransitionComponent={SlideUpTransition}
            onClose={closeModal}
            aria-labelledby="create-trainer-modal"
            open={open}
            classes={{ paper: classes.paper }}
          >
            <DialogLoadingIndicator open={createUserMutation.isLoading} />
            <DialogTitle id="create-trainer-modal">Crear Entrenador</DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent>
              <TrainerFields
                form={form}
                classes={classes}
                requiredFields={[
                  'first_name',
                  'last_name',
                  'email',
                  'password',
                  'gender',
                  'birthdate',
                  'joining_date',
                ]}
                disableFuture={[
                  'birthdate',
                  'joining_date',
                ]}
              />
            </DialogContent>
            <Divider className={classes.divider} />
            <DialogActions>
              <Grid container>
                <Grid item xs={6} className={classes.resetContainer}>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    color="secondary"
                    onClick={() => { form.mutators.resetForm(form); }}
                    disabled={createUserMutation.isLoading}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsContainer}>
                  <Button
                    className={classes.cancelButton}
                    onClick={closeModal}
                    color="secondary"
                    disabled={createUserMutation.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.roundedButton}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => { onSubmit(values); }}
                    disabled={invalid || pristine || createUserMutation.isLoading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

CreateTrainerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateTrainerModal;
