import parseISO from 'date-fns/parseISO';
import lightFormat from 'date-fns/lightFormat';
import randomize from 'randomatic';
import { dateFormat } from 'common/constants';
import { isValidDate } from './fieldValidations';

export const resetForm = (args) => {
  const form = args[0];
  form.restart();
};

export const generatePassword = (args, state, tools) => {
  tools.changeValue(state, 'password', () => randomize('Aa0', 16));
};

export const stringToDate = (str) => {
  const arr = str.split('-');

  return parseISO(`${arr[0]}-${arr[1]}-${arr[2]}T00:00:00`);
};

export const getFieldsToUpdate = (values, initialValues) => {
  const fieldsToUpdate = {};

  for (const key in initialValues) {
    if (Object.prototype.hasOwnProperty.call(initialValues, key) && key in values) {
      let value = values[key];
      let initialValue = initialValues[key];

      if (isValidDate(values[key])) {
        value = lightFormat(value, dateFormat);
        initialValue = lightFormat(initialValue, dateFormat);
      }

      if (value !== initialValue) {
        fieldsToUpdate[key] = values[key];
      }
    }
  }

  return fieldsToUpdate;
};
