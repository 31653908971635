import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import SectionTitle from 'components/layout/SectionTitle';
import AddButton from 'components/layout/AddButton';
import useLocalStorage from 'hooks/useLocalStorage';
import usePaginationSettings from 'hooks/usePaginationSettings';
import routes from 'common/routes/index';
import { defaultServiceFilters } from 'common/filters';
import service from 'api/service';
import ServiceTable from './ServiceTable';
import ServiceFilters from './ServiceFilters';
import CreateServiceModal from './CreateServiceModal';
import EditServiceModal from './EditServiceModal';

/**
 * Service component
 *
 * @return {jsx} react component
 */
function Service() {
  const { enqueueSnackbar } = useSnackbar();
  const [serviceFilters, setServiceFilters] = useLocalStorage('zudaServiceFilter', defaultServiceFilters);
  const {
    currentPage, itemsPerPage, setCurrentPage, setItemsPerPage,
  } = usePaginationSettings();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    ['services', { page: currentPage, first: itemsPerPage }, serviceFilters],
    service.list,
    {
      keepPreviousData: true,
      onError: (error) => {
        enqueueSnackbar(error.message, {
          key: 'list-services-error',
          variant: 'error',
        });
      },
    },
  );

  const toggleCreateModal = () => {
    setCreateModal(true);
  };

  return (
    <React.Fragment>
      <SectionTitle text={routes.services.title}>
        <AddButton handleClick={toggleCreateModal} />
      </SectionTitle>
      <ServiceFilters
        filters={serviceFilters}
        setFilters={setServiceFilters}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading || isFetching}
      />
      <ServiceTable
        data={data}
        isLoading={isLoading || isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSelectedItem={setSelectedItem}
        setEditModal={setEditModal}
      />
      <CreateServiceModal
        open={createModal}
        onClose={setCreateModal}
      />
      <EditServiceModal
        open={editModal}
        onClose={setEditModal}
        selectedItem={selectedItem}
      />
    </React.Fragment>
  );
}

export default Service;
