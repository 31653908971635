import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from 'common/utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '5px 30px',
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down(655)]: {
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      marginTop: 0,
      boxShadow: 'none',
    },
  },
  serviceList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  service: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 16,
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
  },
  serviceNoMargin: {
    marginTop: 0,
  },
  serviceName: {
    flex: '1 1 auto',
  },
  serviceLabel: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  trainerName: {
    flex: '0 1 60%',
    textAlign: 'right',
  },
  serviceValue: {
    flex: '0 1 30%',
    textAlign: 'right',
  },
  serviceTitle: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2),
  },
}));

/**
 * Services component
 *
 * @param {object} { authUser }
 * @return {jsx} react component
 */
function Services({ authUser }) {
  const classes = useStyles();

  const getServicesList = () => authUser.services_id.map((item, index) => (
    <li
      key={item.id}
      className={clsx(classes.service, {
        [classes.serviceNoMargin]: index === 0,
      })}
    >
      <span className={classes.serviceName}>
        <span className={classes.serviceLabel}>Servicio:&nbsp;</span>
        {item.name}
      </span>
      <span className={classes.serviceValue}>
        {authUser.is_zuda ? formatCurrency(item?.zuda_price || 0) : formatCurrency(item?.price || 0)}
      </span>
    </li>
  ));

  return (
    <article className={classes.card}>
      <ul className={classes.serviceList}>
        {authUser.trainer_id && (
          <li className={classes.service}>
            <span className={classes.serviceName}>
              <span className={classes.serviceLabel}>Entrenador:&nbsp;</span>
            </span>
            <span className={classes.trainerName}>
              {authUser.trainer_id?.full_name || ''}
            </span>
          </li>
        )}
        {authUser.plan_id && (
          <li className={classes.service}>
            <span className={classes.serviceName}>
              <span className={classes.serviceLabel}>Plan:&nbsp;</span>
              {authUser.plan_id.name}
            </span>
            <span className={classes.serviceValue}>
              {formatCurrency(authUser.plan_id?.price || 0)}
            </span>
          </li>
        )}
        {authUser.num_classes && (
          <li className={classes.service}>
            <span className={classes.serviceName}>
              <span className={classes.serviceLabel}>Numero de sesiones:&nbsp;</span>
            </span>
            <span className={classes.serviceValue}>
              {authUser.num_classes}
            </span>
          </li>
        )}
        {authUser.services_id.length > 0 && (
          <li className={classes.serviceTitle}>Servicios</li>
        )}
        { getServicesList() }
      </ul>
    </article>
  );
}

Services.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default Services;
