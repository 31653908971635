import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logoutContainer: {
    position: 'relative',
  },
  logoutButton: {
    borderRadius: 16,
    width: '100%',
    [theme.breakpoints.down(960)]: {
      width: 'auto',
    },
  },
  logoutProgress: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    marginTop: -12,
    marginRight: -12,
  },
  invisible: {
    visibility: 'hidden',
  },
}));

/**
 * LogoutButton component
 *
 * @param {object} { isLoading, label, onClick, variant }
 * @return {jsx} react component
 */
function LogoutButton({
  isLoading, label, onClick, variant,
}) {
  const classes = useStyles();

  return (
    <div className={classes.logoutContainer}>
      <Button
        className={classes.logoutButton}
        variant={variant}
        color="secondary"
        fullWidth
        onClick={onClick}
        disabled={isLoading}
      >
        <span className={clsx({
          [classes.invisible]: isLoading,
        })}
        >
          {label}
        </span>
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          thickness={5}
          color="secondary"
          className={classes.logoutProgress}
        />
      )}
    </div>
  );
}

LogoutButton.defaultProps = {
  variant: 'text',
};

LogoutButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default LogoutButton;
