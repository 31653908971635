import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  focusedLabel: {
    color: `${theme.palette.common.white} !important`,
  },
}));

/**
 * PasswordFieldAdapter component
 *
 * @param {object} {
 *   input,
 *   meta,
 *   id,
 *   label,
 *   shuffle = false,
 *   onShuffle,
 *   ...rest
 * }
 * @return {jsx} react component
 */
function PasswordFieldAdapter({
  input,
  meta,
  id,
  label,
  shuffle = false,
  onShuffle,
  ...rest
}) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickShuffle = () => {
    onShuffle();
  };

  const onKeyDownPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const onKeyDownShuffle = (event) => {
    event.preventDefault();
    onShuffle();
  };

  return (
    <FormControl
      {...rest}
      error={meta.touched ? meta.invalid : false}
    >
      <InputLabel
        htmlFor={id}
        classes={{ focused: classes.focusedLabel }}
      >
        {label}
      </InputLabel>
      <Input
        {...input}
        {...rest}
        id={id}
        type={showPassword ? 'text' : 'password'}
        onChange={(event) => input.onChange(event.target.value)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle-password-visibility"
              onClick={onClickShowPassword}
              onKeyDown={onKeyDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {shuffle && (
              <IconButton
                arial-label="shuffle"
                onClick={onClickShuffle}
                onKeyDown={onKeyDownShuffle}
              >
                <ShuffleIcon />
              </IconButton>
            )}
          </InputAdornment>
        )}
      />
      {(meta.touched && meta.invalid) && <FormHelperText>{meta.invalid ? meta.error : ''}</FormHelperText>}
    </FormControl>
  );
}

PasswordFieldAdapter.defaultProps = {
  shuffle: false,
  onShuffle: () => {},
};

PasswordFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  shuffle: PropTypes.bool,
  onShuffle: PropTypes.func,
};

export default PasswordFieldAdapter;
