import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MobileDrawerContext from 'context/MobileDrawerContext';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
  placeholder: {
    width: 48,
    height: 10,
  },
});

/**
 * MobileHeader component
 *
 * @return {jsx} react component
 */
function MobileHeader() {
  const classes = useStyles();
  const { mobileDrawer, setMobileDrawer } = useContext(MobileDrawerContext);

  const onDrawerToggle = () => {
    setMobileDrawer(!mobileDrawer);
  };

  return (
    <AppBar position="static">
      <Toolbar
        disableGutters
        classes={{
          root: classes.toolbar,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={onDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <div>Zuda</div>
        <div className={classes.placeholder} />
      </Toolbar>
    </AppBar>
  );
}

export default MobileHeader;
