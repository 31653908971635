import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import TableAction from 'components/shared/TableAction';
import DaysLeft from 'components/shared/DaysLeft';
import CustomToolbarSelect from 'components/shared/CustomToolbarSelect';

export const defaultTableOptions = {
  download: false,
  filter: false,
  print: false,
  rowsPerPageOptions: [5, 10, 20],
  search: false,
  serverSide: true,
  sort: false,
  jumpToPage: true,
  responsive: 'standard',
};

export const tableLocalization = (type) => ({
  body: {
    noMatch: `No hay ${type.toLowerCase()} que mostrar. Intenta cambiando los filtros`,
  },
  pagination: {
    displayRows: 'de',
    rowsPerPage: 'Filas Por Página',
    previous: 'Página anterior',
    next: 'Página siguiente',
    jumpToPage: 'Página',
  },
  toolbar: {
    viewColumns: 'Ver columnas',
  },
  viewColumns: {
    title: 'Ver Columnas',
  },
  selectedRows: {
    text: 'fila(s) seleccionada(s)',
    delete: 'Eliminar',
    deleteAria: 'Eliminar Fila(s) Seleccionada(s)',
  },
});

export const simpleTableLocalization = (type) => ({
  body: {
    noMatch: `No hay ${type.toLowerCase()} que mostrar`,
  },
  pagination: {
    displayRows: 'de',
    rowsPerPage: 'Filas Por Página',
    previous: 'Página anterior',
    next: 'Página siguiente',
    jumpToPage: 'Página',
  },
});

export const getSelectedIds = (selectedRows, displayData) => {
  const dataIndexes = selectedRows.data.map((selectedRow) => selectedRow.dataIndex);
  const ids = displayData.reduce((acc, curr) => {
    if (dataIndexes.includes(curr.dataIndex)) {
      acc.push(curr.data[0]);
    }

    return acc;
  }, []);

  return ids;
};

const getRowToUpdate = (tableData, rowIndex) => (
  tableData.filter((data, index) => index === rowIndex)
);

export const customAction = (type, tableData, onClick) => {
  const isDefault = (row) => (
    <TableAction type={type} onClick={onClick} row={row} disabled={row.deleted_at !== null} />
  );

  const icon = {
    edit: isDefault,
    notification: isDefault,
  };

  return {
    name: '',
    options: {
      filter: false,
      sort: false,
      empty: true,
      viewColumns: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        const row = getRowToUpdate(tableData, rowIndex)[0];
        return icon[type](row);
      },
    },
  };
};

export const daysLeftColumn = (tableData) => ({
  label: 'Próximo pago',
  name: 'membership_days_left',
  options: {
    customBodyRenderLite: (dataIndex, rowIndex) => {
      const row = getRowToUpdate(tableData, rowIndex)[0];
      return <DaysLeft data={row} />;
    },
  },
});

export const getCustomSelectToolBar = ({
  id,
  rows,
  data,
  onDelete,
  onRestore,
}) => (
  <CustomToolbarSelect
    filtersId={id}
    displayData={data}
    selectedRows={rows}
    onDelete={onDelete}
    onRestore={onRestore}
  />
);

export const getDownloadButton = ({
  rowIndex,
  buttonClasses,
  disabled,
  onClick,
}) => (
  <div className={buttonClasses.iconButtonContainer}>
    <IconButton
      className={buttonClasses.iconButton}
      aria-label="download"
      onClick={() => {
        onClick(rowIndex);
      }}
      disabled={disabled}
    >
      <GetAppIcon />
    </IconButton>
  </div>
);

export const getViewButton = ({
  rowIndex,
  buttonClasses,
  onClick,
}) => (
  <div className={buttonClasses.iconButtonContainer}>
    <IconButton
      className={buttonClasses.iconButton}
      aria-label="check"
      onClick={() => {
        onClick('', { rowIndex });
      }}
    >
      <SearchIcon />
    </IconButton>
  </div>
);
